import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';


import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";

import StoreMod from "../../../../page/store/Mod";

function SiteAdminManagementAgencyMod() {


    return (
        <>
        <div id="wrap">
            <Header/>
                        
            <StoreMod pay_type={""}/>
                                
        </div>

        <Footer/>
        </>
    )
}

export default SiteAdminManagementAgencyMod