import React, {useEffect, useState, useRef, useReducer, useCallback } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import Paging from "../../views/include/Paging";
import OpenLogComment from "../../views/include/OpenLogComment";
import { type } from '@testing-library/user-event/dist/type';

function ManageLogList({user_level}) {
    
    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    const query = queryString.parse(location.search);    
    
    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    React.useEffect(() => {              
        // if(log_set === true){
        //     window.location.reload();
        // }
    }, [log_set])

    const [list , setList] = useState({
        viewContent:'',                 // 게시판 내역
        totalCnt:0,                     // 게시판 총 수
        nums:0,                         // 게시판 넘버
        pageMax:10,                     // 페이징 페이징에 한번에 보일 수
        gListRows:10,                   // 페이징 한번에 나오는 리스트 수
        page:1,                         // 페이징 현재 페이지
        searchCates01:'',               // 검색 거래처 종류
        searchCates02:'user_name',      // 검색 거래처 종류
        searchWord:'',                  // 검색 명
        url:queryParam,                 // 페이징 url 정보        
    }); 
    

    const sWord = query.searchWord ? query.searchWord : '';
    const sCate01 = query.searchCates01 ? query.searchCates01 : '';
    const sCate02 = query.searchCates02 ? query.searchCates02 : '';    
    const sPg = query.pg? query.pg : 1;
    
    // 카테고리 변경시
    const onSearchCates01 = (event) => {
        setList({...list, searchCates01:event.currentTarget.value});
    }

    
    // 카테고리 변경시
    const onSearchCates02 = (event) => {
        setList({...list, searchCates02:event.currentTarget.value});
    }    

    // 키워드 입력시
    const onSearchWord = ((event)=>{        
        setList({...list, searchWord:event.currentTarget.value});
    });

    function onSubmitSearch(event){
        event.preventDefault();        
        const purl = 'searchWord='+list.searchWord+'&searchCates01='+list.searchCates01+'&searchCates02='+list.searchCates02;
        setList({...list, url:purl});        
        navigate('/SiteAdmin/_manage/ManageLog?searchWord='+list.searchWord+'&searchCates01='+list.searchCates01+'&searchCates02='+list.searchCates02);        
    }

    const Fn_Fail =(idx)=>{
        if( ! window.confirm("정말 초기화 하시겠습니까?")){
            return false;
        }

        let body = {
            idx: idx
        }

        axios.post('/api/manage/ManageLogDel', body)
        .then(function(response){
            if(response.data === "OK"){    
                                
                GetList();
                
                set_log_set(true);  
                
                //window.location.reload();
            }else{
                alert("시스템 에러");    
            }
        },[])

    }
    
    const Board = ({
        idx,
        nums,
        branch_name,
        corp_class, 
        user_id,
        regdate,
        loginis,
        last_login,
        login_ip,
        login_fail_cnt
    }) => {

            return (
                <tr>
                    <td className="number_td">{nums}</td>
                    <td className="branch_name_td">{branch_name}</td>                    
                    <td className="corp_class_td">{corp_class}</td>
                    <td className="user_id_td">{user_id}</td>
                    <td className="regdate_td">{regdate}</td>
                    <td className="loginis_td">{loginis}</td>
                    <td className="last_login_td">{last_login}</td>
                    <td className="login_ip_td">{login_ip}</td>
                    <td className="login_fail_cnt_td">{login_fail_cnt}</td>
                    <td className="btn_wrap_td">
                        <div className="btn_wrap">                                                    
                            <Link to={''} onClick={()=>{Fn_Fail(idx)}} className="btn_st_02 blue_line" >실패초기화</Link>
                        </div>
                    </td>
                </tr>
            
            );
    };

    // 전체 수량 구하는 
    function GetTotalCnt(){

        let bodyQuery = {            
            searchCates01: list.searchCates01,
            searchCates02: list.searchCates02,
            searchWord: list.searchWord,
            user_level: user_level            
        }

        axios.post('/api/manage/ManageLogTotalList', bodyQuery).then(function(res){            
                 
            // console.log('res.data.tcnt : ', res.data.tcnt)
            // console.log('list.page : ', list.page)
            // console.log('list.gListRows : ', list.gListRows)
            // console.log('list.nums ===>>> ', (res.data.tcnt)-( (list.page - 1) * list.gListRows ));

            setList({...list
                , totalCnt:{...list.totalCnt=res.data.tcnt}
                , nums:{...list.nums=(res.data.tcnt)-( (list.page - 1) * list.gListRows )}
            });
            
        })

    }

    const GetList =()=>{

        let bodyPage = {
            nFrom: (list.page - 1) * list.gListRows,
            gListRows: list.gListRows,            
            searchCates01: list.searchCates01,
            searchCates02: list.searchCates02,
            searchWord: list.searchWord,           
            user_level: user_level,                        
        }
        
        axios.post('/api/manage/ManageLogList', bodyPage).then(function(res){
            setList({...list, viewContent : res.data});                      
        })  

    }


    useEffect(() => {
 
        if (sWord) {
            setList({...list, sWord:{...list.sWord=sWord}});
        }

        if(sCate01){
            setList({...list, sCate01:{...list.sCate01=sCate01}});
        }
    
        if(sPg) {            
            setList({...list, page:{...list.page=sPg}});
        }
    
        GetTotalCnt();

        GetList();
            
    },[sWord, sCate01, sCate02, sPg]);
    
    
    return(
        <>
        {/* 운영로그 기록 */}
        {(log_set === true) && ( <OpenLogComment pageMode={'접속로그관리 > 실패초기화'} /> )}

        <div id="container" data-menu-page="setup_admin">
            <h3 className="page_ttl">접속로그관리</h3>


                <form onSubmit={onSubmitSearch} >
                    <div className="top_filter">
                        <div className="sel_box">
                            <select name="pUser_level" id="pUser_level" onChange={onSearchCates01} value={list.searchCates01}>
                                    <option value="">거래처 종류</option>
                                                                                                            
                                    {(user_level === 1) && (
                                        <>
                                        <option value="1">통합</option>
                                        <option value="2">지사</option>
                                        <option value="3">총판</option>
                                        <option value="4">대리점</option>
                                        <option value="5">가맹점</option>                                        
                                        </>
                                    )}

                                    {(user_level === 2) && (
                                        <>
                                        <option value="2">지사</option>
                                        <option value="3">총판</option>
                                        <option value="4">대리점</option>
                                        <option value="5">가맹점</option>                                        
                                        </>
                                    )}
                                    {(user_level === 3) && (
                                        <>
                                        <option value="3">총판</option>
                                        <option value="4">대리점</option>
                                        <option value="5">가맹점</option>                                        
                                        </>
                                    )}
                                    {(user_level === 4) && (   
                                        <>
                                        <option value="4">대리점</option>
                                        <option value="5">가맹점</option>                                        
                                        </>
                                    )}
                                                                
                            </select>
                        </div>

                        <div className="sel_box">
                            <select  name="searchCates" id="searchCates" required onChange={onSearchCates02} value={list.searchCates02}>
                                <option value="branch_name">거래처명</option>
                                <option value="user_id">로그인ID</option>
                            </select>
                        </div>

                        <div className="in_box search_box">
                            <input type="text" name="searchWord" id="searchWord" value={list.searchWord} onChange={onSearchWord} placeholder='검색해주세요' />
                            <button type="submit" className="btn_st_02 blue search" >검색</button>
                        </div>
                    

                        
                    </div>
                </form>


                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {(typeof(list.totalCnt) === 'number' ? list.totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0')}개</div>
                    </div>
                    <div className="table_box">


                        <table>

                            <thead>

                                <tr>
                                    <th>No</th>
                                    <th>업체명</th>
                                    <th>업체구분</th>
                                    <th>아이디</th>
                                    <th>등록일</th>
                                    <th>로그인</th>
                                    <th>마지막로그인</th>
                                    <th>접속IP</th>
                                    <th>실패</th>
                                    <th>-</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    (list.viewContent !=='') && (                                            
                                        list.viewContent.map((v, index) => {                                            
                                            return (
                                                <Board
                                                    key={v.idx}
                                                    idx={v.idx}
                                                    nums={list.nums-index}
                                                    branch_name={v.branch_name}                                            
                                                    corp_class={v.corp_class}
                                                    user_id={v.user_id}
                                                    regdate={v.regdate}
                                                    loginis={v.loginis}
                                                    last_login={v.last_login}
                                                    login_ip={v.login_ip}
                                                    login_fail_cnt={v.login_fail_cnt}
                                                />
                                            );
                                        })
                                    )

                                }                                    
                            </tbody>
                        </table>
                    </div>

                    {
                        list.viewContent.length > 0 ? <Paging curPage={list.page} totalPage={Math.ceil(list.totalCnt/list.gListRows)} pageBtnNum={list.pageMax} url={list.url} /> : null
                    }
                </div>
            
        </div>
           
    </>
    )
}

export default ManageLogList