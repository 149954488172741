import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import Header from "../../../../include/SiteAdminHeader";
import Footer from "../../../../include/SiteAdminFooter";

/**
 * 스마트로페이 결제 연동 테스트
 * @returns 
 */
function SiteAdminPGSmartroPay() {

    const navigate = useNavigate();

	// PC/Mobile 구분하여 도메인 변경 필요
	// PC 환경에서 호출시 https://tpay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=현재일자
	// Mobile 환경에서 호출시 https://tmpay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=현재일자
	
	// 운영 전환 시 도메인 변경
	// PC : tpay.smartropay.co.kr -> pay.smartropay.co.kr
	// Mobile : tmpay.smartropay.co.kr -> mpay.smartropay.co.kr
	
	// script 캐시방지를 위해 ?version=today를 추가  ex) SmartroPAY-1.0.min.js?version=20230101
	useEffect(() => {
		const script = document.createElement('script');
		script.src = `https://tpay.smartropay.co.kr/asset/js/SmartroPAY-1.0.min.js?version=&{ today }`;
		script.type = 'text/javascript';
		document.head.appendChild(script);
		return () => {
			document.head.removeChild(script);
		}
	}
	, []);

	// 스마트로 페이 응답은 POST로 응답됩니다.
    
    const [Amt, set_Amt] = useState(1000);
    const [card_no, set_Card_no] = useState("");


    const goPay =()=>{
           
        let bodyPage = {
            mid: 'WONSUNG01m',
            pay_type: 'CREDIT_CARD',
            pay_method: 'CREDIT_UNAUTH_API',
            //card_no: '5236120143674050',
            //card_expiry_ym: '2603',
            card_no: '52361201436743232',
            card_expiry_ym: '2604',
            order_no: Math.floor(100000 + Math.random() * 900000),
            user_name: '이동호',
            amount: '100',
            product_name: '상품명',
            card_sell_mm: '00',
            millis: '1546413445525'       
        }
        
        axios.post('/api/smartroPay/notAuthSmartroPaySned', bodyPage).then(function(res){                        
            console.log('setSmartroPay : ', res.data);                         
        },{
            headers: {
                'Content-Type': 'application/json'               
                }
        })        
    


    }

    
    useEffect(() => {
       
    },[])

    return (
        <>
        <div id="wrap">
            <Header/>        
            <div id="container" data-menu-page="distributor_list">                                
                
                <h3 className="page_ttl">PG SmartroPay 결제 연동 테스트</h3>

                <div className="form_item">                    
                
                   

                    <button onClick={goPay}>결제하기</button>
                  
                </div>        
            </div>
            
        </div>

        <Footer/>
        </>
    )
}
export default SiteAdminPGSmartroPay