import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import Paging from "../../../include/Paging";
import queryString from 'query-string';
import PropTypes from 'prop-types';




function SiteAdminTerminalDirectPaymentList() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    // 페이징 현재 페이지
    const [page, setPage] = useState(1);

    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('sname');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');

    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    // PG idx 처리
    const [parent_idx, set_parent_idx] = useState('');

    const on_set_parent_idx_handler = (event) => {
        set_parent_idx(event.currentTarget.value)
    }

    
    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    const sCate = qs.searchCates ? qs.searchCates : '';
    const sParent_idx = qs.parent_idx ? qs.parent_idx : '';
    const sPg = qs.pg? qs.pg : 1;






    const Board = ({
        idx,
        nums,
        regdate,
        sname,
        pgs,
        store,
        cerify,
        income,
        fee
    }) => {
        let pg_txt = '';
        let cerify_txt = '';
       

        // 계약상태
        if(pgs === "payjoa"){
            pg_txt = '페이조아';
        }else{
            pg_txt = '';
        }


        if(cerify === "Y"){
            cerify_txt = "비생인증";
        }else if(cerify === "N"){
            cerify_txt = "비인증";
        }
     

        regdate = regdate.substr(0,10);
        
        


        return (
            <tr>
                <td className="number_td">{nums}</td>
                <td className="regdate_td">{regdate}</td>
                <td>{pg_txt}</td>
                <td>{store}</td>
                <td>{cerify_txt}</td>
                <td>{sname} -{fee}%</td>
                <td>{fee}% (D+{income})</td>
                <td>
                    <div className="btn_wrap">
                        <Link to={`/SiteAdmin/_terminal/DirectPaymentMod?idx=`+idx} className="btn_st_02 red">상세정보</Link>
                    </div>
                </td>
            </tr>
        );
    };



    function onSubmitSearch(event){
        event.preventDefault();
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
        navigate('/SiteAdmin/_terminal/DirectPaymentList?searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
        
    }


    // 전체 수량 구하는 
    function getTotalCnt(){
        //console.log("getTotalCnt Start");
        let bodyQuery = {
            searchWord: searchWord,
            searchCates: searchCates,
            parent_idx: parent_idx
        }
        console.log("bodyQuery : " + parent_idx);

        axios.post('/api/terminal/direct_payment_total_list', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })

    }



    useEffect(() => {
        //console.log("useEffect : total_cnt");
        //console.log("sWord : " +sWord);
        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
    
        if(sPg) {
            setPage(sPg);
        }

       

        getTotalCnt();
        
    },[sWord, sCate, sPg, sParent_idx,  page]);


    useEffect(()=>{
        /*
        console.log("num 재확인 시작");
        console.log("nums : " + nums);
        console.log("num 재확인 끝");
        */

        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: searchWord,
            searchCates: searchCates,
            parent_idx: parent_idx
        }
        console.log("bodyPage : " + bodyPage);

        axios.post('/api/terminal/direct_payment_list', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            console.log(res.data)
        }) 

        
    },[sWord, sCate, sParent_idx, sPg]);

    return (
        <>
        <div id="wrap">
            <Header/>

            <div id="container" data-menu-page="direct_payment_list">
                <h3 className="page_ttl">수기결제관리</h3>

                <form onSubmit={onSubmitSearch} >
                <div className="top_filter">

                    <div className="sel_box">
                        <select name="parent_idx" id="parent_idx" value={parent_idx} onChange={on_set_parent_idx_handler}>
                            <option value="">= PG사 선택 = </option>
                            <option value="nice">나이스페이</option>
                            <option value="dau">다우데이타</option>
                        </select>
                    </div>
                    
                    <div className="sel_box">
                        <select name="searchCates" id="searchCates" required onChange={onSearchCates} value={searchCates} >
                            <option value="sname">상점명</option>
                            <option value="store">상점코드</option>
                        </select>
                    </div>

                    <div className="in_box">
                        <input type="text" name="searchWord" id="searchWord" value={searchWord} onChange={onSearchWord} />
                    </div>


                    <div className="btn_box">
                        <button type="submit" className="btn_st_02 blue search">검색</button>
                    </div>

                    <div className="btn_box">
                        <Link to="/SiteAdmin/_terminal/DirectPaymentForm" className="btn_st_02 red ">신규 등록</Link>
                    </div>

                </div>
                </form>



                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건</div>

                    </div>
                    <div className="table_box">

                        <table>

                            <thead>
                            
                                <tr>
                                    <th>No</th>
                                    <th>등록일</th>
                                    <th>PG</th>
                                    <th>상점코드</th>
                                    <th>구분</th>
                                    <th>상점관리명</th>
                                    <th>정산조건</th>
                                    <th>-</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    viewContent.map((v, index) => {

                                        return (
                                            <Board
                                                key={v.idx}
                                                idx={v.idx}
                                                nums={nums-index}
                                                regdate={v.regdate}
                                                sname={v.sname}
                                                pgs={v.pg}
                                                store={v.store}
                                                cerify={v.cerify}
                                                fee={v.fee}
                                                income={v.income}
                                                
                                            />
                                        );
                                    })
                                }
                                
                            </tbody>

                        </table>
                    </div>

                    {
                        viewContent.length > 0 ? <Paging curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    }
                </div>


            </div>

        </div>

        <Footer/>
        </>
    )


}
export default SiteAdminTerminalDirectPaymentList