import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import NoticeView from "../../../../page/notice/View";
import Header from "../../../include/AgencyHeader";
import Footer from "../../../include/SiteAdminFooter";



function AgencyManageNoticeView() {


    return (
        <>
        <div id="wrap">
            <Header/>        
            
            <NoticeView user_level={'4'} user_site={'Agency'} />
            
        </div>

        <Footer/>
        </>
    )
}

export default AgencyManageNoticeView