import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import PagingNew from "../../../include/PagingNew";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {DoublePayChk} from "../../../include/ConfigSet";

axios.defaults.withCredentials = true;


function SiteAdminSettlementDataUploadInsTerminal() {

    const navigate = useNavigate();

    // 고객성명
    const [user_name, set_user_name] = useState("");

    const on_user_name_handler = (event) => {
        set_user_name(event.currentTarget.value)
    }

    // 휴대폰번호
    const [user_hp, set_user_hp] = useState("");

    const on_user_hp_handler = (event) => {
        set_user_hp(event.currentTarget.value)
    }

    // 고객이메일
    const [user_email, set_user_email] = useState("");

    const on_user_email_handler = (event) => {
        set_user_email(event.currentTarget.value)
    }

    // 상품명
    const [goods_name, set_goods_name] = useState("");

    const on_goods_name_handler = (event) => {
        set_goods_name(event.currentTarget.value)
    }


    // 결제금액
    const [price, set_price] = useState("");

    const on_price_handler = (event) => {
        set_price(event.currentTarget.value)
    }


    // 카드번호
    const [card_num, set_card_num] = useState("");

    const on_card_num_handler = (event) => {
        set_card_num(event.currentTarget.value)
    }


    // 유효기간 월
    const [month, set_month] = useState("");

    const on_month_handler = (event) => {
        set_month(event.currentTarget.value)
    }


    // 유효기간 년도
    const [year, set_year] = useState("");

    const on_year_handler = (event) => {
        set_year(event.currentTarget.value)
    }


    // 할부기간
    const [installment, set_installment] = useState("00");

    const on_installment_handler = (event) => {
        set_installment(event.currentTarget.value)
    }

    // 카드코드
    const [cardcode, set_cardcode] = useState("00");

    const on_cardcode_handler = (event) => {
        set_cardcode(event.currentTarget.value)
    }

    


    // 생년월일
    const [birth, set_birth] = useState("");

    const on_birth_handler = (event) => {
        set_birth(event.currentTarget.value)
    }


    // 비밀번호
    const [pws, set_pws] = useState("");
    
    const on_pws_handler = (event) => {
        set_pws(event.currentTarget.value)
    }


    // 인증방식
    const [charging_type, set_charging_type] = useState("18");

    const on_charging_type_handler = (event) => {
        set_charging_type(event.currentTarget.value)
    }

    

    // 주문번호
    const [order_no, set_order_no] = useState("");

    // 로그인 아이디
    const [regid, set_regid] = useState("");


    // 가맹점 아이디
    const [loginId, set_loginId] = useState("");

    const on_loginId_handler = (event) => {
        set_loginId(event.currentTarget.value)
    }


    // 거래일자
    const [dateYmd, set_dateYmd] = useState("");

    const on_dateYmd_handler = (event) => {
        set_dateYmd(event.currentTarget.value)
    }

    // 거래시간
    const [dateHis, set_dateHis] = useState("");

    const on_dateHis_handler = (event) => {
        set_dateHis(event.currentTarget.value)
    }
    



    useEffect(() => {
        //getOrderNo();
    },[]);


    function getOrderNo(){

        if( loginId === ""){
            alert("가맹점 아이디를 먼저 입력해주세요.");
            return false;
        }
        
        axios.post('/api/terminal/store/pay/getOrderNo').then(function(res){            
            if(res.data){
                set_order_no(res.data);  
            }

            
    
            let bodyPage = {
                loginId: loginId

            }


            axios.post('/api/store/storeInfo', bodyPage).then(function(response){
                if( response.data.length > 0 ){
                    // PG사 정보 확인
                    //alert( response.data[0].sugi_code1 );
                    alert("확인되었습니다.");

                }else{
                    alert("존재하는 가맹점 아이디가 아닙니다.");
                    return false;

                }
                
                
                


                /*
                let body3 = {
                    user_id: loginId
                }
                
                axios.post('/api/store/pay/getPgInfo', body3).then(function(res){            
                    alert( res.data[0].cerify )
                  
                    if(res.data[0].cerify==="Y"){
                        set_charging_type("13");
                    }else{
                        set_charging_type("18");
                    }
                  

                },[])
                */

                
            });


            
            

        },[])
    }



    

    const on_pay_handler = (event) => {
        event.preventDefault();


        DoublePayChk(dateYmd, card_num, loginId, price).then((result) => {

            if(result === 'E1'){
                alert("당일 한도 금액이 초과 되었습니다.");
                return;
            }
            if(result === 'E2'){
                alert("이번달 한도 금액이 초과 되었습니다.");
                return;
            }
            if(result === 'E3'){
                alert("금일 해당카드로 결제내역이 있습니다.");
                return;
            }
            // if(result === "T"){
            //     alert("금일 해당카드로 결제내역이 있습니다.");
            // }else{

                let body = {
                    dateYmd:dateYmd,
                    dateHis:dateHis,
                    user_name: user_name,
                    user_hp: user_hp,
                    user_email: user_email,
                    goods_name: goods_name,
                    price: price,
                    card_num: card_num,
                    month: month,
                    year: year,
                    installment: installment,
                    cardcode: cardcode,
                    birth: birth,
                    pws: pws,
                    charging_type: charging_type,
                    order_no: order_no,
                    regid: loginId
                }
        
        
                axios.post('/api/terminal/store/pay/notPayAdd', body)
                .then(function(response){
                    
                    if(response.data === "AddOk"){
        
                        let body = {
                            order_no: order_no
                        }
                        
                        axios.post('/payment/payjoaNoPay.php', body,
                        { withCredentials: true }
                        ).then(function(res2){
                            //console.log(res2);
                            if(res2.data.RESULTCODE === "0000"){
                                alert(res2.data.ERRORMESSAGE);
                                
                            }else{
                                if(res2.data.RESULTCODE !== null){
                                    getOrderNo();
                                    alert("결제 실패! 사유 : " + res2.data.ERRORMESSAGE + "\n코드 : " + res2.data.RESULTCODE);
                                }else{
                                    getOrderNo();
                                    alert("결제에 실패했습니다.");
                                    
                                }
                            }
                        
                        })
        
                    }else if(response.data === "Err1"){    
                        alert("이미 등록된 주문번호입니다.");
                        return false;
                    
                    }else{
                        alert("시스템 에러");
                        
                    }
                    
                    console.log(response.data);
                    
                },[])

            // }
        })

        
        
        

    }

    return (
        <>
        <div id="wrap">
            <Header/>

            
    
            <div id="container" data-menu-page="data_upload">
                <h3 className="page_ttl">정산결제</h3>

                <form onSubmit={on_pay_handler}>
                    <input type="hidden" name="order_no" id="order_no" value={order_no} />
                  


                    <div className="form_item">
                        <h3 className="label">* 가맹점 선택</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                    
                                        <th>거래일시  <span className="red">＊</span></th>
                                        <td colSpan="3">
                                            <div className="flex">
                                                <div className="in_box" style={{"width": "168px"}}>
                                                    <input type="text" name="dateYmd" id="dateYmd" value={dateYmd} onChange={on_dateYmd_handler}  placeholder="yyyymmdd" required />
                                                </div>
                                                <div className="in_box"  style={{"width": "168px", "marginLeft": "10px"}}>
                                                    <input type="text" name="dateHis" id="dateHis" value={dateHis} onChange={on_dateHis_handler} placeholder="hhmmss" required />
                                                </div>
                                            </div>                                 
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>가맹점  <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex" >
                                                <div className="in_box" >
                                                    <input type="text" name="loginId" id="" value={loginId} onChange={on_loginId_handler}  placeholder="가맹점 아이디" required />
                                                </div>

                                                <span style={{"marginLeft":"10px"}}>
                                                    <button type="button" className="btn_st_02 blue_line" onClick={getOrderNo} >확인</button>
                                                </span>
                                            </div>
                                            
                                        </td>
                                        <th>주문번호</th>
                                        <td>
                                            { order_no === "" ? "가맹점을 먼저 선택해주세요." : order_no }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                    <div className="form_item">
                        <h3 className="label">* 카드 결제 정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>카드정보  <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="sel_box wd_auto">
                                                <select name="cardcode" id="cardcode" className="wd100" onChange={on_cardcode_handler} required>  
                                                    <option value="">선택</option>
                                                    <option value="CCSU">수협</option>
                                                    <option value="CCSS">삼성카드</option>
                                                    <option value="CCNH">NH카드</option>
                                                    <option value="CCLO">롯데카드</option>
                                                    <option value="CCLG">신한카드</option>
                                                    <option value="CCKM">국민카드</option>
                                                    <option value="CCKJ">광주은행</option>
                                                    <option value="CCKE">외환은행</option>
                                                    <option value="CCJB">전북은행</option>
                                                    <option value="CCHN">하나SK카드</option>
                                                    <option value="CCDI">현대카드</option>
                                                    <option value="CCCT">씨티은행</option>
                                                    <option value="CCCJ">제주은행</option>
                                                    <option value="CCBC">비씨카드</option>
                                                </select>
                                                </div>
                                            
                                                <div className="in_box">
                                                    <input type="text" maxLength="16" name="card_num" id="card_num" value={card_num} onChange={on_card_num_handler} required />
                                                </div>
                                            </div>
                                        </td>
                                        <th>결제금액<span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box"  style={{"marginRight": "10px"}}>
                                                    <input type="text" name="price" id="price" value={price} onChange={on_price_handler} required />
                                                </div>
                                                <span>원</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>유효기간<span className="red">＊</span></th>
                                        <td>
                                            <div className="flex table_cont_1">
                                                <div className="sel_box">
                                                    <select name="month" id="month" className="wd100" onChange={on_month_handler} required>
                                                        <option value="">MONTH</option>
                                                        <option value="01">01</option>
                                                        <option value="02">02</option>
                                                        <option value="03">03</option>
                                                        <option value="04">04</option>
                                                        <option value="05">05</option>
                                                        <option value="06">06</option>
                                                        <option value="07">07</option>
                                                        <option value="08">08</option>
                                                        <option value="09">09</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </div>
                                            

                                                <div className="sel_box">
                                                <select name="year" id="year" className="wd100" onChange={on_year_handler} required>
                                                    <option value="">YEAR</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                    <option value="2031">2031</option>
                                                    <option value="2032">2032</option>
                                                    <option value="2033">2033</option>
                                                    <option value="2034">2034</option>
                                                    <option value="2035">2035</option>
                                                    <option value="2036">2036</option>
                                                    <option value="2037">2037</option>
                                                    <option value="2038">2038</option>
                                                    <option value="2039">2039</option>
                                                    <option value="2040">2040</option>
                                                    <option value="2041">2041</option>
                                                    <option value="2042">2042</option>
                                                    <option value="2043">2043</option>
                                                    <option value="2044">2044</option>
                                                    <option value="2045">2045</option>
                                                    <option value="2046">2046</option>
                                                    <option value="2047">2047</option>
                                                    <option value="2048">2048</option>
                                                    <option value="2049">2049</option>
                                                    <option value="2050">2050</option>
                                                    <option value="2051">2051</option>
                                                    <option value="2052">2052</option>
                                                </select>
                                                </div>
                                            </div>
                                        </td>
                                        <th>할부   <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="sel_box">
                                                <select name="installment" id="installment" className="wd100" onChange={on_installment_handler} required>  
                                                    <option value="00">일시불</option>
                                                    <option value="02">02</option>
                                                    <option value="03">03</option>
                                                    <option value="04">04</option>
                                                    <option value="05">05</option>
                                                    <option value="06">06</option>
                                                    <option value="07">07</option>
                                                    <option value="08">08</option>
                                                    <option value="09">09</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    
                                    <tr>
                                        <th>주문자/연락처 <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex table_cont_1">
                                                <div className="in_box"  style={{"width":"168px", "marginRight": "10px"}}>
                                                    <input type="text" name="user_name" id="user_name" value={user_name} onChange={on_user_name_handler} placeholder="주문자" required />
                                                </div>
                                                <div className="in_box"style={{"width":"168px"}} >
                                                    <input type="text" name="user_hp" id="user_hp" value={user_hp} onChange={on_user_hp_handler} placeholder="연락처" required />
                                                </div>
                                            </div>
                                        </td>
                                        <th>상품명 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box" >
                                                <input type="text" name="goods_name" id="goods_name" value={goods_name} onChange={on_goods_name_handler} placeholder="상품명" required />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                    <div className="top_table_btn">
                        <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>
                        <button type="submit" className="blue  btn_st_01">등록</button>
                    </div>
                </form>


            </div>
            
            
        </div>

        <Footer/>
        </>
    )
}

export default SiteAdminSettlementDataUploadInsTerminal