import * as axios from 'axios';
import React, {useEffect, useState } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import Paging from "../../../include/Paging";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {maskingFunc} from "../../../include/ConfigSet_masking";
import OpenLogComment from "../../../include/OpenLogComment";

function SiteAdminManageSetupAdminList() {

    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    const [log_set_msg, set_log_set_msg] = useState('');
    const [pageReload, set_pageReload] = useState('');    
    React.useEffect(() => {              
        if(log_set === true){
            set_log_set(false);            
        }
    }, [log_set])

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    // 페이징 현재 페이지
    const [page, setPage] = useState(1);

    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('user_id');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');


    const [bank_code, set_bank_code] = useState('');

    const on_bank_code_handler = (event) => {
        set_bank_code(event.currentTarget.value);
    }

    const [bank_name, set_bank_name] = useState('');

    const on_bank_name_handler = (event) => {
        set_bank_name(event.currentTarget.value);
    }



    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    // PG idx 처리
    const [parent_idx, set_parent_idx] = useState('');

    const on_set_parent_idx_handler = (event) => {
        set_parent_idx(event.currentTarget.value)
    }

    
    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    const sCate = qs.searchCates ? qs.searchCates : '';
    const sParent_idx = qs.parent_idx ? qs.parent_idx : '';
    const sPg = qs.pg? qs.pg : 1;


    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            bank_code: bank_code,
            bank_name: bank_name
        }

        axios.post('/api/manage/BankAdd', body)
        .then(function(response){
            
            if(response.data === "AddOk"){                    
                set_log_set_msg('등록');
                set_log_set(true);
                set_pageReload('reload');                                               
            }else{
                alert("시스템 에러");                
            }            
        },[])


    }


    const Fn_Del =(idx)=>{

        if( ! window.confirm("정말 삭제하시겠습니까?")){
            return false;
        }

        let body = {
            idx: idx
        }

        axios.post('/api/manage/SetupAdminDel', body)
        .then(function(response){
            if(response.data === "DelOk"){    
                set_log_set_msg('삭제');
                set_log_set(true);
                set_pageReload('reload');  
            }else{
                alert("시스템 에러");    
            }
        },[])

    }


    const Board = ({
        idx,
        nums,
        user_id,
        user_name,
        user_phone,
        contract_status,
        regdate
    }) => {

        let contract_status_txt = "";

        if(contract_status === "Y"){
            contract_status_txt = "이용";
        }else{
            contract_status_txt = "정지";
        }

        regdate = regdate.substr(0,10);
   
        return (

            <tr>
                <td className="number_td">{nums}</td>
                <td>{contract_status_txt}</td>
                <td className="user_id_td">{user_id}</td>
                <td className="maskingFunc_td">{maskingFunc.name(user_name)}</td>
                <td className="maskingFunc_td">{maskingFunc.phone(user_phone)}</td>
                <td className="regdate_td">{regdate}</td>
                <td>
                    <div className="btn_wrap">
                        <Link to={`/SiteAdmin/_manage/SetupAdminMod?idx=`+idx} className="btn_st_02 blue_line" >관리</Link>
                        <button className="btn_st_02 blue_bg" onClick={()=>{Fn_Del(idx)}}>삭제</button>
                    </div>
                </td>
            </tr>
            
        );
    };



    function onSubmitSearch(event){
        event.preventDefault();
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
        navigate('/SiteAdmin/_manage/SetupAdminList?searchWord='+searchWord+'&searchCates='+searchCates+'&parent_idx='+parent_idx);
        
    }


    // 닫기 버튼을 눌렀을 때
    function pop_close(){
        const bank_pop = document.getElementsByClassName("bank_pop").item(0);
        bank_pop.classList.remove('show');
        set_bank_code("");
        set_bank_name("");
    }

    // 등록 버튼을 눌렀을 때
    function pop_show(){
        const bank_pop = document.getElementsByClassName("bank_pop").item(0);
        bank_pop.classList.add('show');
    }


    // 전체 수량 구하는 
    function getTotalCnt(){
        //console.log("getTotalCnt Start");
        let bodyQuery = {
            searchWord: searchWord,
            searchCates: searchCates
        }

        axios.post('/api/manage/setup_admin_total_list', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })

    }


    useEffect(() => {

        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
    
        if(sPg) {
            setPage(sPg);
        }

        getTotalCnt();
        
    },[sWord, sCate, sPg, sParent_idx,  page]);


    useEffect(()=>{
        /*
        console.log("num 재확인 시작");
        console.log("nums : " + nums);
        console.log("num 재확인 끝");
        */

        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: searchWord,
            searchCates: searchCates,
            parent_idx: parent_idx
        }
        
        
        console.log(bodyPage);

        axios.post('/api/manage/setup_admin_list', bodyPage).then(function(res){
            setViewContent(res.data);
            console.log(res.data)
        }) 

        
    },[sWord, sCate, sParent_idx, sPg]);


    
    return (
        <>
        {(log_set === true) && ( <OpenLogComment pageMode={`운영관리 > 운영자 계정관리 > ${log_set_msg}`} pageReload={pageReload} /> )} {/* 운영로그 기록 */} 
        <div id="wrap">
            <Header/>

            
            <div id="container" data-menu-page="setup_admin">
                <h3 className="page_ttl">운영자 계정관리</h3>

                <form onSubmit={onSubmitSearch} >
                    <div className="top_filter">

                        <div className="sel_box">
                            <select name="searchCates" id="searchCates" required onChange={onSearchCates} value={searchCates} >
                                <option value="user_id">아이디</option>
                                <option value="user_name">이름</option>
                            </select>
                        </div>

                        <div className="in_box search_box">
                            <input type="text" name="searchWord" id="searchWord" value={searchWord} onChange={onSearchWord}  placeholder='검색해주세요'/>
                            <button type="submit" className="btn_st_02 search">검색</button>
                        </div>
                    </div>
                </form>


                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>
                        <div className="right">
                            <Link to="/SiteAdmin/_manage/SetupAdminForm" className="btn_st_02 blue_bg">등록</Link>
                        </div>

                    </div>
                    <div className="table_box">


                        <table>

                            <thead>

                                <tr>
                                    <th>No</th>
                                    <th>현황</th>
                                    <th>아이디</th>
                                    <th>이름</th>
                                    <th>연락처</th>
                                    <th>가입일시</th>
                                    <th>관리</th>
                                </tr>

                            </thead>

                            <tbody>
                                {
                                    viewContent.map((v, index) => {

                                        return (
                                            <Board
                                                key={v.idx}
                                                idx={v.idx}
                                                nums={nums-index}
                                                user_id={v.user_id}
                                                user_name={v.user_name}
                                                user_phone={v.user_phone}
                                                contract_status={v.contract_status}
                                                regdate={v.regdate}
                                            />
                                        );
                                    })
                                }
                                

                            </tbody>
                        </table>
                    </div>

                    {
                        viewContent.length > 0 ? <Paging curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    }
                </div>
            </div>
        </div>

        <Footer/>
        </>
    )


}
export default SiteAdminManageSetupAdminList