import * as axios from 'axios';
import { Link } from "react-router-dom";

//import * as axios from 'axios';
import React, {useEffect, useState, useRef} from 'react'
import ApexCharts from "apexcharts";


import Header from "../../../include/AgencyHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";




function AgencyMainTerminal() {

    const loginId = sessionStorage.getItem("loginId");

    const chartRef = useRef(null);

    // 금일 매출액
    const [todayPrice, set_todayPrice] = useState(0);

    // 금일 입금액
    const [todayIncome, set_todayIncome] = useState(0);

    // 금일 차감액
    const [todaySpend, set_todaySpend] = useState(0);



    // 전일 매출액
    const [yesterdayPrice, set_yesterdayPrice] = useState(0);

    // 전일 입금액
    const [yesterdayIncome, set_yesterdayIncome] = useState(0);

    // 전일 차감액
    const [yesterdaySpend, set_yesterdaySpend] = useState(0);



    // 전주 매출액
    const [weekPrice, set_weekPrice] = useState(0);

    // 전주 입금액
    const [weekIncome, set_weekIncome] = useState(0);

    // 전주 차감액
    const [weekSpend, set_weekSpend] = useState(0);


    // 전월 매출액
    const [monthPrice, set_monthPrice] = useState(0);

    // 전월 입금액
    const [monthIncome, set_monthIncome] = useState(0);

    // 전월 차감액
    const [monthSpend, set_monthSpend] = useState(0);



    // 결제완료건수
    const [pay_cnt7 , set_pay_cnt7] = useState(0);

    // 결제금액
    const [pay_hap7 , set_pay_hap7] = useState(0);


    // 정산내역 - 금액
    const [pay_end_hap , set_pay_end_hap] = useState(0);


    
    
    // PG 수수료
    const [pg_fee , set_pg_fee] = useState(0);

    // 가맹점 수수료
    const [st_fee , set_st_fee] = useState(0);

    // 지사 수수료
    const [br_fee , set_br_fee] = useState(0);

    // 총판 수수료
    const [di_fee , set_di_fee] = useState(0);

    // 대리점 수수료
    const [ag_fee , set_ag_fee] = useState(0);

    const [notice_res, set_notice_res] = useState({
        code : 'notice',
        user_level : '3'
    });

    const [notice_res2, set_notice_res2] = useState({
        code : 'notice',
        user_level : '3',
        user_id: sessionStorage.getItem("loginId")
    });
    
    const [noticeContent , setNoticeContent] = useState([]);

    // 차트에 넣을 기간
    let chart_caption = "";
    let today = new Date();
    var oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
    var oneYearAgoFormatted = oneYearAgo.getFullYear() + '.' + (oneYearAgo.getMonth() + 1).toString().padStart(2, '0');
    var nowFormatted = today.getFullYear() + '.' + (today.getMonth() + 1).toString().padStart(2, '0');
    chart_caption = oneYearAgoFormatted + "~" + nowFormatted;

    
    // 차트 날짜 부분 구하기
    let chart_x = [];

    // 차트에 들어갈 월별 매출 배열
    let chart_price = [];

    // 차트에 들어갈 월별 건수 배열
    let chart_cnt = [];


    const [faqContent , setFaqContent] = useState([]);
    const [qnaContent , setQnaContent] = useState([]);


    useEffect(()=>{
        let bodyQuery = {
            sdate: oneYearAgoFormatted,
            edate: nowFormatted,
            user_id: loginId
        }

        axios.post('/api/terminal/ag/chart_month_price', bodyQuery).then(function(res){
            
            let chart_price_list = res.data;

            for(let i=1; i<=12; i++){
            

                //oneYearAgo = new Date(today.getFullYear() - i, today.getMonth(), today.getDate());
                let tmpDay = new Date(oneYearAgo.getFullYear(), oneYearAgo.getMonth() + i, oneYearAgo.getDate());
                let tmpDayFormatted = tmpDay.getFullYear() + '.' + (tmpDay.getMonth() + 1).toString().padStart(2, '0');
                tmpDayFormatted = tmpDayFormatted.toString();
                
                // 라벨 배열 추가
                chart_x.push(tmpDayFormatted);
        
                let arr_index = chart_price_list.findIndex(v => v.months === tmpDayFormatted);
    
                if( arr_index < 0){
                    chart_price.push(0);
                    chart_cnt.push(0);
                }else{
                    // 매출부분입력
                    //alert(chart_price_list[arr_index].total_amount);
                    //console.log(chart_price_list[arr_index].total_amount)
                    chart_price.push(chart_price_list[arr_index].total_amount);
                    chart_cnt.push(chart_price_list[arr_index].total_cnt);
                }
    
            }



            let options = {
                series: [{
                    name: '매출내역',
                    type: 'column',
                    //data: [23000, 11000, 22000, 27000, 13000, 22000, 37000, 21000, 44000, 22000, 30000, 22000]
                    data: chart_price
                }, {
                    name: '매출건수',
                    type: 'line',
                    //data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 36]
                    data: chart_cnt
                }],
                colors : ['#3a82f8', '#ff2c27'],
                chart: {
                    height: 510,
                    type: 'line',
                    stacked: false,
                },
                stroke: {
                    width: [0, 4],
                    curve: 'straight',
                    colors: ['#3a82f8', '#ff2c27'],
        
                },
                plotOptions: {
                    bar: {
                    borderRadius:12 ,
                    columnWidth: '30%'
                    }
                },
            
                dataLabels: {
                    style: {
                    colors: ['#3a82f8', '#ff2c27']
                    }
                },
        
                fill: {
                    opacity: [0.85, 0.25, 1],
                    colors: ['#3a82f8', '#ff2c27'],
                    
                    gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                    }
                },
                labels: chart_x,
            
                markers: {
                    size: 4,
                    colors: ["#ff2c27"],
                    showNullDataPoints: true,
                    hover: {
                        size: undefined,
                        sizeOffset: 3
                    }
        
                },
                xaxis: {
                    type: 'category',
                    labels: {
                        datetimeFormatter: {
                        year: 'yyyy',
                        month: 'yyyy \'MM'
                        }
                    },
                },
                yaxis: [
                    {
                    title: {
                    },
                    },
                    {
                    opposite: true,
                    title: {
                    }
                    }
                ],
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {

                        /*
                        formatter: function (y) {
                            
                            if (typeof y !== "undefined") {
                                return y.toFixed(0) + " 원";
                            }

                            return y;
                    
                        }
                        */
                        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                            //return value
                            if(seriesIndex === 0){
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 원";
                            }else{
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 건";
                            }
                            
                        }
                    }
                },
                subtitle: {
                    text: "월별 매출액 : 최근 12개월 ("+chart_caption+")",
                    align: 'left',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                    fontSize:  '18px',
                    fontWeight:  '500',
                    fontFamily: 'Spoqa Han Sans Neo',
                    color:  '#252525'
                    },
                }
        
            };



            const chart = new ApexCharts(chartRef.current, options);
            chart.render();
            
        })

        axios.post('/api/manage/NoticeMainTop', notice_res).then(function(res){
            setNoticeContent(res.data)
        }) 

        axios.post('/api/manage/FaqMainTop', notice_res).then(function(res){
            setFaqContent(res.data)
        }) 

        axios.post('/api/manage/QnaMainTop', notice_res2).then(function(res){
            setQnaContent(res.data)
        }) 
  
    },[])


    // 금일 매출액
    function getTodayPrice(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/today_price', bodyQuery).then(function(res){            
            set_todayPrice(res.data.total_price);
        })
    }

    // 금일 입금액
    function getTodayIncome(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/todayIncome', bodyQuery).then(function(res){            
            set_todayIncome(res.data.total_price);
        })
    }

    // 금일 차감액
    function getTodaySpend(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/todaySpend', bodyQuery).then(function(res){            
            set_todaySpend(res.data.total_price);
        })
    }


    // 전일 매출액
    function getYesterdayPrice(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/yesterday_price', bodyQuery).then(function(res){            
            set_yesterdayPrice(res.data.total_price);
        })
    }

    // 전일 입금액
    function getYesterdayIncome(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/yesterdayIncome', bodyQuery).then(function(res){            
            set_yesterdayIncome(res.data.total_price);
        })
    }

    // 전일 차감액
    function getYesterdaySpend(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/yesterdaySpend', bodyQuery).then(function(res){            
            set_yesterdaySpend(res.data.total_price);
        })
    }



    // 전주 매출액
    function getWeekPrice(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/week_price', bodyQuery).then(function(res){            
            set_weekPrice(res.data.total_price);
        })
    }

    // 전주 입금액
    function getWeekIncome(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/weekIncome', bodyQuery).then(function(res){            
            set_weekIncome(res.data.total_price);
        })
    }

    // 전주 차감액
    function getWeekSpend(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/weekSpend', bodyQuery).then(function(res){            
            set_weekSpend(res.data.total_price);
        })
    }



    // 전월 매출액
    function getMonthPrice(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/month_price', bodyQuery).then(function(res){            
            set_monthPrice(res.data.total_price);
        })
    }

    // 전월 입금액
    function getMonthIncome(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/monthIncome', bodyQuery).then(function(res){            
            set_monthIncome(res.data.total_price);
        })
    }

    // 전월 차감액
    function getMonthSpend(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/monthSpend', bodyQuery).then(function(res){            
            set_monthSpend(res.data.total_price);
        })
    }



    // 결제완료건수
    function get_pay_cnt7(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/pay_cnt7', bodyQuery).then(function(res){            
            let tmp_cnt = res.data.tcnt;        
            if(tmp_cnt == null){
                tmp_cnt = 0;
            }
            set_pay_cnt7(tmp_cnt);
        })
    }

    // 결제금액
    function get_pay_hap7(){
        let bodyQuery = {
            user_id: loginId
        }
        axios.post('/api/terminal/ag/pay_hap7', bodyQuery).then(function(res){            
            let tmp_tprice = res.data.tprice;
            if(tmp_tprice == null){
                tmp_tprice = 0;
            }
            set_pay_hap7(tmp_tprice);
        })
    }



    useEffect(() => {
        getTodayPrice();
        getTodayIncome();
        getTodaySpend();
        getYesterdayPrice();
        getYesterdayIncome();
        getYesterdaySpend();
        getWeekPrice();
        getWeekIncome();
        getWeekSpend();
        getMonthPrice();
        getMonthIncome();
        getMonthSpend();
        get_pay_cnt7();
        get_pay_hap7();        
    },[]);

    return (
        <>
        <div id="wrap">
            <Header/>

            <div id="container" className="main">

                {/* payment_info_sec */}
                <div className="payment_info_sec main_cont">
                    <div className="w_80">
                        <ul className="payment_info_row">
                            <li>
                                <strong className="label">
                                    금일 매출액 <span>※현재기준</span>
                                </strong>
                                <p className="all_pay">
                                    <b>{(""+todayPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b>원
                                </p>
                                <div className="pay_detail">
                                    <dl>
                                        <dt>입금액</dt>
                                        <dd>{(""+todayIncome).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                    </dl>
                                    <dl>
                                        <dt>차감액</dt>
                                        <dd>{(""+todaySpend).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <strong className="label">
                                    전 일 매출액
                                </strong>
                                <p className="all_pay">
                                    <b>{(""+yesterdayPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b>원
                                </p>
                                <div className="pay_detail">
                                    <dl>
                                        <dt>입금액</dt>
                                        <dd>{(""+yesterdayIncome).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                    </dl>
                                    <dl>
                                        <dt>차감액</dt>
                                        <dd>{(""+yesterdaySpend).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <strong className="label">
                                    전 주 매출액
                                </strong>
                                <p className="all_pay">
                                    <b>{(""+weekPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b>원
                                </p>
                                <div className="pay_detail">
                                    <dl>
                                        <dt>입금액</dt>
                                        <dd>{(""+weekIncome).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                    </dl>
                                    <dl>
                                        <dt>차감액</dt>
                                        <dd>{(""+weekSpend).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <strong className="label">
                                    전 월 매출액
                                </strong>
                                <p className="all_pay">
                                    <b>{(""+monthPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b>원
                                </p>
                                <div className="pay_detail">
                                    <dl>
                                        <dt>입금액</dt>
                                        <dd>{(""+monthIncome).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                    </dl>
                                    <dl>
                                        <dt>차감액</dt>
                                        <dd>{(""+monthSpend).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</dd>
                                    </dl>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="w_20">
                        <div className="color_cont  top">
                            <div className="left">
                                <p>결제완료건수</p>
                                <span>최근 1주일 이내</span>
                            </div>
                            <div className="right">
                                <b>{(""+pay_cnt7).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b>건
                            </div>
                        </div>
                        <div className="color_cont  bot">
                            <div className="left">
                                <p>결제금액</p>
                                <span>최근 1주일 이내</span>
                            </div>
                            <div className="right">
                                <b>{(""+pay_hap7).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b>원
                            </div>
                        </div>
                    </div>
                </div>
                {/*  // payment_info_sec // */}

                {/* calculate_sec */}
                <div className="calculate_sec main_cont">
                    <div className="graph_cont">    
                        <div>
                            <div id="chart" ref={chartRef} />
                        </div>
                    </div>
                </div>
                {/* // calculate_sec //  */}

                {/* list_cont_sec */}
                <div className="bod_list_cont main_cont">
                    <ul className="bod_list_wrap">
                        <li>
                            <div className="ttl">
                                <strong>공지사항</strong>
                                <Link to ="/Agency/_manage/NoticeList">
                                    더보기 <i>&#xE001;</i>
                                </Link>
                            </div>
                            <ul className="bod_list">
                                {
                                    noticeContent.map((v, index) => {
                                        return (
                                            <li>
                                                <Link to ={`/Agency/_manage/noticeView?idx=${v.idx}`}>
                                                    <span className="sb_ttl">{v.subject}</span>
                                                    <em className="date">{v.regdate.substr(0,10)}</em>
                                                </Link>
                                            </li>
                                        );
                                    })
                                }
                                {(noticeContent.length == 0 ) && (
                                    <li>등록된 글이 없습니다.</li>
                                )}
                            </ul>
                        </li>
                        <li>
                            <div className="ttl">
                                <strong>자주하는 질문</strong>
                                <Link to ="/Agency/_manage/FaqList">
                                    더보기 <i>&#xE001;</i>
                                </Link>
                            </div>
                            <ul className="bod_list">
                                {
                                    faqContent.map((v, index) => {
                                        return (
                                            <li>
                                                <Link to ={`/Agency/_manage/FaqView?idx=${v.idx}`}>
                                                    <span className="sb_ttl">{v.subject}</span>
                                                    <em className="date">{v.regdate.substr(0,10)}</em>
                                                </Link>
                                            </li>
                                        );
                                    })
                                }
                                {(faqContent.length == 0 ) && (
                                    <li>등록된 글이 없습니다.</li>
                                )}
                            </ul>
                        </li>
                        <li>
                            <div className="ttl">
                                <strong>1대1 문의</strong>
                                <Link to ="/Agency/_manage/QnaList">
                                    더보기 <i>&#xE001;</i>
                                </Link>
                            </div>
                            <ul className="bod_list">
                                {
                                    qnaContent.map((v, index) => {
                                        return (
                                            <li>
                                                <Link to ={`/Agency/_manage/QnaView?idx=${v.idx}`}>
                                                    <span className="sb_ttl">{v.subject}</span>
                                                    <em className="date">{v.regdate.substr(0,10)}</em>
                                                </Link>
                                            </li>
                                        );
                                    })
                                }
                                {(qnaContent.length == 0 ) && (
                                    <li>등록된 글이 없습니다.</li>
                                )}
                            </ul>
                        </li>
                    </ul>
                </div>
                {/*  // list_cont_sec // */}

            {/* <FooterInfo/> */}
            </div>
        </div>

        <Footer/>
        </>
    )
}

export default AgencyMainTerminal