import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';



import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";

import StoreMod from "../../../../page/store/Mod";


function SiteAdminManagementStoreTerminalMod() {

    

    return (
        <>
        <div id="wrap">
            <Header/>
                        
            <StoreMod pay_type={"Terminal"}/>
                                
        </div>

        <Footer/>
        </>
    )

}

export default SiteAdminManagementStoreTerminalMod