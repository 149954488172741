import * as axios from 'axios';
import React, {useEffect} from 'react'

import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import StoreList from "../../../../page/store/List";

function SiteAdminManagementStoreTerminalList() {
            
    useEffect(() => {
      
    },[])


    return (
        <>
        <div id="wrap">
            <Header/>

            
            <StoreList pay_type={"Terminal"}/>

        </div>

        <Footer/>
        </>
    )


}
export default SiteAdminManagementStoreTerminalList