import * as axios from 'axios';
import React, {useEffect, useState, useRef} from 'react'
import { useNavigate } from 'react-router-dom';



import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import StoreForm from "../../../../page/store/Form";

function SiteAdminManagementStoreForm() {

    
    useEffect(() => {
       
    },[])



    return (
        <>
        <div id="wrap">
            <Header/>

        
            <StoreForm pay_type={""}/>
            
        </div>

        <Footer/>
        </>
    )
}

export default SiteAdminManagementStoreForm