import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import '../../../assest/css/adm_login.css';
import '../../../assest/css/reset.css';
import img_logo from '../../../assest/img/intro/logo.png';
import re_closebtn_w from '../../../assest/img/common/re_closebtn_w.png';

import {useDispatch} from 'react-redux';
import { loginUser } from '../../../_action/user_action';
import { useNavigate } from 'react-router-dom';
import {useCookies} from 'react-cookie'
import moment from 'moment';




function SiteAdmin(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [CookieId, setCookieId, removeCookieId] = useCookies(['save_id']);
    const [saveid_chk, setSaveid_chk] = useState((CookieId.save_id === undefined ? false : true)); 
        
    const [LoginId, setLoginId] = useState((CookieId.save_id === undefined ? '' : CookieId.save_id));
    const [Password, setPassword] = useState("");
    const [userIp, setUserIp] = useState("");
    const [loginPopup, setLoginPopup] = useState(false);
    const [loginType, setLoginType] = useState("EMAIL");
    const [typeNumber, setTypeNumber] = useState(""); //메일 및 휴대폰 번호
    const [authNumber, setAuthNumber] = useState(""); //인증번호 확인
    const [CookieCertNum, setCookieCertNum, removeCookieCertNum] = useCookies(['cert_num']); // 쿠키 훅 
    
    const onIdHandler = (event) => {
        setLoginId(event.currentTarget.value)
    }
    
    const onTypeNumberAuthHandler = (event) => {
        setAuthNumber(event.currentTarget.value)
    }
    
    const onPasswordHandler = (event) => {
        setPassword(event.currentTarget.value)
    }

    const onTypeNumberHandler = (event) => {
        setTypeNumber(event.currentTarget.value)
    }

    const onSubmitHandler = (event) => {
        event.preventDefault();
        let body = {
            loginid: LoginId,
            password: Password
        }
        axios.post('/api/user/login', body)
        .then(function(res){

            if(typeof(res.data) !== 'string'){       
                setLoginPopup(true);   
            }else{
                alert(res.data);              
            }  

        },[])
        
    }
 
    const Fn_Pass_Login =()=>{
        let body = {
            loginid: LoginId,
            password: Password,
            userIp: userIp
        }
        
        axios.post('/api/user/login', body)
        .then(function(res){
 
            if(typeof(res.data) !== 'string'){  
                
                if(saveid_chk === true){                            
                    const pExpires =  moment().add(7,'d').toDate(); //7일후       
                    setCookieId('save_id', res.data.user_id, {expires:pExpires})
                }else{
                    removeCookieId(['save_id']);
                }

                sessionStorage.setItem("loginId", res.data.user_id);
                sessionStorage.setItem("loginIdx", res.data.idx);
                navigate('/SiteAdmin/_main/main');

            }else{
                alert(res.data);              
            }  

      
        },[])
    }
    
    useEffect(()=>{
        removeCookieCertNum(['cert_num']);
    }, [])

    useEffect(()=>{
    
    }, [loginType])
    

    //인증 팝업 닫기
    const Fn_PopupClose =()=>{
        setLoginPopup(false);
        setLoginType('EMAIL')
    }

    //인증타입 선택
    const Fn_TabState =(tab)=>{
        setLoginType(tab);
    }

    const Fn_Send_Email =()=>{
        
        if(typeNumber === ''){
            alert("이메일을 입력해주세요.");
            return;
        }

        const cert_num = Math.floor(Math.random() * 999999);

        let body = {
            receivers: typeNumber,
            subject:'[유월] 인증번호를 보내드립니다.',
            content:'인증번호 : ' + cert_num
        }
        
        axios.post('/api/email/sendMail', body)
        .then(function(res){
            alert("인증번호를 발송하였습니다.");
            const pExpires =  moment().add(3, "m").toDate(); //3분후
            setCookieCertNum('cert_num', cert_num, {expires: pExpires})
            //console.log('res : ', res);
        }).catch((err) => {
            //console.log('err : ', err);
        })

    }

    const Fn_Send_Hp =()=>{
        if(typeNumber === ''){
            alert("이메일을 입력해주세요.");
            return;
        }

        const cert_num = Math.floor(Math.random() * 999999);

        let body = {
            receivers: typeNumber,
            message: '인증번호 : ' + cert_num
        }

        axios.post('/api/sms/send', body)
        .then(function(res){
            
            console.log('response : ', res.data);
            alert("인증번호를 발송하였습니다.");      
            const pExpires =  moment().add(3, "m").toDate(); //3분후
            setCookieCertNum('cert_num', cert_num, {expires:pExpires})

        }).catch((err) => {
            //console.log('err : ', err);
        })

    }

    const Fn_Proc_Email =()=>{
        if(authNumber === CookieCertNum.cert_num ){
            sessionStorage.setItem("loginId", LoginId);
            navigate('/SiteAdmin/_main/main');
        }else{
            alert('인증번호가 일치하지 않습니다.');
        }
        
    }

    const Fn_Save_Id =()=>{
        
        if(saveid_chk === false){
            setSaveid_chk(true)
        }else{            
            setSaveid_chk(false)            
        }
    }

    //아이피 불러오기
    const Fn_IP = async()=>{
        const ipData = await fetch('https://geolocation-db.com/json/');
        const locationIp = await ipData.json();    
        setUserIp(locationIp.IPv4);      
    }

    useEffect(() => {
        Fn_IP();    
    },[]);

    

    
    return (
        <>
    
            <div className='intro bg03'>
                <div className="login_box">
                    <form onSubmit={onSubmitHandler}>
                        <div className="login_ttl">
                            <h1 className="logo">
                                <img src={img_logo} alt="" />
                            </h1>
                            <em>유월페이에 오신 것을 환영합니다.</em>
                        </div>
                        <div className="input_box">
                            <div className="input_wrap">
                                <input type="text" id="loginId" name="loginId" required value={LoginId} placeholder="아이디를 입력해주세요." onChange={onIdHandler} />
                            </div>
                            <div className="input_wrap">
                                <input type="password" id="passwd" name="passwd" required value={Password} placeholder="비밀번호를 입력해주세요." onChange={onPasswordHandler} />
                            </div>
                        </div>
                        <div className="option_box">
                            <div className="check_box">
                                <input type="checkbox" id="save_id" name="save_id" checked={saveid_chk} value="Y" onClick={Fn_Save_Id} />
                                <label htmlFor="save_id">ID저장</label>
                            </div>	
                        
                        </div>		
                        <div className="btn_wrap">
                            <button type="submit" className="login_btn" >로그인</button>

                            <Link to='#' style={{paddingTop: '20px'}} onClick={Fn_Pass_Login} >인증없이 로그인</Link>
                            
                        </div>
                        <div className="add_info">
                            <span>FAX : 02.3439.0773 / Email : uwal@uwal.co.kr</span>
                            <span>고객센터 : 02.3667.0774</span>
                        </div>
                        <p className="copyright">designed by uwal communication</p>	
                    </form>
                </div>
            </div>






            <div className="pop_common intro_pop login_confirm01" style={{display:(loginPopup === true ? 'block' : 'none')}} > 
                <div className="pop_cont ">
                    <div className="pop_top">
                        <h3 className="pop_ttl">로그인 추가인증 선택</h3>
                        <button type="button" onClick={Fn_PopupClose}>
                            <img src={re_closebtn_w} alt="닫기버튼" />
                        </button>
                    </div>
                
                    <div className="pop_item">
                        <div className="pop_inner">
                            <span className="txt">
                                정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 28조에 제 1항 제2호 개인정보의<br/>
                                보호조치에 따라 “로그인 시 추가인증"이 필요하게 되었습니다. 따라서 추가인증 수단을 사전에<br/> 
                                등록하신 후 로그인시 해당 수단을 선택하여 인증하여 주시기 바랍니다.
                            </span>
                            <em>아래 인증 수단 중 택일하여 추가인증을 진행하여 주시기 바랍니다.</em>
                            <div className="btn_box confirm_tab">
                                <Link to='#' className={`btn_st_02   ${(loginType === 'EMAIL' || loginType === '' ? 'on' : "")}`} onClick={()=>{Fn_TabState('EMAIL')}}>이메일 인증</Link>
                                <Link to='#' className={`btn_st_02  ${(loginType === 'HP' ? 'on' : "")}`} onClick={()=>{Fn_TabState('HP')}} >휴대폰 인증</Link>
                            </div>				
                            <div className="tab_wrap">
                                <div className="tab" style={{display:"block"}}>
                                   
                                    		
                                    <div className="pop_table">

                                        

                                        <table className="table_layout01">
                                            <colgroup>
                                                <col width="20%" />
                                                <col width="*" />
                                            </colgroup>
                                            
                                            <tbody>

                                                {
                                                    (loginType === 'EMAIL') && (
                                                        <tr>
                                                            <th>이메일</th>
                                                            <td>
                                                                <div className="button_row flex_box">
                                                                    <input className="" 
                                                                        type="text" 
                                                                        name="auth_no1" 
                                                                        id="auth_no1"  
                                                                        placeholder="이메일 주소"  
                                                                        value={typeNumber}  
                                                                        onChange={onTypeNumberHandler} />
                                                                    <button className="list_btn" type="button" onClick={Fn_Send_Email}>전송</button>
                                                                </div>
                                                        
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                
                                                {
                                                    (loginType === 'HP') && (
                                                        <tr>
                                                            <th>휴대폰번호</th>
                                                            <td>
                                                                <div className="button_row flex_box">
                                                                    <input className="" type="text" name="auth_no1" id="auth_no1" placeholder="휴대폰 번호(-)제외" 
                                                                        value={typeNumber}  
                                                                        onChange={onTypeNumberHandler} />
                                                                    <button className="list_btn" type="button" onClick={Fn_Send_Hp} >전송</button>
                                                                </div>
                                                        
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                
                                                <tr>
                                                    <th>인증번호</th>
                                                    <td>
                                                        <div className="button_row flex_box">
                                                            <input className="" type="text" 
                                                            name="auth_no1" 
                                                            id="auth_no1" 
                                                            maxLength={6} 
                                                            placeholder="인증번호" 
                                                            value={authNumber}
                                                            onChange={onTypeNumberAuthHandler} />
                                                            <button className="list_btn" type="button" onClick={Fn_Proc_Email}>인증번호 확인</button>
                                                        </div>
                                                
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>					
                                </div>


                                {/* <div className="tab">
                                    <span className="txt">· 가맹점관리자 계정 내 등록된 휴대폰 번호로 인증번호가 발송됩니다.</span>
                                    <span className="txt">· 선택한 정보로 수신된 인증번호를 입력하여 주시기 바랍니다.</span>
                                    <span className="txt">· '다른 휴대폰 등록'을 클릭하여 인증정보 추가 등록이 4개까지 가능합니다</span>
                                    <div className="inner_bottom login_add_radio">
                                        <ul>
                                            <li>
                                                <div className="radio_box">
                                                    <input type="radio" id="tel_select1" name="tel_select" value="010-0000-0000" checked />
                                                    <label for="tel_select1" >
                                                        <span>주식회사 스타업 <i>010-0000-0000</i></span>
                                                    </label>
                                                </div>	
                                            </li>
                                            <li>
                                                <div className="radio_box">
                                                   <input type="radio" id="tel_select2" name="tel_select" value="010-1234-1234" /> 
                                                    <label for="tel_select2" >
                                                        <span>주식회사 스타업 <i>010-1234-1234</i></span>
                                                    </label>
                                                </div>	
                                            </li>
                                        </ul>
                                        <div className="btn_box intro_btn">
                                            <a href="javascript:auth_js('1');" className="dark_full_btn">문자 발송</a>
                                            
                                            <a href="javascript:void(0);" className="gray_line_btn register_phone">다른 휴대폰 등록</a>
                                            
                                        </div>
                                    </div>				
                                    <div className="pop_table">
                                        
                                    </div>					
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="dim"></div>
            </div>


        {/*             
            <div className="pop_common intro_pop login_confirm01" style={{display:'block'}} > 
                <div className="pop_cont ">
                    <div className="pop_top">
                        <h3 className="pop_ttl">로그인 추가인증 선택</h3>
                        <button type="button" onclick="pop_close();">
                            <img src={re_closebtn_w} alt="닫기버튼" />
                        </button>
                    </div>
                
                    <div className="pop_item">
                        <div className="pop_inner">
                            <span className="txt">
                                정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 28조에 제 1항 제2호 개인정보의<br/>
                                보호조치에 따라 “로그인 시 추가인증"이 필요하게 되었습니다. 따라서 추가인증 수단을 사전에<br/> 
                                등록하신 후 로그인시 해당 수단을 선택하여 인증하여 주시기 바랍니다.
                            </span>
                            <em>아래 인증 수단 중 택일하여 추가인증을 진행하여 주시기 바랍니다.</em>
                            <div className="btn_box confirm_tab">
                                <a href="javascript:void(0);" className="dark_line_btn on">이메일 인증</a>
                                <a href="javascript:void(0);" className="dark_line_btn ">휴대폰 인증</a>
                            </div>				
                            <div className="tab_wrap">
                                <div className="tab" style={{display:"block"}}>
                                   
                                    <div className="inner_bottom login_add_radio">
                                        
                                        <ul>
                                            <li>
                                                <div className="radio_box">
                                                    <input type="radio" id="email_select1" name="email_select" value="starup@starmon.co.kr" checked />
                                                    <label for="email_select1" >
                                                        <span>주식회사 스타업 <i>starup@starmon.co.kr</i></span>
                                                    </label>
                                                </div>	
                                            </li>
                                            <li>
                                                <div className="radio_box">
                                                    <input type="radio" id="email_select2" name="email_select" value="jdy.dev@starmon.co.kr" />
                                                    <label for="email_select2" >
                                                        <span>주식회사 스타업 <i>jdy.dev@starmon.co.kr</i></span>
                                                    </label>
                                                </div>	
                                            </li>
                                        
                                            
                                        </ul>
                                        <div className="btn_box intro_btn">
                                            <a href="#!" className="dark_full_btn">이메일 발송</a>
                                            
                                            <a href="javascript:void(0);" className="gray_line_btn register_email">다른 이메일 주소 등록</a>
                                            
                                        </div>
                                    </div>				
                                    <div className="pop_table">
                                        <table className="table_layout01">
                                
                                            <tbody>
                                                <tr>
                                                    <th>인증번호</th>
                                                    <td>
                                                        <div className="button_row flex_box">
                                                            <input className="" type="text" name="auth_no1" id="auth_no1" placeholder="인증번호" />
                                                            <button className="list_btn" type="button">인증번호 확인</button>
                                                        </div>
                                                
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>					
                                </div>


                                <div className="tab">
                                    <span className="txt">· 가맹점관리자 계정 내 등록된 휴대폰 번호로 인증번호가 발송됩니다.</span>
                                    <span className="txt">· 선택한 정보로 수신된 인증번호를 입력하여 주시기 바랍니다.</span>
                                    <span className="txt">· '다른 휴대폰 등록'을 클릭하여 인증정보 추가 등록이 4개까지 가능합니다</span>
                                    <div className="inner_bottom login_add_radio">
                                        <ul>
                                            <li>
                                                <div className="radio_box">
                                                    <input type="radio" id="tel_select1" name="tel_select" value="010-0000-0000" checked />
                                                    <label for="tel_select1" >
                                                        <span>주식회사 스타업 <i>010-0000-0000</i></span>
                                                    </label>
                                                </div>	
                                            </li>
                                            <li>
                                                <div className="radio_box">
                                                    <input type="radio" id="tel_select2" name="tel_select" value="010-1234-1234" />
                                                    <label for="tel_select2" >
                                                        <span>주식회사 스타업 <i>010-1234-1234</i></span>
                                                    </label>
                                                </div>	
                                            </li>
                                        </ul>
                                        <div className="btn_box intro_btn">
                                            <a href="javascript:auth_js('1');" className="dark_full_btn">문자 발송</a>
                                            
                                            <a href="javascript:void(0);" className="gray_line_btn register_phone">다른 휴대폰 등록</a>
                                            
                                        </div>
                                    </div>				
                                    <div className="pop_table">
                                        
                                    </div>					
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="dim"></div>
            </div>
        */}


            <div className="pop_common intro_pop login_confirm02 " >
                <div className="pop_cont ">
                    <div className="pop_top">
                        <h3 className="pop_ttl">로그인 추가인증 선택</h3>
                        <button type="button" />
                            <img src="./_common/img/common/re_closebtn_w.png" alt="닫기버튼" />
                    </div>
                
                    
                    <div className="pop_item">
                        <div className="pop_inner">
                            <div className="inner_top">
                                <span className="txt">
                                    등록된 휴대폰 번호 이외에 추가로 등록하실 다른 휴대폰 번호를 입력해주시기 바랍니다.<br/>
                                    아래 입력하신 인증 정보는 다음 추가 인증 시 리스트에 포함됩니다.
                                </span>			
                            </div>
                            <div className="pop_table">

                                <table className="table_layout01">
                                    
                                    <tbody>
                                            <tr>
                                                <th>MID(상점아이디)</th>
                                                <td>Astarup</td>
                                            </tr>
                                            <tr>
                                                <th>정산계좌번호<br />(뒤 5자리)</th>
                                                <td>
                                                    <div className="flex_box count_num">
                                                        <span>  ***** &nbsp;</span>
                                                        <input type="text" name="account_num1" id="account_num1" placeholder="" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>회사명</th>
                                                <td>
                                                    <input type="text" name="business_nm1" id="business_nm1" placeholder="" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>휴대폰번호</th>
                                                <td>
                                                    <div className="button_row flex_box">
                                                        <input type="text" name="check_no1" id="check_no1" placeholder="010-0000-0000" />
                                                        <button type="button" className="list_btn">인증번호 발송</button>
                                                    </div>
                                            
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>인증번호</th>
                                                <td>
                                                    <div className="button_row flex_box">
                                                        <input type="text" name="auth_no_1" id="auth_no_1" placeholder="인증번호" />
                                                        <button type="button" id="auth_no_3_check1" className="confirm_before_btn off list_btn">인증번호 확인</button>	

                                                    </div>
                                                                                
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
                                
                                <div className="btn_box intro_btn">
                                    <Link className="dark_full_btn">등록</Link>
                                    <Link className="gray_line_btn register_phone_back">돌아가기</Link>
                                    
                                </div>					
                            
                            </div>				
                        </div>
                    </div>
                </div>
                <div className="dim"></div>

            </div>


            
        </>
    )
}

export default SiteAdmin