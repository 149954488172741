import React from 'react'
import logo_02 from '../../../assets/img/common/logo_02.png';
import $ from 'jquery';


function StoreHeaderM() {

    $('html').addClass('mo')

    return (
        <>
            <header id="header">
                <h1 className="logo"><img src={logo_02} alt="" /></h1>
            </header>
        </>
    )
}

export default StoreHeaderM
