import * as axios from 'axios';
import React, {useState} from 'react'
import {
    useNavigate
  } from "react-router-dom";

import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import {DoublePayChk} from "../../../include/ConfigSet";
import OpenLogComment from "../../../include/OpenLogComment";

axios.defaults.withCredentials = true;


function SiteAdminSettlementDataUploadHome() {

    const navigate = useNavigate();

    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    const [log_set_msg, set_log_set_msg] = useState('');
    const [pageReload, set_pageReload] = useState('');    
    React.useEffect(() => {              
        if(log_set === true){
            set_log_set(false);            
        }
    }, [log_set])

    // 로그인 아이디
    const [reg_id, set_reg_id] = useState("");


    // 가맹점 아이디
    const [loginId, set_loginId] = useState("");

    const on_loginId_handler = (event) => {
        set_loginId(event.currentTarget.value)
    }



    const [goods_name, set_goods_name] = useState("");

    const on_goods_name_handler = (event) => {
        set_goods_name(event.currentTarget.value)
    }


    const [customer_name, set_customer_name] = useState("");

    const on_customer_name_handler = (event) => {
        set_customer_name(event.currentTarget.value)
    }


    const [customer_hp, set_customer_hp] = useState("");

    const on_customer_hp_handler = (event) => {
        set_customer_hp(event.currentTarget.value)
    }


    const [customer_email, set_customer_email] = useState("");

    const on_customer_email_handler = (event) => {
        set_customer_email(event.currentTarget.value)
    }


    const [paytype, set_paytype] = useState("");

    const on_paytype_handler = (event) => {
        set_paytype(event.currentTarget.value)
    }


    const [price, set_price] = useState("");
    
    const on_price_handler = (event) => {
        set_price(event.currentTarget.value)
    }


    const [memo, set_memo] = useState("");

    const on_memo_handler = (evnet) => {
        set_memo(evnet.currentTarget.value)
    }


    const onSubmitHandler = (event) => {
        event.preventDefault();

    
        if( reg_id === "" ){
            alert("등록자 아이디가 누락되었습니다.");
            return false;
        }

        const today = new Date();
        const nYear = today.getFullYear();
        const nMonth = ('0' + (today.getMonth() + 1)).slice(-2); // 1월이 0부터 시작하므로 +1을 해줌
        const nDay = ('0' + today.getDate()).slice(-2);

        const dateYmd = `${nYear}${nMonth}${nDay}`;

        DoublePayChk(dateYmd, '', reg_id, price).then((result) => {

            if(result === 'E1'){
                alert("당일 한도 금액이 초과 되었습니다.");
                return;
            }
            if(result === 'E2'){
                alert("이번달 한도 금액이 초과 되었습니다.");
                return;
            }

            if(result === 'E3'){
                alert("금일 해당카드로 결제내역이 있습니다.");
                return;
            }
            
            let body = {
                reg_id: reg_id,
                goods_name: goods_name,
                customer_name: customer_name,
                customer_hp: customer_hp,
                customer_email: customer_email,
                paytype: paytype,
                price: price,
                memo: memo
            }
    
            axios.post('/api/store/management/goodsAdd', body).then(function(response){
                
    
                if(response.data === "AddOk"){
    
                    let body = {
                        reg_id: reg_id,
                        goods_name: goods_name,
                        customer_name: customer_name,
                        customer_hp: customer_hp,
                        price: price
                    }
            
    
                    // 마지막에 추가된 idx값 가져오기
                    axios.post('/api/store/management/goodsLast', body).then(function(res2){
                        
                        //alert(res2.data.idx)
    
                        if( paytype === "sms" ){
    
                            let body = {
                                receivers: customer_hp,
                                message: '[유월페이] 상품결제 링크 : https://uwalpay.net/Store/_management/payFormStep1?idx=' + res2.data.idx
                            }
                    
                            axios.post('/api/sms/send', body).then(function(res){
                                
                                //console.log('response : ', res.data);
                                alert("발송하였습니다.");      

                                set_log_set_msg('[홈페이지] 매출 거래생성 : SMS 발송');
                                set_log_set(true);
                                set_pageReload('/SiteAdmin/_pay/homepageList');
                                //navigate('/SiteAdmin/_pay/homepageList');

                    
                            }).catch((err) => {
                                //console.log('err : ', err);
                            })
    
                        }else if( paytype === "email" ){
    
                            if(customer_email === ''){
                                alert("이메일을 입력해주세요.");
                                return;
                            }
                    
                            const cert_num = Math.floor(Math.random() * 999999);
                    
                            let body = {
                                receivers: customer_email,
                                subject:'[유월페이] 상품결제 링크를 보내드립니다.',
                                content:'[유월페이] 상품결제 링크 : <a href="https://uwalpay.net/Store/_management/payFormStep1?idx=' + res2.data.idx + '" target="_blank">새창열기</a>'
                            }
                            
                            axios.post('/api/email/sendMail', body)
                            .then(function(res){
                                alert("발송하였습니다.");

                                set_log_set_msg('[홈페이지] 매출 거래생성 : Email 발송');
                                set_log_set(true);

                            }).catch((err) => {
                                //console.log('err : ', err);
                            })
    
    
                        }
    
                    });
                
                }else{
                    alert("시스템 에러");
                    
                }
                
                
                
            },[])

        });




    }

    /*
    판매자 정보 관련
    */

    const [sellerName, set_sellerName] = useState("");
    const [sellerHp, set_sellerHp] = useState("");
    const [sellerAddr, set_sellerAddr] = useState("");

    function getStoreInfo(){



        if( loginId === ""){
            alert("가맹점 아이디를 먼저 입력해주세요.");
            return false;
        }
        
        axios.post('/api/store/pay/getOrderNo').then(function(res){            
            if(res.data){
                
            }

            
    
            let bodyPage = {
                loginId: loginId

            }


            axios.post('/api/store/storeInfo', bodyPage).then(function(response){
                if( response.data.length > 0 ){
                    // PG사 정보 확인
                    
                    

                    let bodyPage2 = {
                        loginId: loginId
                    }
                    
                    // 등록자 ID
               
                    set_reg_id(response.data[0].user_id);
                    set_sellerName(response.data[0].branch_name);
                    set_sellerHp(response.data[0].user_phone);
                    set_sellerAddr(response.data[0].corp_addr);
                  

                    alert("확인되었습니다.");

                }else{
                    alert("존재하는 가맹점 아이디가 아닙니다.");
                    return false;

                }
                
            },[]);

        },)


    }


    return (
        <>

        {(log_set === true) && ( <OpenLogComment pageMode={`정산관리 > 매출데이터업로드 > ${log_set_msg}`} pageReload={pageReload} /> )} {/* 운영로그 기록 */}     
    
        <div id="wrap">
            <Header/>

            
    
            <div id="container" data-menu-page="data_upload">
                <h3 className="page_ttl">[홈페이지] 매출 거래생성</h3>

                <form onSubmit={onSubmitHandler}>
                    
                    <div className="form_item">
                        <h3 className="label">* 기본정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                    <th>가맹점  <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex" >
                                                <div className="in_box" >
                                                    <input type="text" name="loginId" id="" value={loginId} onChange={on_loginId_handler}  placeholder="가맹점 아이디" required />
                                                </div>

                                                <span style={{"marginLeft":"10px"}}>
                                                    <button type="button" className="btn_st_02 blue_line" onClick={getStoreInfo} >확인</button>
                                                </span>
                                            </div>
                                            
                                        </td>
                                        <th>판매자 정보</th>
                                        <td >
                                            <p>- 판매자명 : {sellerName}   /  휴대폰 : {sellerHp}</p>
                                            <p> - 주소 : {sellerAddr} </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>고객명</th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box">
                                                    <input type="text" maxLength="25" name="customer_name" id="customer_name" value={customer_name} onChange={on_customer_name_handler} required />
                                                </div>
                                            </div>
                                        </td>
                                        <th>결제방법</th>
                                        <td>
                                            <div className="flex">
                                                <div className="sel_box">
                                                <select name="paytype" id="paytype" className="wd100" onChange={on_paytype_handler} required>  
                                                    <option value="">선택</option>
                                                    <option value="sms">문자</option>
                                                    <option value="email">이메일</option>
                                                </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>휴대폰</th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box">
                                                    <input type="text" maxLength="20" name="customer_hp" id="customer_hp" value={customer_hp} onChange={on_customer_hp_handler} required />
                                                </div>
                                            </div>
                                        </td>
                                        <th>이메일</th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box">
                                                    <input type="text" maxLength="20" name="customer_email" id="customer_email" value={customer_email} onChange={on_customer_email_handler} required />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>상품명</th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="goods_name" id="goods_name" value={goods_name} onChange={on_goods_name_handler} required  />
                                            </div>
                                        </td>
                                        <th>판매가격</th>
                                        <td>
                                            <div className="in_box">
                                            <input type="text" className="yellow" name="price" id="price" value={price} onChange={on_price_handler} required />
                                            </div>
                                        </td>
                                        
                                    </tr>
                                    <tr>
                                        <th>상품정보</th>
                                        <td colSpan="3">
                                            <div className="in_box">
                                            <textarea name="memo" id="memo" value={memo} onChange={on_memo_handler} cols="30" rows="10"></textarea>
                                            </div>
                                        </td>
                                    </tr>




                                    
                                   
                                </tbody>
                            </table>

                        </div>

                    </div>


                    
                    

                    <div className="top_table_btn">
                       
                        <button type="button" className="btn_st_01" onClick={  () => navigate(-1) }>목록</button>
                        <button type="submit" className=" blue btn_st_01">등록</button>
                    </div>
                </form>


            </div>
            
            
        </div>

        <Footer/>
        </>
    )
}

export default SiteAdminSettlementDataUploadHome