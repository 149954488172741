import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import jquery from 'jquery';
import $ from 'jquery';


import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";




function StorePayTransactionHistoryListTerminal() {

    const navigate = useNavigate();

    const qs = queryString.parse(window.location.search);
    const user_id = qs.user_id ? qs.user_id : '';

    if(user_id === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }



   
   const [st_name, set_st_name] = useState("");
   const [calculate_date, set_calculate_date] = useState("");
   const [E_fee, set_E_fee] = useState("");
   const [EC_fee, set_EC_fee] = useState("");





    useEffect(() => {

        let bodyPage = {
            user_id: user_id
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/terminal/settlement/store_result_view', bodyPage).then(function(res){
            console.log(res.data.map);
            res.data.map((v, index) => {
                
                set_st_name(v.st_name);
                set_calculate_date(v.calculate_date);
                set_E_fee(v.E_fee);
                set_EC_fee(v.EC_fee);
            })
            
        }) 


        return () => {
          
        };
    }, []);



    return (
        <>
        <div id="wrap">
            <Header/>

            <div id="container" data-menu-page="set_result">
                <h3 className="page_ttl">가맹점 정산 설정</h3>

                <div className="top_table">
                    <table>
                        <colgroup>
                            <col width="150px" />
                            <col width="*" />
                            <col width="150px" />
                            <col width="*" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>가맹점명</th>
                                <td colSpan="3">{st_name}</td>
                            </tr>
                            <tr>
                                <th>거래일자</th>
                                <td colSpan="3">{calculate_date}</td>
                            </tr>
                            <tr>
                                <th>정산금액</th>
                                <td>{E_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                <th>입금금액</th>
                                <td>{EC_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            </tr>
                            <tr>
                                <th>구분</th>
                                <td>
                                    <div className="radio_wrap flex">
                                        <div className="radio_box">
                                            <input type="radio" name="store_op" id="minus" value="m" checked />
                                            <label htmlFor="minus">(-)차감</label>
                                        </div>
                                        <div className="radio_box">
                                            <input type="radio" name="store_op" id="plus" value="p" />
                                            <label htmlFor="plus">(+)가감</label>
                                        </div>
                                    </div>
                                </td>
                                <th>설정금액</th>
                                <td>
                                    <div className="flex">
                                        <div className="in_box mr_10">
                                            <input type="text" id="cha_price" />
                                        </div>
                                        <span> 원 (숫자만 입력)</span>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="top_table_btn">
                        <button className=" btn_st_01" onClick={  () => navigate(-1) } >취소</button>
                        <button className=" blue btn_st_01" onClick={ (e)=>chg_price(e, user_id)}>변경</button>
                    </div>
                </div>
            </div>
            
        </div>

        <Footer/>
        </>
    )
}

function chg_price(e, user_id){
    let cha_price = $("#cha_price").val();
    let pm = "";

    
    if(cha_price === ""){
        alert("금액을 입력해주세요.");
        $("#cha_price").focus();
        return false;
    }


    if( $("#minus").prop("checked") === true ){
        pm = "m";
        cha_price = cha_price * -1;
    }else{
        pm = "p";

    }


    let body = {
        cha_price: cha_price,
        pm: pm,
        user_id: user_id
    }

    axios.post('/api/management/settlement/store_result_mod', body)
    .then(function(response){
        
        if(response.data === "ModOk"){    
            //window.location.reload();
            alert("반영되었습니다.");
            window.history.go(-1);
        
        }else{
            alert("시스템 에러");   
        }
        
    },[])
}

export default StorePayTransactionHistoryListTerminal