import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import Header from "../../../include/StoreHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import PagingNew from "../../../include/PagingNew";
import queryString from 'query-string';
import jquery from 'jquery';
import $ from 'jquery';
import PropTypes from 'prop-types';




const StoreManageFaqList =()=>{

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('branch_name');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');
    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'branch_name';
    $("#searchCates").val(sCate);


    const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';
    

    const page = qs.pg? qs.pg : 1;

    const Fn_Del =(idx)=>{

        if( ! window.confirm("정말 삭제하시겠습니까?")){
            return false;
        }

        let body = {
            idx: idx
        }

        axios.post('/api/manage/FaqDel', body)
        .then(function(response){
            if(response.data === "DelOk"){    
                window.location.reload();
            }else{
                alert("시스템 에러");    
            }
        },[])

    }

    const Board = ({
        idx,
        nums,
        regdate,
        code,
        subject,
        user_id,
        contents
    }) => {      
        regdate = regdate.substr(0,10);

        return (
            <>
            <tr>
                <td className="number_td">{nums}</td>
                <td className="regdate_td">{regdate}</td>
                <td style={{"text-align": "left"}} ><Link to={`/Store/_manage/FaqView?idx=`+idx} >{subject}</Link></td>
                <td className="user_id_td">{user_id}</td>
            </tr>
            </>
        );
    };

    // 전체 수량 구하는 
    function getTotalCnt(){
        //console.log("getTotalCnt Start");
        let bodyQuery = {
            searchWord: sWord,
            searchCates: sCate
        }
        //console.log("bodyQuery : " + bodyQuery);

        axios.post('/api/manage/faq/total_list', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })

    }


    useEffect(() => {
        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
        
        getTotalCnt();
        

    },[sWord, sCate]);


    useEffect(()=>{

        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: sWord,
            searchCates: sCate
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/manage/faq/list', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            //console.log(res.data)
        }) 

        
    },[sWord, sCate, page]);

    const fn_submit = (e) => {
        $("#pg").val("1");
        //e.preventDefault();
    }

    return(
        <>
        <div id="wrap">
            <Header/>

            <div id="container" data-menu-page="notice">
                <h3 className="page_ttl">자주하는 질문</h3>

                <form name="" id="" action="./FaqList" method="get" onSubmit={ fn_submit } >
                    <input type="hidden" name="pg" id="pg" value={page} />
                        
                    <div className="top_filter">
                        <div className="sel_box">
                            <select name="searchCates" id="searchCates" required onChange={onSearchCates} value={searchCates} >
                                <option value="subject">제목</option>      
                            </select>
                        </div>
                        <div className="in_box search_box">
                            <input type="text" name="searchWord" id="searchWord" placeholder='검색해주세요'/>
                            <button type="submit" className="btn_st_02 blue search">검색</button>
                        </div>
                        <div className="btn_box">
                           
                        </div>
                    </div>
                </form>

                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건</div>
                    </div>
                    <div className="table_box">
                        <table>
                            <colgroup>
                                <col width="10%" />
                                <col width="15%" />
                                <col width="*" />
                                <col width="10%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>등록일자</th>
                                    <th>제목</th>
                                    <th>등록자</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewContent.map((v, index) => {

                                        return (
                                            <Board
                                                key={v.idx}
                                                idx={v.idx}
                                                nums={nums-index}
                                                regdate={v.regdate}
                                                code={v.code}
                                                subject={v.subject}
                                                contents={v.contents}
                                                user_id={v.user_id}
                                            />
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        viewContent.length > 0 ? <PagingNew curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    } 
                </div>
            </div>
        </div>

        <Footer/>
        </>
    )
}

export default StoreManageFaqList