import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import Header from "../../../include/MbranchHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import PagingNew from "../../../include/PagingNew";
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {PgCardName, PgName, GetPayStatus, Calculate_store, GetPayGubun, ChkDelayAble, ChgDelay, ChgDelayOff, SettlementDiChk, GetBankName} from "../../../include/ConfigSet_Terminal";
import {maskingFunc} from "../../../include/ConfigSet_masking";
import jquery from 'jquery';
import $ from 'jquery';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";


$(document).ready(function(){

    $("#num_all").click(function(){
        $(".chkBox").prop("checked",  $(this).prop("checked") );
    });

    
    $(".calcSel").click(function(){   

        if (window.confirm("정말 정산처리 하시겠습니까?")) {
                
            let chkCnt = 0;

            $(".chkBox").each(function(){
                if( $(this).prop("checked") == true ){
                    chkCnt++;
                }
            });
            
            if(chkCnt < 1){
                alert("하나 이상 선택해주세요.");
                return false;
            }

            $(".chkBox").each(function(){
                if( $(this).prop("checked") == true ){
                    //alert( $(this).val() )
                    SettlementDiChk( $(this).val() );
                }
            });

            alert("정산이 실행되었습니다.");
            window.location.reload();
        }

    });


    $(".calAll").click(function(){

        if (window.confirm("정말 정산처리 하시겠습니까?")) {
            
            let chkCnt = 0;

            $(".chkBox").each(function(){            
                //alert( $(this).val() )
                SettlementDiChk( $(this).val() );
            });

            alert("정산이 실행되었습니다.");
            window.location.reload();

        }

    });

    
});


function MbranchSettlementDiTerminal() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(15);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    
    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    
    
    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    $("#searchWord").val(sWord);

    const sCate = qs.searchCates ? qs.searchCates : 'authno';
    $("#searchCates").val(sCate);

    const pg_group = qs.pg_group ? qs.pg_group : '';
    $("#pg_group").val(pg_group);

    const paymenttype = qs.paymenttype ? qs.paymenttype : '';
    $("#paymenttype").val(paymenttype);

    const ordertype = qs.ordertype ? qs.ordertype : '';
    $("#ordertype").val(ordertype);

    const chkdate = qs.chkdate ? qs.chkdate : 'authdate';
    $("#chkdate").val(chkdate);

    const sdate = qs.sdate ? qs.sdate : '';
    $("#sdate").val(sdate);

    const edate = qs.edate ? qs.edate : '';
    $("#edate").val(edate);

    const q_parent_idx1 = qs.parent_idx1 ? qs.parent_idx1 : '';
    const q_parent_idx2 = qs.parent_idx2 ? qs.parent_idx2 : '';
    
    const status = qs.status ? qs.status : '';
    $("#status").val(status);


    const viewType = qs.viewType ? qs.viewType : 'store';
    $("#viewType").val(viewType);


    const dateType = qs.dateType ? qs.dateType : 'during';
    $("#dateType").val(dateType);
    
    
    
    const page = qs.pg? qs.pg : 1;

    let corp_class_txt = '';
    let contract_status_txt = '';
    let login_able_txt = '';
    let calculate_txt = '';
    

   


    /**
     * 
     * @param {{nums:number,regdate:string}} nums 게시판 키값
     * @param {*} regdate 등록일자
     * @param {*} branch_name 지사명
     * @param {string} representative 대표자명
     * @param {string} user_id ID
     * @param {string} corp_class 구분
     * @param {string} calculate 정산Type
     * @param {string} commission 제공수수료
     * @param {string} contract_status 상태
     * @param {string} login_able 로그인 허용여부
     * @returns 
     */

    // 정상매출 - 건수 합계
    let total_E_cnt =  0;

    // 정상매출 - 금액 합계
    let total_E_amount = 0;


    // 정상매출 수수료 합계
    let total_E_income =  0;

    // 정상매출 정산금액 합계
    let total_E_fee = 0;

    // 차감매출 - 건수 합계
    let total_EC_cnt =  0;

    // 차감매출 - 금액 합계
    let total_EC_amount = 0;

    // 차감매출 수수료	 - 합계
    let total_EC_income = 0;

    // 차감매출 차감금액	 - 합계
    let total_EC_fee = 0;




    const Board = ({
        idx,
        nums,
        regid,
        st_name,
        Total_amount,
        E_cnt,
        E_amount,
        E_income,
        E_fee,
        EC_cnt,
        EC_amount,
        EC_income,
        EC_fee,
        bank,
        account_num,
        account_holder
    }) => {

        
        return (
            <>
            <tr>
                <td className="chk_td">
                    <div className="check_box">
                        <label htmlFor={"num_"+nums}><span></span>{nums}</label>
                    </div>
                </td>
                <td>{st_name}</td>
                <td>정상</td>
                <td>{(E_income-EC_income).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{(E_income-EC_income).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="blue_txt">{E_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="blue_txt">{E_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="blue_txt">{E_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="red_txt">{EC_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="red_txt">{EC_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="red_txt">{EC_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td>{GetBankName(bank)}</td>
                <td className="maskingFunc_td">{maskingFunc.account(account_num)}</td>
                <td className="maskingFunc_td">{maskingFunc.name(account_holder)}</td>
            </tr>
            </>
        );






    };




    // 전체 수량 구하는 
    function getTotalCnt(){

        const loginId = sessionStorage.getItem("loginId");
        
        if(loginId === null){
            navigate('/SiteAdmin/');
            return;
        }

        let bodyPage = {
            loginId: loginId
        }

      
        
        let bodyQuery = {
            loginId: loginId
        }

        axios.post('/api/terminal/mbranch/settlement/di_total_list', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })


    }

    
    useEffect(() => {
        getTotalCnt();
    },[]);


  
    useEffect(()=>{

        const loginId = sessionStorage.getItem("loginId");

 
        
        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            loginId:loginId
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/terminal/mbranch/settlement/Di_list', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            //console.log(res.data)
        }) 

          

        
    },[sWord, sCate, page]);
    // nums
    
   

    const fn_submit = (e) => {
        $("#pg").val("1");
        //e.preventDefault();
    }

    

    


    return (
        <>
        <div id="wrap">
            <Header/>

            
            <div id="container" data-menu-page="set_store">
                <h3 className="page_ttl"> 총판 정산</h3>


                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}건</div>
                    
                    </div>
                    <div className="table_box">

                        <table>

                            <thead>
                                {/* <tr className="total">
                                    <th colSpan="21">
                                    </th>
                                </tr> */}

                                <tr>
                                    <th rowSpan="2">
                                        <div className="check_box">
                                            <label htmlFor="num_all"><span></span> NO</label>
                                        </div>
                                    </th>
                                    <th rowSpan="2">총판명</th>
                                    <th rowSpan="2">결과</th>
                                    <th rowSpan="2">수수료정산</th>
                                    <th rowSpan="2">입금금액</th>

                                    <th colSpan="3" className="blue_txt th_top left_line">정상매출</th>
                                    <th colSpan="3" className="red_txt th_top left_line right_line">차감매출</th>

                                    <th rowSpan="2">입금은행</th>
                                    <th rowSpan="2">계좌번호</th>
                                    <th rowSpan="2">예금주명</th>
                                </tr>
                                <tr>
                                    <th className="blue_bg left_line">건수</th>
                                    <th className="blue_bg">금액</th>
                                    <th className="blue_bg">수수료</th>

                                    <th className="red_bg left_line">건수</th>
                                    <th className="red_bg">금액</th>
                                    <th className="red_bg right_line">수수료</th>
                                </tr>

                            </thead>

                            <tbody>

                            {
                                viewContent.map((v, index) => {

                                    // 정상매출 - 건수 합계
                                    total_E_cnt = parseInt(total_E_cnt) + parseInt(v.E_cnt);

                                    // 정상매출 - 금액 합계
                                    total_E_amount = parseInt(total_E_amount) + parseInt(v.E_amount);

                                    // 정상매출 수수료 합계
                                    total_E_income = parseInt(total_E_income) + parseInt(v.E_income);

                                    // 정상매출 정산금액 합계
                                    total_E_fee = parseInt(total_E_fee) + parseInt(v.E_fee);


                                    // 차감매출	 - 건수 합계
                                    total_EC_cnt = parseInt(total_EC_cnt) + parseInt(v.EC_cnt);

                                    // 차감매출	 - 금액 합계
                                    total_EC_amount = parseInt(total_EC_amount) + parseInt(v.EC_amount);

                                    // 차감매출 수수료	 - 합계
                                    total_EC_income = parseInt(total_EC_income) + parseInt(v.EC_income);

                                    // 차감매출 차감금액	 - 합계
                                    total_EC_fee = parseInt(total_EC_fee) + parseInt(v.EC_fee);

                                    

                                    return (
                                        <Board
                                            key={v.idx}
                                            idx={v.idx}
                                            regid={v.regid}
                                            nums={nums-index}
                                            st_name={v.st_name}
                                            Total_amount={v.Total_amount}
                                            E_cnt={v.E_cnt}
                                            E_amount={v.E_amount}
                                            E_income={v.E_income}
                                            E_fee={v.E_fee}
                                            EC_cnt={v.EC_cnt}
                                            EC_amount={v.EC_amount}
                                            EC_income={v.EC_income}
                                            EC_fee={v.EC_fee}
                                            bank={v.bank}
                                            account_num={v.account_num}
                                            account_holder={v.account_holder}
                                        />
                                    );
                                })   
                            }
                              
                                
                                <tr className="total">
                                    <td colSpan="3">TOTAL</td>
                                    <td>{(total_E_income-total_EC_income).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{(total_E_income-total_EC_income).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_E_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_E_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_E_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_EC_cnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_EC_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td>{total_EC_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                    <td colSpan="3"></td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    {
                        viewContent.length > 0 ? <PagingNew curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    }
                </div>
            </div>

            
            
        </div>

        <Footer/>
        </>
    )

    
}
export default MbranchSettlementDiTerminal