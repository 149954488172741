import * as axios from 'axios';
import React, {useEffect, useRef, useState} from 'react'
import { useNavigate } from 'react-router-dom';

import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";

function SiteAdminManagementAgencyForm() {

    const navigate = useNavigate();

    const ref_corp_addr2 = useRef(null);
    const [corp_class, set_corp_class] = useState("");    
    const [login_able, set_login_able] = useState("");
    const [bank, set_bank] = useState("");
    const [bankList, set_bankList] = useState([]);
    const [branch_name, set_branch_name] = useState("");
    const [user_id, set_user_id] = useState("");
    const [user_pw, set_user_pw] = useState("");
    const [user_email, set_user_email] = useState("");
    const [representative, set_representative] = useState("");
    const [parent_idx1, set_parent_idx1] = useState("");
    const [parent_idx2, set_parent_idx2] = useState("");
    const [manager_phone, set_manager_phone] = useState("");
    const [corp_name, set_corp_name] = useState("");
    const [corp_nun, set_corp_nun] = useState("");
    const [corp_post, set_corp_post] = useState(""); 
    const [corp_addr, set_corp_addr] = useState("");
    const [corp_addr2, set_corp_addr2] = useState("");
    const [corp_status, set_corp_status] = useState("");
    const [corp_sector, set_corp_sector] = useState("");
    const [commission, set_commission] = useState("");
    const [account_num, set_account_num] = useState("");
    const [account_holder, set_account_holder] = useState("");
    const [id_check, set_id_check] = useState("");
    const [memo, set_memo] = useState("");
    const [managerList1, setManagerList1] = useState([]);
    const [managerList2, setManagerList2] = useState([]);
    const [contract_status, set_contract_status] = useState("");
    const [site_logo_file, set_site_logo_file] = useState({ site_logo_file_r: "", site_logo_file: "" });    
    const [up_site_logo_file, set_up_site_logo_file] = useState(null);

    

    useEffect(() => {
        // 계약상태 기본값 대입
        set_contract_status('Y');
        
        // 로그인
        set_login_able('Y');
        return () => {
          
        };
    }, []);
  

    const on_contract_status = (event) => {
        set_contract_status(event.currentTarget.value)
    }
    const on_corp_class = (event) => {
        set_corp_class(event.currentTarget.value)
    }
    const on_login_able = (event) => {
        set_login_able(event.currentTarget.value)
    }
    const on_bank = (event) => {
        set_bank(event.currentTarget.value)
    }
    const on_branch_name_handler = (event) => {
        set_branch_name(event.currentTarget.value)
    }
    const on_user_id_handler = (event) => {
        set_user_id(event.currentTarget.value)
        set_id_check('');
    }
    const on_user_pw_handler = (event) => {
        set_user_pw(event.currentTarget.value)
    }
    const on_user_email_handler = (event) => {
        set_user_email(event.currentTarget.value)
    }
    const on_representative_handler = (event) => {
        set_representative(event.currentTarget.value)
    }
    const on_manager_phone_handler = (event) => {
        set_manager_phone(event.currentTarget.value)
    }
    const on_corp_name_handler = (event) => {
        set_corp_name(event.currentTarget.value)
    }

    const on_corp_nun_handler = (event) => {
        set_corp_nun(event.currentTarget.value)
    }

    const on_corp_post_handler = (event) => {
        set_corp_post(event.currentTarget.value)
    }

    const on_corp_addr_handler = (event) => {
        set_corp_addr(event.currentTarget.value)
    }

    const on_corp_addr2_handler = (event) => {
        set_corp_addr2(event.currentTarget.value)
    }
    const on_corp_status_handler = (event) => {
        set_corp_status(event.currentTarget.value)
    }

    const on_corp_sector_handler = (event) => {
        set_corp_sector(event.currentTarget.value)
    }

    const on_commission_handler = (event) => {
        set_commission(event.currentTarget.value)
    }

    const on_account_num_handler = (event) => {
        set_account_num(event.currentTarget.value)
    }

    const on_account_holder_handler = (event) => {
        set_account_holder(event.currentTarget.value)
    }

    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
        getManagerList2(event.currentTarget.value);
    }
    const on_set_parent_idx2_handler = (event) => {
        set_parent_idx2(event.currentTarget.value)
    }
    const on_memo_handler = (event) => {
        set_memo(event.currentTarget.value)
    }
    const on_up_site_logo_file_handler = (event) => {
        set_up_site_logo_file(event.target.files[0]);        
    }    

    const on_id_check = (event) => {
        if(user_id === "")
        {
            alert("아이디를 입력해주세요.");
            return false;
        }
        let body = {
            user_id: user_id,
        }

        axios.post('/api/user/login_chk', body)
        .then(function(response){
            if(response.data === "NO"){
                
                alert("이미 존재하는 아이디입니다.");
                set_id_check('');
                return false;

            }else{
                alert("사용 가능한 아이디입니다.");
                set_id_check('Y');
                return false;
                
            }
            
        },[])
        //set_id_check('Y');

    }
    const onSubmitHandler = (event) => {
        event.preventDefault();

        if( id_check === "" ){
            alert("아이디 중복검사를 해주세요.");
            return false;
        }
        

        let body = {
            parent_idx: parent_idx1,
            parent_idx2: parent_idx2,
            user_id: user_id,
            user_pw: user_pw,
            user_email: user_email,
            branch_name: branch_name,
            manager_phone: manager_phone,
            login_able: login_able,
            commission: commission,
            corp_class: corp_class,
            representative: representative,
            corp_name: corp_name,
            corp_nun: corp_nun,
            corp_post: corp_post,
            corp_addr: corp_addr,
            corp_addr2: corp_addr2,
            corp_status: corp_status,
            corp_sector: corp_sector,
            bank: bank,
            account_num: account_num,
            account_holder: account_holder,
            contract_status: contract_status,
            memo: memo
        }

        axios.post('/api/management/AgencyAdd', body)
        .then(function(response){
            

            
            if(response.data === "AddOk"){    
                navigate('/SiteAdmin/_management/AgencyList');
            

                //파일 업로드
                fileUpload(event, user_id);

            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])


    }

    function getManagerList1(){
        
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/management/manager_list',bodyPage).then(function(res){            
            setManagerList1(res.data);
            
        })
    }

    function getManagerList2(temp_idx){

        
        let bodyPage = {
            user_level: 3,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/distributor_sel_list',bodyPage).then(function(res){            
            setManagerList2(res.data);
            
        })
    
    }

    function rendManagerList(){
        return (
            <select name="parent_idx1" id="parent_idx1" required onChange={on_set_parent_idx1_handler}>
                <option value=''>==지사선택==</option>
            {
                managerList1.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    function rendManagerList2(){
        return (
            <select name="parent_idx2" id="parent_idx2" required onChange={on_set_parent_idx2_handler}>
                <option value=''>==선택==</option>
            {
                managerList2.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    // 상위지사 내역 가져오기
    useEffect(() => {
        getManagerList1();
        getBankList();
    },[])


    // 총판 내역 가져오기
    useEffect(() => {
        //getManagerList2();
    },[managerList1])


    function getBankList(){
        axios.post('/api/management/bank_list').then(function(res){         
            
            set_bankList(res.data);
            
        })

    }

    /********************
     * 파일업로드
     * @param {*} e 
     * @param {*} user_id 
     ********************/
    const fileUpload = async(e, user_id) => {
        e.preventDefault();            
        const formData = new FormData();
        formData.append('user_id', user_id);        
        formData.append('site_logo_file', up_site_logo_file);        
        axios.post('/api/fileUpload/storeFileUpload', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            }).then(function(res){                                
                console.log('res : ', res);
            },[]
        );            
    };

    /******************************
     * 다음 우편번호 서비스 호출 함수
     ******************************/
    const handlePostcode = () => {

        new window.daum.Postcode({
            oncomplete: (data) => {
                set_corp_post(data.zonecode); // 우편번호
                set_corp_addr(data.address); // 전체 주소  
                ref_corp_addr2.current.focus();  
            },
            popupName: "postcodePopup", // 팝업 이름 설정
          }).open({
            // left: (window.innerWidth / 2) - 200, // 중앙에 위치
            // top: (window.innerHeight / 2) - 300,
            // width: 100,
            // height: 600,
          });        
    };

    return (
        <>
        <div id="wrap">
            <Header/>

        
            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>
                    <input type="hidden" name="id_check" id="id_check" value={id_check} />

                    <h3 className="page_ttl">대리점등록</h3>

                    <div className="form_item">
                        <h3 className="label">* 기본정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>상위총판 <span className="red">＊</span></th>
                                        <td>
                                            <div className="sel_box flex">
                                                {
                                                    rendManagerList()
                                                }
                                                {
                                                    rendManagerList2()
                                                }
                                                
                                            </div>
                                        </td>
                                        <th>계약상태   <span className="red">＊</span></th>
                                        <td>
                                            <div className="sel_box">
                                                <select name="contract_status" id="contract_status" value="Y" required onChange={on_contract_status}>
                                                    <option value="Y" >정상</option>
                                                    <option value="F">해지</option>
                                                    <option value="A">신청</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>


                                    <tr>
                                        <th>대리점명 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="branch_name" id="branch_name" value={branch_name} onChange={on_branch_name_handler} required />
                                            </div>
                                        </td>

                                        <th>담당자 연락처</th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="manager_phone" id="manager_phone" value={manager_phone} onChange={on_manager_phone_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>이메일</th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" id="user_email" value={user_email} onChange={on_user_email_handler} />
                                            </div>
                                        </td>
                                        <th></th>
                                        <td></td>
                                    </tr>                                
                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="form_item">
                        <h3 className="label">* 로그인 정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>

                                    <tr>
                                        <th>로그인ID <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box mr_10">
                                                    <input type="text" name="user_id" id="user_id" value={user_id} onChange={on_user_id_handler} required />
                                                </div>
                                                <button className="btn_st_02 blue_line" type="button" onClick={on_id_check} >중복검사</button>
                                            </div>
                                        </td>
                                        <th>비밀번호 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="password" name="user_pw" id="user_pw" value={user_pw} onChange={on_user_pw_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>수수료  <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box mr_10" style={{width: '100px'}}>
                                                    <input type="text" name="commission" id="commission" value={commission} onChange={on_commission_handler} required />
                                                </div>
                                                %
                                                <span className="red" style={{margin:'0 10px'}}>(VAT 포함가) </span>
                                                <span>수수료 = 가맹점 수수료 - 제공 수수료</span>
                                            </div>
                                        
                                        </td>
                                        <th>로그인  <span className="red">＊</span></th>
                                        <td>
                                            <div className="sel_box">
                                                <select name="login_able" id="login_able" required onChange={on_login_able}>
                                                    <option value="Y">허용</option>
                                                    <option value="N">불가</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                    <div className="form_item">
                        <h3 className="label">* 사업자 정보 ( 선택 사항 )</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>사업자 구분</th>
                                        <td>
                                            <div className="sel_box">
                                                <select name="corp_class" id="corp_class" required onChange={on_corp_class}>
                                                    <option value="">선택</option>
                                                    <option value="corp">법인사업자</option>
                                                    <option value="solo">개인사업자</option>
                                                    <option value="non">비사업자</option>
                                                </select>
                                            </div>
                                        </td>
                                        <th>대표자  </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="representative" id="representative" value={representative} onChange={on_representative_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>사업자명 </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_name" id="corp_name" value={corp_name} onChange={on_corp_name_handler} required />
                                            </div>
                                        </td>
                                        <th>사업자등록번호 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_nun" id="corp_nun" value={corp_nun} onChange={on_corp_nun_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>업태 </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_status" id="corp_status" value={corp_status} onChange={on_corp_status_handler}  required />
                                            </div>
                                        </td>
                                        <th>종목 </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_sector" id="corp_sector" value={corp_sector} onChange={on_corp_sector_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>사업자주소</th>
                                        <td colSpan="3">
                                            <div className="in_box">   
                                                <div style={{marginBottom: '5px', display: 'flex'}}>
                                                    <input type="text" name="post" id="corp_post" value={corp_post} style={{width:'200px'}} onChange={on_corp_post_handler} readOnly={true}  />
                                                    <button type="button" className="btn_st_01" style={{margin:'8px 0 0 8px'}} onClick={handlePostcode}>우편번호검색</button>                                              
                                                </div>
                                                <div style={{marginBottom: '5px'}}><input type="text" id="corp_addr" value={corp_addr} onChange={on_corp_addr_handler} readOnly={true} /></div>
                                                <div style={{marginBottom: '5px'}}><input type="text" id="corp_addr2" value={corp_addr2} ref={ref_corp_addr2} onChange={on_corp_addr2_handler}  /></div>                                            
                                            </div>                                                                                                         
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="form_item">
                        <h3 className="label">*  정산계좌정보 </h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>은행선택  </th>
                                        <td colSpan="3">
                                            <div className="sel_box table_cont_1">
                                                <select name="bank" id="bank" required onChange={on_bank}>
                                                    <option value="">선택</option>
                                                    {
                                                        bankList.map((v, index)=> {
                                                            return (
                                                                <option value={v.bank_code} key={index}>{v.bank_name}</option>
                                                            )

                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                
                                    <tr>
                                        <th>계좌번호  </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="account_num" id="account_num" value={account_num} onChange={on_account_num_handler} required />
                                            </div>
                                        </td>
                                        <th>예금주 </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="account_holder" id="account_holder" value={account_holder} onChange={on_account_holder_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>기타  </th>
                                        <td colSpan="3">
                                            <div className="text_area">
                                                <textarea name="memo" id="memo" value={memo} onChange={on_memo_handler} ></textarea>
                                            </div>
                                        </td>
                                    </tr>


                    

                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="form_item">
                        <h3 className="label">*  로고 설정 </h3>
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>                                       
                                        <th>관리자 로고</th>
                                        <td colSpan={3}>
                                            <div className="in_box">
                                                <input type="file"  id="site_logo_File" onChange={on_up_site_logo_file_handler} />                                                                                       
                                                {site_logo_file.site_logo_file == '' ? '' : <img src={'/uploads/'+ site_logo_file.site_logo_file} />}
                                            </div>
                                        </td>
                                    </tr>                                                            
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="top_table_btn">
                        <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>
                        <button className=" blue btn_st_01">등록</button>
                    </div>

                </form>

            </div>
            
        </div>

        <Footer/>
        </>
    )
}

export default SiteAdminManagementAgencyForm