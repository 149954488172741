import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';

import Paging from "../../views/include/Paging";



function HolidayList() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    // 페이징 현재 페이지
    const [page, setPage] = useState(1);

    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('branch_name');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');


    const [h_date, set_h_date] = useState("");
    const [h_text, set_h_text] = useState("");


    const on_h_date_handler = (event) => {
        set_h_date(event.currentTarget.value);
    }

    const on_h_text_handler = (event) => {
        set_h_text(event.currentTarget.value);
    }


    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });


    // 상위 지사리스트
    const [managerList, setManagerList] = useState([]);

    // 지사 idx 처리
    const [parent_idx, set_parent_idx] = useState('');

    const on_set_parent_idx_handler = (event) => {
        set_parent_idx(event.currentTarget.value)
    }
    


    const qs = queryString.parse(window.location.search);
    const sWord = qs.searchWord ? qs.searchWord : '';
    const sCate = qs.searchCates ? qs.searchCates : '';
    const sParent_idx = qs.parent_idx ? qs.parent_idx : '';
    const sPg = qs.pg? qs.pg : 1;



     const Board = ({
        idx,
        nums,
        h_date,
        h_text
    }) => {
        

        return (
            <tr>
                <td>{h_date}</td>
                <td className="left">{h_text}</td>
                <td>
                    <div className="btn_wrap"> 
                        <button type="button" code={idx} onClick={on_dels} className="btn_st_02 red">삭제</button>
                    </div>  
                </td>
            </tr>
        );
    };




    // 전체 수량 구하는 
    function getTotalCnt(){
        //console.log("getTotalCnt Start");
        let bodyQuery = {
            searchWord: searchWord,
            searchCates: searchCates,
            parent_idx: parent_idx
        }
        //console.log("bodyQuery : " + bodyQuery);

        axios.post('/api/management/holi_total_list', bodyQuery).then(function(res){            
            setTotalCnt(res.data.tcnt);
            
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })

    }


    // 은행 삭제
    function on_dels(event){
        const idxs = event.target.getAttribute('idx');


        if( ! window.confirm("정말 삭제하시겠습니까?")){
            return false;

        }

        
        let body = {
            idxs: idxs
        }

        axios.post('/api/manage/HoliDel', body)
        .then(function(response){
            

            
            if(response.data === "DelOk"){    
                //pop_close();
                window.location.reload();
            
            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])
    }


    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            h_date: h_date,
            h_text: h_text
        }

        axios.post('/api/manage/HoliAdd', body)
        .then(function(response){
            

            
            if(response.data === "AddOk"){    
                //pop_close();
                window.location.reload();
            
            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])


    }
    

    useEffect(() => {
        //console.log("useEffect : total_cnt");
        //console.log("sWord : " +sWord);
        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
    
        if(sPg) {
            setPage(sPg);
        }

        if(sParent_idx) {
            set_parent_idx(sParent_idx);
        }

        getTotalCnt();
        

        

    },[sWord, sCate, sPg, sParent_idx, page]);
    /*
    pg, sWord
    */

  
    useEffect(()=>{
        /*
        console.log("num 재확인 시작");
        console.log("nums : " + nums);
        console.log("num 재확인 끝");
        */

        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows,
            searchWord: searchWord,
            searchCates: searchCates,
            parent_idx: parent_idx
        }
        console.log("bodyPage : " + bodyPage);

        axios.post('/api/management/holi_list', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            console.log(res.data)
        }) 

        
    },[sWord, sCate, sParent_idx, sPg]);
    // nums
    


    return (
        <>    
        <div id="container" data-menu-page="holiday">
            <h3 className="page_ttl">휴일관리</h3>
            <form onSubmit={onSubmitHandler}>
            <div className="top_filter">

                <div className="in_box">
                    <input type="text" placeholder="YYYYMMDD" name="h_date" id="h_date" required value={h_date} onChange={on_h_date_handler} />
                </div>

                <div className="in_box">
                    <input type="text" placeholder="휴일내용" name="h_text" id="h_text" required value={h_text} onChange={on_h_text_handler} />
                </div>


                <div className="btn_box">
                    <button type="submit" className="btn_st_02 blue search">신규등록</button>
                </div>



            </div>
            </form>


            <div className="bot_item">
                <div className="table_box">
                    <table>
                        <colgroup>
                            <col width="10%" />
                            <col width="*" />
                            <col width="10%" />
                        </colgroup>
                        <thead>
                        
                            <tr>
                                <th>일자</th>
                                <th>내용</th>
                                <th>-</th>
                            </tr>

                        </thead>

                        <tbody>
                            {
                                viewContent.map((v, index) => {

                                    return (
                                        <Board
                                            key={v.idx}
                                            idx={v.idx}
                                            nums={nums-index}
                                            h_date={v.h_date}
                                            h_text={v.h_text}
                                        />
                                    );
                                })
                            }
                        </tbody>

                    </table>
                </div>

                {
                    viewContent.length > 0 ? <Paging curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                } 

            </div>

        </div>
        
        </>
    )


}
export default HolidayList