import React, {useState} from 'react'
import * as axios from 'axios';
import {useNavigate} from "react-router-dom";

import OpenLogComment from "../../views/include/OpenLogComment";

function SettingForm({user_level, user_site}) {
    
    const navigate = useNavigate();
    
    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    React.useEffect(() => {              
        if(log_set === true){
            window.location.reload();
        }
    }, [log_set])

    
    const [user_info, set_user_info] = useState({
        user_id:'',
        user_name:'',
        user_pw:'',
        user_pw2:'',
        last_login:null,

    });  
    

    const [site_logo_file, set_site_logo_file] = useState({ site_logo_file_r: "", site_logo_file: "" });
    const [up_site_logo_file, set_up_site_logo_file] = useState(null);

    const on_pw = (event) => {               
        set_user_info({...user_info, user_pw:event.currentTarget.value})
    } 
    const on_pw2 = (event) => {               
        set_user_info({...user_info, user_pw2:event.currentTarget.value})
    } 
    
    const on_up_site_logo_file_handler = (event) => {
        set_up_site_logo_file(event.target.files[0]);        
    }    
    
    const onSubmitHandler = (event) => {

        event.preventDefault();

        if(user_info.user_pw !== user_info.user_pw2){
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        let body = {
            user_id:sessionStorage.getItem('loginId'),
            user_pw:user_info.user_pw,            
        }

        axios.post('/api/admin/SettingAdd', body)
        .then(function(response){
            if(response.data === "AddOk"){
                alert("정상적으로 등록되었습니다.");

                //파일 업로드
                fileUpload(event, body.user_id);

                set_log_set(true);   

            }else{
                alert("시스템 에러");                
            }
        },[])
    }
    
    const Fn_User_Info=()=>{

        let bodyPage = {
            loginId: sessionStorage.getItem('loginId')
        }
        
        axios.post('/api/manage/userInfo', bodyPage).then(function(res){                              
            res.data.map((v, index) => {                
                set_user_info({...user_info
                    , user_id:v.user_id
                    , user_name:v.user_name
                    , last_login:v.last_login});                                    
                set_site_logo_file({
                    site_logo_file_r: (v.site_logo_file_r == null ? '' : v.site_logo_file_r),
                    site_logo_file: (v.site_logo_file == null ? '' : v.site_logo_file),
                });   
                                        
                return ''
            });                       
        })         
    }
    
    React.useEffect(() => {              
        Fn_User_Info();      
    }, [])

     
    // 로그인 날짜
    function converToLocalTime(serverDate) {
        var date = new Date(Date.parse(serverDate));
        const TIME_ZONE = 3240 * 10000;
        return new Date(+date + TIME_ZONE).toISOString().replace('T', ' ').replace(/\..*/, '').substring(0, 16);
    }

    /********************
     * 파일업로드
     * @param {*} e 
     * @param {*} user_id 
     ********************/
    const fileUpload = async(e, user_id) => {
        e.preventDefault();            
        const formData = new FormData();
        formData.append('user_id', user_id);        
        formData.append('site_logo_file', up_site_logo_file);        
        axios.post('/api/fileUpload/storeFileUpload', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            }).then(function(res){                                
                console.log('res : ', res);
            },[]
        );            
    };

    return (
        <>
            {/* 운영로그 기록 */}
            {(log_set === true) && ( <OpenLogComment pageMode={'정보변경 > 정보수정'} /> )}
            
            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>
                                     
                    <h3 className="page_ttl">정보변경</h3>

                    <div className="form_item">
                        
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>ID   </th>
                                        <td>{user_info.user_id}</td>
                                        <th>관리자명</th>
                                        <td>{user_info.user_name} </td>
                                    </tr>
                                    <tr>
                                        <th>신규 비밀번호 입력</th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box mr_10" >
                                                    <input type="password" name="user_pw" id="user_pw" maxLength={15} onChange={on_pw} required />
                                                        
                                                </div>
                                                <span>(15자 이내)</span>
                                            </div>
                                        </td>
                                        <th>신규 비밀번호 재입력</th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box mr_10">
                                                    <input type="password" name="user_pw2" id="user_pw2" maxLength={15} onChange={on_pw2} required />
                                                </div>
                                                <span>(15자 이내)</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Last update </th>
                                        <td colSpan={3}>{(user_info.last_login !== null) && (converToLocalTime(user_info.last_login)) }</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                    <div className="form_item">
                        <h3 className="label">*  로고 설정 </h3>
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>                                       
                                        <th>관리자 로고</th>
                                        <td colSpan={3}>
                                            <div className="in_box">
                                                <input type="file"  id="site_logo_File" onChange={on_up_site_logo_file_handler} />                                                                                       
                                                {site_logo_file.site_logo_file == '' ? '' : <img src={'/uploads/'+ site_logo_file.site_logo_file} />}
                                            </div>
                                        </td>
                                    </tr>                                                            
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="top_table_btn">
                        <button className=" blue btn_st_01">등록</button>                       
                    </div>

                </form>

            </div>                   
        </>
    )
}

export default SettingForm