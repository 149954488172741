import React, {useEffect, useState, useRef, useReducer, useCallback } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import Paging from "../../../include/Paging";
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
//react-datepicker
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";


function SiteAdminManageOperateLog() {
    
    const navigate = useNavigate();
    const location = useLocation();
    
    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);

    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(10);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    // 페이징 현재 페이지
    const [page, setPage] = useState(1);
    
    // 검색 카테고리
    const [searchCates, setSearchCates] = useState('sUser_id');
    //console.log(searchCates);

    // 검색 키워드
    const [searchWord, setSearchWord] = useState('');

    // PG idx 처리
    const [parent_idx, set_parent_idx] = useState('');

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    // 카테고리 변경시
    const onSearchCates = (event) => {
        setSearchCates(event.currentTarget.value);
    }

    // 키워드 입력시
    const onSearchWord = ((event)=>{
        setSearchWord(event.currentTarget.value);
    });

    const qs = queryString.parse(window.location.search);
    
    const sWord = qs.searchWord ? qs.searchWord : '';
    const sCate = qs.searchCates ? qs.searchCates : '';
    const sParent_idx = qs.parent_idx ? qs.parent_idx : '';
    const sPg = qs.pg? qs.pg : 1;

    function onSubmitSearch(event){
        event.preventDefault();        
        setUrl('searchWord='+searchWord+'&searchCates='+searchCates+'&startDate='+startDate+'&endDate='+endDate+'&parent_idx='+parent_idx);                
        navigate('/SiteAdmin/_manage/OperateLog?searchWord='+searchWord+'&searchCates='+searchCates+'&startDate='+startDate+'&endDate='+endDate+'&parent_idx='+parent_idx);        
    }
    
    // 전체 수량 구하는 
    function getTotalCnt(){
        let bodyQuery = {
            searchWord: searchWord,
            searchCates: searchCates,
            startDate: startDate,
            endDate: endDate          
        }
        axios.post('/api/manage/OperateLogTotalCount', bodyQuery).then(function(res){    
            setTotalCnt(res.data.tcnt);            
            setNums( (res.data.tcnt) -  ( (sPg - 1) * gListRows ) );
        })
    }
    
    const Board = ({
        idx,
        nums,
        sUser_id,
        sIp,
        sMemo,
        sUrl,
        dUpdate,
        user_level,
        user_name,
        branch_name
    }) => {
        dUpdate = dUpdate.substr(0,16);
        return (

            <tr>
                <td className="number_td">{nums}</td>
                <td>{user_level}</td>
                <td>{sUser_id}</td>
                <td>{user_name}{branch_name}</td>
                <td>{sIp}</td>
                <td>{sMemo}</td>
                <td><div style={{maxWidth:'500px', overflowX:'auto'}}>{sUrl}</div></td>
                <td>{dUpdate}</td>
            </tr>
            
        );
    };

    useEffect(() => {

        if (sWord) {
            setSearchWord(sWord);
        }

        if(sCate){
            setSearchCates(sCate);
        }
    
        if(sPg) {
            setPage(sPg);
        }

        getTotalCnt();
        
    },[sWord, sCate, sPg, sParent_idx,  page]);
    
    
    useEffect(()=>{
        
        let bodyPage = {
            nFrom: (sPg - 1) * gListRows,
            gListRows: gListRows,
            searchWord: searchWord,
            searchCates: searchCates,
            parent_idx: parent_idx            
        }
        
        console.log('bodyPage : ', bodyPage);
        axios.post('/api/manage/OperateLogList', bodyPage).then(function(res){
            setViewContent(res.data);
        }) 

    },[sWord, sCate, sParent_idx, sPg]); 


    return(
        <>

            <div id="wrap">
            <Header/>                     
                    <div id="container" data-menu-page="setup_admin">
                        <h3 className="page_ttl">
                            운영로그관리
                        </h3>
                        <form onSubmit={onSubmitSearch} >
                            <div className="top_filter">
                                <div className="date_box datepick_box">
                                    <div className='datepick'>
                                        <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={startDate} onChange={(date) => setStartDate(date)} />
                                    </div>
                                    <span>-</span>
                                    <div className='datepick'>
                                        <DatePicker locale={ko} dateFormat={'yyyy-MM-dd'} selected={endDate} onChange={(date) => setEndDate(date)} />
                                    </div>
                            
                                </div>
                            
                                <div className="sel_box">
                                    <select  name="searchCates" id="searchCates" required onChange={onSearchCates} value={searchCates}>
                                        <option value="sUser_id">상점ID</option>
                                        <option value="sIp">IP</option>
                                        <option value="sMemo">기능</option>
                                        <option value="sUrl">URL</option>
                                    </select>
                                </div>

                                <div className="in_box search_box">
                                    <input type="text" name="searchWord" id="searchWord" value={searchWord} onChange={onSearchWord} placeholder='검색해주세요' />
                                    <button type="submit" className="btn_st_02 blue search">검색</button>
                                </div>                                                        
                            </div>
                        </form>


                        <div className="bot_item">
                            <div className="bot_info">
                                <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>
                

                            </div>
                            <div className="table_box">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>구분</th>
                                            <th>ID</th>
                                            <th>이름</th>
                                            <th>IP</th>
                                            <th>기능</th>
                                            <th>URL</th>
                                            <th>변경일자</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            viewContent.map((v, index) => {

                                                return (
                                                    <Board
                                                        key={v.idx}
                                                        idx={v.idx}
                                                        nums={nums-index}
                                                        sUser_id={v.sUser_id}
                                                        sIp={v.sIp}
                                                        sMemo={v.sMemo}
                                                        sUrl={v.sUrl}
                                                        dUpdate={v.dUpdate}
                                                        user_level={v.user_level}
                                                        user_name={v.user_name}
                                                        branch_name={v.branch_name}
                                                    />
                                                );
                                            })
                                        }
                                        

                                    </tbody>
                                </table>
                            </div>

                            {
                                viewContent.length > 0 ? <Paging curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                            }
                        </div>
                    </div>
                </div>
            <Footer/>                
        </>
    )
}

export default SiteAdminManageOperateLog