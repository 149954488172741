import React  from 'react'
import Header from "../../../include/DistributorHeader";
import Footer from "../../../include/SiteAdminFooter";
import UserEditForm from "../../../../page/setting/UserEditForm";



function DistributorAdminSetting() {



    return (
        <>
        <div id="wrap">
            <Header/>                  
            <UserEditForm user_level={3} user_site={'Distributor'} sIdx={sessionStorage.getItem('loginIdx')} />   
        </div>
        <Footer/>
        </>
    )
}

export default DistributorAdminSetting