import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import HeaderMo from "../../../include/StoreHeaderM";
import card_ok from "../../../../../assets/img/common/card_ok.png"

import queryString from 'query-string';
import PropTypes from 'prop-types';
import jquery from 'jquery';
import $ from 'jquery';


function StoreManagementPayFormStep3() {

    const navigate = useNavigate();
    const location = useLocation();

    
    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);


    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    

    const qs = queryString.parse(window.location.search);


    const sIdx = qs.idx ? qs.idx : '';

    if(sIdx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }


    const [price, set_price] = useState("");
   

    useEffect(() => {
           
        let bodyPage = {
            idx: sIdx
        }

        axios.post('/api/store/management/goodsMod', bodyPage).then(function(res){

            res.data.map((v, index) => {
                set_price(v.price);
            }) 
        }) 
           
    }, []);



    return(
        <>
            <div id="wrap_mo">
                <HeaderMo />

                
                <div id="container" data-menu-page="complete" >  
                    <div className="card_ok_sec">
                        <div className="top">
                            <figure>
                                <img src={card_ok} alt="" />
                            </figure>
                            <p>카드결제가 완료되었습니다</p>
                        </div>
                        <div className="bot">
                            <p>충전금액</p>
                            <em><strong>{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong> 원</em>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default StoreManagementPayFormStep3