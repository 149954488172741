import React, {useEffect} from 'react'

import "../../../assets/css/index.css";
import logo2 from '../../../assets/img/common/preparing.png';
function IndexPage() {

  return (
    <>
    
      <html lang="en">
      <head>
          <meta charSet="UTF-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>준비중입니다</title>
      </head>
      <body>
              <div className="preparing_sect">
                  <img src={logo2} alt="페이지 준비중 이미지" />
                          <h1><strong>유월페이 홈페이지</strong> 점검중입니다.</h1>
                          <p>보다 좋은 서비스를 제공하기 위해 홈페이지 준비중에 있습니다.<br/>
                  빠른 시일내에 준비하여 찾아뵙겠습니다. </p>
              </div>
      </body>
      </html>
    </>
  )
}

export default IndexPage