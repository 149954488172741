import * as axios from "axios";
import { Link } from "react-router-dom";

//import * as axios from 'axios';
import React, { useEffect, useState, useRef } from "react";
import ApexCharts from "apexcharts";

import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";

function SiteAdminMain() {
  const chartRef = useRef(null);

  // 금일 매출액
  const [todayPrice, set_todayPrice] = useState(0);

  // 금일 입금액
  const [todayIncome, set_todayIncome] = useState(0);

  // 금일 차감액
  const [todaySpend, set_todaySpend] = useState(0);

  // 전일 매출액
  const [yesterdayPrice, set_yesterdayPrice] = useState(0);

  // 전일 입금액
  const [yesterdayIncome, set_yesterdayIncome] = useState(0);

  // 전일 차감액
  const [yesterdaySpend, set_yesterdaySpend] = useState(0);

  // 전주 매출액
  const [weekPrice, set_weekPrice] = useState(0);

  // 전주 입금액
  const [weekIncome, set_weekIncome] = useState(0);

  // 전주 차감액
  const [weekSpend, set_weekSpend] = useState(0);

  // 전월 매출액
  const [monthPrice, set_monthPrice] = useState(0);

  // 전월 입금액
  const [monthIncome, set_monthIncome] = useState(0);

  // 전월 차감액
  const [monthSpend, set_monthSpend] = useState(0);

  // 결제완료건수
  const [pay_cnt7, set_pay_cnt7] = useState(0);

  // 결제금액
  const [pay_hap7, set_pay_hap7] = useState(0);

  // 정산내역 - 금액
  const [pay_end_hap, set_pay_end_hap] = useState(0);

  // 지사수
  const [mBranchCnt, set_mBranchCnt] = useState(0);

  // 총판수
  const [distributor, set_distributor] = useState(0);

  // 대리점 수
  const [agency, set_agency] = useState(0);

  // 가맹점 수
  const [store, set_storet] = useState(0);

  // PG 수수료
  const [pg_fee, set_pg_fee] = useState(0);

  // 가맹점 수수료
  const [st_fee, set_st_fee] = useState(0);

  // 지사 수수료
  const [br_fee, set_br_fee] = useState(0);

  // 총판 수수료
  const [di_fee, set_di_fee] = useState(0);

  // 대리점 수수료
  const [ag_fee, set_ag_fee] = useState(0);

  const [notice_res, set_notice_res] = useState({
    code: "notice",
    user_level: "1",
  });

  const [notice_res2, set_notice_res2] = useState({
    code: "notice",
    user_level: "1",
    user_id: "",
  });

  const [noticeContent, setNoticeContent] = useState([]);

  // 차트에 넣을 기간
  let chart_caption = "";
  let today = new Date();
  var oneYearAgo = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  );
  var oneYearAgoFormatted =
    oneYearAgo.getFullYear() +
    "." +
    (oneYearAgo.getMonth() + 1).toString().padStart(2, "0");
  var nowFormatted =
    today.getFullYear() +
    "." +
    (today.getMonth() + 1).toString().padStart(2, "0");
  chart_caption = oneYearAgoFormatted + "~" + nowFormatted;

  // 차트 날짜 부분 구하기
  let chart_x = [];

  // 차트에 들어갈 월별 매출 배열
  let chart_price = [];

  // 차트에 들어갈 월별 건수 배열
  let chart_cnt = [];

  const [faqContent, setFaqContent] = useState([]);
  const [qnaContent, setQnaContent] = useState([]);

  useEffect(() => {
    let bodyQuery = {
      sdate: oneYearAgoFormatted,
      edate: nowFormatted,
    };

    axios
      .post("/api/management/chart_month_price", bodyQuery)
      .then(function (res) {
        let chart_price_list = res.data;

        for (let i = 0; i < 12; i++) {
          //oneYearAgo = new Date(today.getFullYear() - i, today.getMonth(), today.getDate());
          let tmpDay = new Date(
            oneYearAgo.getFullYear(),
            oneYearAgo.getMonth() + i,
            oneYearAgo.getDate()
          );
          let tmpDayFormatted =
            tmpDay.getFullYear() +
            "." +
            (tmpDay.getMonth() + 1).toString().padStart(2, "0");
          tmpDayFormatted = tmpDayFormatted.toString();

          // 라벨 배열 추가
          chart_x.push(tmpDayFormatted);

          let arr_index = chart_price_list.findIndex(
            (v) => v.months === tmpDayFormatted
          );

          if (arr_index < 0) {
            chart_price.push(0);
            chart_cnt.push(0);
          } else {
            // 매출부분입력
            //alert(chart_price_list[arr_index].total_amount);
            //console.log(chart_price_list[arr_index].total_amount)
            chart_price.push(chart_price_list[arr_index].total_amount);
            chart_cnt.push(chart_price_list[arr_index].total_cnt);
          }
        }

        let options = {
          series: [
            {
              name: "매출내역",
              type: "column",
              //data: [23000, 11000, 22000, 27000, 13000, 22000, 37000, 21000, 44000, 22000, 30000, 22000]
              data: chart_price,
            },
            {
              name: "매출건수",
              type: "line",
              //data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 36]
              data: chart_cnt,
            },
          ],
          colors: ["#3a82f8", "#ff2c27"],
          chart: {
            height: 390,
            type: "line",
            stacked: false,
          },
          stroke: {
            width: [0, 4],
            curve: "straight",
            colors: ["#3a82f8", "#ff2c27"],
          },
          plotOptions: {
            bar: {
              borderRadius: 12,
              columnWidth: "30%",
            },
          },

          dataLabels: {
            style: {
              colors: ["#3a82f8", "#ff2c27"],
            },
          },

          fill: {
            opacity: [0.85, 0.25, 1],
            colors: ["#3a82f8", "#ff2c27"],

            gradient: {
              inverseColors: false,
              shade: "light",
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          labels: chart_x,

          markers: {
            size: 4,
            colors: ["#ff2c27"],
            showNullDataPoints: true,
            hover: {
              size: undefined,
              sizeOffset: 3,
            },
          },
          xaxis: {
            type: "category",
            labels: {
              datetimeFormatter: {
                year: "yyyy",
                month: "yyyy 'MM",
              },
            },
          },
          yaxis: [
            {
              title: {},
              labels: {
                formatter: function (value) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
              },
            },
            {
              opposite: true,
              title: {},
              labels: {
                formatter: function (value) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
              },
            },
          ],
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              /*
                        formatter: function (y) {
                            
                            if (typeof y !== "undefined") {
                                return y.toFixed(0) + " 원";
                            }

                            return y;
                    
                        }
                        */
              formatter: function (
                value,
                { series, seriesIndex, dataPointIndex, w }
              ) {
                //return value
                if (seriesIndex === 0) {
                  return (
                    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    " 원"
                  );
                } else {
                  return (
                    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    " 건"
                  );
                }
              },
            },
          },
          subtitle: {
            text: "월별 매출액 : 최근 12개월 (" + chart_caption + ")",
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "Spoqa Han Sans Neo",
              color: "#252525",
            },
          },
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        };

        const chart = new ApexCharts(chartRef.current, options);
        chart.render();
      });

    axios.post("/api/manage/NoticeMainTop", notice_res).then(function (res) {
      setNoticeContent(res.data);
    });

    axios.post("/api/manage/FaqMainTop", notice_res).then(function (res) {
      setFaqContent(res.data);
    });

    axios.post("/api/manage/QnaMainTop", notice_res2).then(function (res) {
      setQnaContent(res.data);
    });
  }, []);

  // 금일 매출액
  function getTodayPrice() {
    let bodyQuery = {};
    axios.post("/api/management/today_price", bodyQuery).then(function (res) {
      set_todayPrice(res.data.total_price);
    });
  }

  // 금일 입금액
  function getTodayIncome() {
    let bodyQuery = {};
    axios.post("/api/management/todayIncome", bodyQuery).then(function (res) {
      set_todayIncome(res.data.total_price);
    });
  }

  // 금일 차감액
  function getTodaySpend() {
    let bodyQuery = {};
    axios.post("/api/management/todaySpend", bodyQuery).then(function (res) {
      set_todaySpend(res.data.total_price);
    });
  }

  // 전일 매출액
  function getYesterdayPrice() {
    let bodyQuery = {};
    axios
      .post("/api/management/yesterday_price", bodyQuery)
      .then(function (res) {
        set_yesterdayPrice(res.data.total_price);
      });
  }

  // 전일 입금액
  function getYesterdayIncome() {
    let bodyQuery = {};
    axios
      .post("/api/management/yesterdayIncome", bodyQuery)
      .then(function (res) {
        set_yesterdayIncome(res.data.total_price);
      });
  }

  // 전일 차감액
  function getYesterdaySpend() {
    let bodyQuery = {};
    axios
      .post("/api/management/yesterdaySpend", bodyQuery)
      .then(function (res) {
        set_yesterdaySpend(res.data.total_price);
      });
  }

  // 전주 매출액
  function getWeekPrice() {
    let bodyQuery = {};
    axios.post("/api/management/week_price", bodyQuery).then(function (res) {
      set_weekPrice(res.data.total_price);
    });
  }

  // 전주 입금액
  function getWeekIncome() {
    let bodyQuery = {};
    axios.post("/api/management/weekIncome", bodyQuery).then(function (res) {
      set_weekIncome(res.data.total_price);
    });
  }

  // 전주 차감액
  function getWeekSpend() {
    let bodyQuery = {};
    axios.post("/api/management/weekSpend", bodyQuery).then(function (res) {
      set_weekSpend(res.data.total_price);
    });
  }

  // 전월 매출액
  function getMonthPrice() {
    let bodyQuery = {};
    axios.post("/api/management/month_price", bodyQuery).then(function (res) {
      set_monthPrice(res.data.total_price);
    });
  }

  // 전월 입금액
  function getMonthIncome() {
    let bodyQuery = {};
    axios.post("/api/management/monthIncome", bodyQuery).then(function (res) {
      set_monthIncome(res.data.total_price);
    });
  }

  // 전월 차감액
  function getMonthSpend() {
    let bodyQuery = {};
    axios.post("/api/management/monthSpend", bodyQuery).then(function (res) {
      set_monthSpend(res.data.total_price);
    });
  }

  // 결제완료건수
  function get_pay_cnt7() {
    let bodyQuery = {};
    axios.post("/api/management/pay_cnt7", bodyQuery).then(function (res) {
      let tmp_cnt = res.data.tcnt;
      if (tmp_cnt == null) {
        tmp_cnt = 0;
      }
      set_pay_cnt7(tmp_cnt);
    });
  }

  // 결제금액
  function get_pay_hap7() {
    let bodyQuery = {};
    axios.post("/api/management/pay_hap7", bodyQuery).then(function (res) {
      let tmp_tprice = res.data.tprice;
      if (tmp_tprice == null) {
        tmp_tprice = 0;
      }
      set_pay_hap7(tmp_tprice);
    });
  }

  // 정산내역 - 금액
  function get_pay_end_hap() {
    let bodyQuery = {};
    axios.post("/api/management/pay_end_hap", bodyQuery).then(function (res) {
      let tmp_tprice = res.data.tprice;
      if (tmp_tprice == null) {
        tmp_tprice = 0;
      }

      set_pay_end_hap(tmp_tprice);
    });
  }

  // 지사수
  function getMbranchCnt() {
    let bodyQuery = {};
    axios.post("/api/management/admin_cnt", bodyQuery).then(function (res) {
      set_mBranchCnt(res.data.tcnt);
    });
  }

  // 총판수
  function getDistributorCnt() {
    let bodyQuery = {};
    axios
      .post("/api/management/distributor_cnt", bodyQuery)
      .then(function (res) {
        set_distributor(res.data.tcnt);
      });
  }

  // 대리점 수
  function getAgencyCnt() {
    let bodyQuery = {};
    axios.post("/api/management/agency_cnt", bodyQuery).then(function (res) {
      set_agency(res.data.tcnt);
    });
  }

  // 가맹점 수
  function getStoreCnt() {
    let bodyQuery = {};
    axios.post("/api/management/store_cnt", bodyQuery).then(function (res) {
      set_storet(res.data.tcnt);
    });
  }

  // PG 수수료
  function getFee() {
    let bodyQuery = {};
    axios.post("/api/management/getFee", bodyQuery).then(function (res) {
      let tmp_pg_fee = res.data.pg_fee;
      let tmp_st_fee = res.data.st_fee;
      let tmp_Br_fee = res.data.Br_fee;
      let tmp_Di_fee = res.data.Di_fee;
      let tmp_Ag_fee = res.data.Ag_fee;

      set_pg_fee(tmp_pg_fee == null ? 0 : tmp_pg_fee);
      set_st_fee(tmp_st_fee == null ? 0 : tmp_st_fee);
      set_br_fee(tmp_Br_fee == null ? 0 : tmp_Br_fee);
      set_di_fee(tmp_Di_fee == null ? 0 : tmp_Di_fee);
      set_ag_fee(tmp_Ag_fee == null ? 0 : tmp_Ag_fee);
    });
  }

  useEffect(() => {
    getTodayPrice();
    getTodayIncome();
    getTodaySpend();
    getYesterdayPrice();
    getYesterdayIncome();
    getYesterdaySpend();
    getWeekPrice();
    getWeekIncome();
    getWeekSpend();
    getMonthPrice();
    getMonthIncome();
    getMonthSpend();
    get_pay_cnt7();
    get_pay_hap7();
    get_pay_end_hap();
    getMbranchCnt();
    getDistributorCnt();
    getAgencyCnt();
    getStoreCnt();
    getFee();
  }, []);

  return (
    <>
      <div id="wrap">
        <Header />

        <div id="container" className="main">
          {/* payment_info_sec */}
          <div className="payment_info_sec row g-lg-3 g-2">
            <div className="col-xl-9 col-md-8 col-12">
              <div className="payment_info_row row g-lg-3 g-2">
                <div className="col-md-3 col-12">
                  <div className="card g-lg-3 g-2">
                    <div className="card-body py-4">
                      <strong className="label">
                        금일 매출액 <span>※현재기준</span>
                      </strong>
                      <p className="all_pay">
                        <b>
                          {("" + todayPrice)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </b>
                        원
                      </p>
                      <div className="pay_detail">
                        <dl>
                          <dt>입금액</dt>
                          <dd>
                            {("" + todayIncome)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </dd>
                        </dl>
                        <dl>
                          <dt>차감액</dt>
                          <dd>
                            {("" + todaySpend)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="card g-lg-3 g-2">
                    <div className="card-body py-4">
                      <strong className="label">전 일 매출액</strong>
                      <p className="all_pay">
                        <b>
                          {("" + yesterdayPrice)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </b>
                        원
                      </p>
                      <div className="pay_detail">
                        <dl>
                          <dt>입금액</dt>
                          <dd>
                            {("" + yesterdayIncome)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </dd>
                        </dl>
                        <dl>
                          <dt>차감액</dt>
                          <dd>
                            {("" + yesterdaySpend)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="card g-lg-3 g-2">
                    <div className="card-body py-4">
                      <strong className="label">전 주 매출액</strong>
                      <p className="all_pay">
                        <b>
                          {("" + weekPrice)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </b>
                        원
                      </p>
                      <div className="pay_detail">
                        <dl>
                          <dt>입금액</dt>
                          <dd>
                            {("" + weekIncome)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </dd>
                        </dl>
                        <dl>
                          <dt>차감액</dt>
                          <dd>
                            {("" + weekSpend)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="card g-lg-3 g-2">
                    <div className="card-body py-4">
                      <strong className="label">전 월 매출액</strong>
                      <p className="all_pay">
                        <b>
                          {("" + monthPrice)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </b>
                        원
                      </p>
                      <div className="pay_detail">
                        <dl>
                          <dt>입금액</dt>
                          <dd>
                            {("" + monthIncome)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </dd>
                        </dl>
                        <dl>
                          <dt>차감액</dt>
                          <dd>
                            {("" + monthSpend)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-12">
              <div className="card g-lg-3 g-2">
                <div className="card-body bg-secondary color-card-body">
                    <div className="left">
                      <p>결제완료건수</p>
                      <span>최근 1주일 이내</span>
                    </div>
                    <div className="right">
                      <b>
                        {("" + pay_cnt7)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </b>
                      건
                    </div>
                </div>
              </div>
              <div className="card g-lg-3 g-2">
                <div className="card-body bg-purple color-card-body">
                    <div className="left">
                      <p>결제금액</p>
                      <span>최근 1주일 이내</span>
                    </div>
                    <div className="right">
                      <b>
                        {("" + pay_hap7)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </b>
                      원
                    </div>
                </div>
              </div>
            </div>
          </div>
          {/*  // payment_info_sec // */}

          {/* calculate_sec */}
          <div className="calculate_sec row g-lg-3 g-2">
            <div className="col-xl-9 col-md-8 col-12">
              <div className="card g-lg-3 g-2">
                <div className="card-body">
                  <div className="management_info">
                    <strong className="ico_ttl">
                      <i></i>업체관리(
                      {mBranchCnt + distributor + agency + store})
                    </strong>
                    <ul className="management_list">
                      <li>
                        <Link
                          to="/SiteAdmin/_management/list"
                          className="link_go"
                        >
                          지사수
                          <span>{mBranchCnt}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/SiteAdmin/_management/DistributorList"
                          className="link_go"
                        >
                          총판 수<span>{distributor}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/SiteAdmin/_management/AgencyList"
                          className="link_go"
                        >
                          대리점 수<span>{agency}</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/SiteAdmin/_management/StoreList"
                          className="link_go"
                        >
                          가맹점 수<span>{store}</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="graph_cont">
                    {/*
                                    <canvas id="graph_item" style={{width:'100%',background:'blue', height: '412px' }}></canvas>
                                    */}
                    <div>
                      <div id="chart" ref={chartRef} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-12">
              <div className="card g-lg-3 g-2">
                <div className="card-body">
                  <div className="cal_item">
                    <div className="top">
                      <p className="sub_ttl">
                        정산내역 <span>최근 1주일 이내</span>
                      </p>
                      <p className="all_pay">
                        <b>
                          {("" + pay_end_hap)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </b>
                        원
                      </p>
                    </div>
                    <ul className="bot_list">
                      <li className="cont_01">
                        <p>
                          <i></i>PG 수수료
                        </p>
                        <em>
                          {("" + pg_fee)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </em>
                      </li>
                      <li className="cont_02">
                        <p>
                          <i></i>가맹점
                        </p>
                        <em>
                          {("" + st_fee)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </em>
                      </li>
                      <li className="cont_03">
                        <p>
                          <i></i>지사
                        </p>
                        <em>
                          {("" + br_fee)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </em>
                      </li>
                      <li className="cont_04">
                        <p>
                          <i></i>총판
                        </p>
                        <em>
                          {("" + di_fee)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </em>
                      </li>
                      <li className="cont_05">
                        <p>
                          <i></i>대리점
                        </p>
                        <em>
                          {("" + ag_fee)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </em>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* // calculate_sec //  */}

          {/* list_cont_sec */}
          <div className="bod_list_cont row g-lg-3 g-2">
            <div className="col-md-4 col-12">
              <div className="card g-lg-3 g-2">
                <div className="card-body py-4">
                  <div className="ttl">
                    <strong>공지사항</strong>
                    <Link to="/SiteAdmin/_manage/NoticeList">
                      더보기 <i>&#xE001;</i>
                    </Link>
                  </div>
                  <ul className="bod_list">
                    {noticeContent.map((v, index) => {
                      return (
                        <li>
                          <Link
                            to={`/SiteAdmin/_manage/noticeView?idx=${v.idx}`}
                          >
                            <span className="sb_ttl">{v.subject}</span>
                            <em className="date">{v.regdate.substr(0, 10)}</em>
                          </Link>
                        </li>
                      );
                    })}
                    {noticeContent.length == 0 && (
                      <li>등록된 글이 없습니다.</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="card g-lg-3 g-2">
                <div className="card-body py-4">
                  <div className="ttl">
                    <strong>자주하는 질문</strong>
                    <Link to="/SiteAdmin/_manage/FaqList">
                      더보기 <i>&#xE001;</i>
                    </Link>
                  </div>
                  <ul className="bod_list">
                    {faqContent.map((v, index) => {
                      return (
                        <li>
                          <Link to={`/SiteAdmin/_manage/FaqView?idx=${v.idx}`}>
                            <span className="sb_ttl">{v.subject}</span>
                            <em className="date">{v.regdate.substr(0, 10)}</em>
                          </Link>
                        </li>
                      );
                    })}
                    {faqContent.length == 0 && <li>등록된 글이 없습니다.</li>}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="card g-lg-3 g-2">
                <div className="card-body py-4">
                  <div className="ttl">
                    <strong>1대1 문의</strong>
                    <Link to="/SiteAdmin/_manage/QnaList">
                      더보기 <i>&#xE001;</i>
                    </Link>
                  </div>
                  <ul className="bod_list">
                    {qnaContent.map((v, index) => {
                      return (
                        <li>
                          <Link to={`/SiteAdmin/_manage/QnaView?idx=${v.idx}`}>
                            <span className="sb_ttl">{v.subject}</span>
                            <em className="date">{v.regdate.substr(0, 10)}</em>
                          </Link>
                        </li>
                      );
                    })}
                    {qnaContent.length == 0 && <li>등록된 글이 없습니다.</li>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*  // list_cont_sec // */}

          {/* <FooterInfo/> */}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default SiteAdminMain;
