import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import {
    Link, useLocation, useNavigate
  } from "react-router-dom";

import Header from "../../../include/SiteAdminHeader";
import FooterInfo from "../../../include/SiteAdminFooterInfo";
import Footer from "../../../include/SiteAdminFooter";
import Paging from "../../../include/Paging";
import queryString from 'query-string';
import PropTypes from 'prop-types';



function SiteAdminTerminalDirectPaymentMod() {

    const navigate = useNavigate();


    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    if(sIdx === ''){
        alert("잘못된 접근입니다.");
        navigate(-1);
    }


    const [sname, set_sname] = useState("");
    const [content, set_content] = useState("");
    const [pg, set_pg] = useState("");
    const [cerify, set_cerify] = useState("");
    const [store, set_store] = useState("");
    const [pkey, set_pkey] = useState("");
    const [income, set_income] = useState("");
    const [fee, set_fee] = useState("");





    useEffect(() => {

        console.log(sIdx);

        let bodyPage = {
            idx: sIdx
        }
        //console.log("bodyPage : " + bodyPage);

        axios.post('/api/terminal/direct_payment_mod', bodyPage).then(function(res){
            console.log(res.data.map);
            res.data.map((v, index) => {
                set_sname(v.sname);
                set_content(v.content);
                set_pg(v.pg);
                set_cerify(v.cerify);
                set_store(v.store);
                set_pkey(v.pkey);
                set_income(v.income);
                set_fee(v.fee);
            })
            
        }) 

        return () => {
          
        };
    }, []);




    const on_sname_handler = (event) => {
        set_sname(event.currentTarget.value)
    }

    const on_content_handler = (event) => {
        set_content(event.currentTarget.value)
    }

    const on_pg_handler = (event) => {
        set_pg(event.currentTarget.value)
    }

    const on_cerify_handler = (event) => {
        set_cerify(event.currentTarget.value)
    }

    const on_store_handler = (event) => {
        set_store(event.currentTarget.value)
    }

    const on_pkey_handler = (event) => {
        set_pkey(event.currentTarget.value)
    }

    const on_income_handler = (event) => {
        set_income(event.currentTarget.value)
    }

    const on_fee_handler = (event) => {
        set_fee(event.currentTarget.value)
    }

    



    const onSubmitHandler = (event) => {
        event.preventDefault();


        let body = {
            idx: qs.idx,
            sname: sname,
            content: content,
            pg: pg,
            cerify: cerify,
            store: store,
            pkey: pkey,
            income: income,
            fee: fee
        }

        axios.post('/api/terminal/direct_payment_update', body)
        .then(function(response){
            

            
            if(response.data === "ModOk"){    
                window.location.reload();
            
            }else{
                alert("시스템 에러");
                
            }
            
            //console.log(response.data);
            
        },[])


        
        
    }

    return (
        <>
        <div id="wrap">
            <Header/>

            <div id="container" data-menu-page="direct_payment_list">
                <h3 className="page_ttl">수기상점 등록</h3>
                <form onSubmit={onSubmitHandler}>
                    <div className="form_item">
                        <h3 className="label">* 기본정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>관리명칭   <span className="red">＊</span></th>
                                        <td >
                                            <div className="in_box">
                                                <input type="text" name="sname" id="sname" value={sname} onChange={on_sname_handler} required />
                                            </div>
                                        </td>
                                        <th>상세정보</th>
                                        <td >
                                            <div className="in_box">
                                                <input type="text" name="content" id="content" value={content} onChange={on_content_handler} required />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>PG사 선택<span className="red">＊</span></th>
                                        <td>
                                            <div className="sel_box" >
                                                <select name="pg" id="pg" required onChange={on_pg_handler} value={pg}>
                                                    <option value="">선택</option>
                                                    <option value="payjoa">페이조아</option>
                                                </select>
                                            </div>
                                        </td>
                                        <th>인증방식 <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="radio_box">
                                                    <input type="radio" name="cerify" id="cerify_ok" value="Y" checked={cerify==='Y'} onChange={on_cerify_handler} />
                                                    <label for="cerify_ok">비생인증(생년월일+비밀번호)   </label>
                                                </div>
                                                <div className="radio_box">
                                                    <input type="radio" name="cerify" id="cerify_no" value="N" checked={cerify==='N'} onChange={on_cerify_handler} />
                                                    <label for="cerify_no">비인증</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>PG상점 code (CPID) <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="store" id="store" value={store} onChange={on_store_handler} required />
                                            </div>
                                        </td>
                                        <th>암복화 KEY  <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="pkey" id="pkey" value={pkey} onChange={on_pkey_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="form_item">
                        <h3 className="label">* 정산정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>신용카드 입금주기   <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box" style={{'width': '200px','margin-right': '10px'}}>
                                                    <input type="text" name="income" id="income" value={income} onChange={on_income_handler} required />
                                                </div>
                                                D+(결제일 기준)
                                            </div>
                                            
                                        </td>
                                        <th>신용카드 수수료율    <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box" style={{'width': '200px','margin-right': '10px'}}>
                                                    <input type="text" name="fee" id="fee" value={fee} onChange={on_fee_handler} required  />
                                                </div>
                                                %
                                                <span className="red" style={{'margin-left': '10px'}}>VAT포함</span>
                                            </div>
                                        </td>
                                    </tr>

                                
                                </tbody>
                            </table>

                        </div>

                    </div>



                    <div className="top_table_btn">
                        <button type="submit" className=" black_btn btn_st_01">등록하기</button>
                        <button type="button" className="line_btn btn_st_01" onClick={  () => navigate(-1) }>목록으로</button>
                    </div>
                </form>

            </div>

        </div>

        <Footer/>
        </>
    )


}
export default SiteAdminTerminalDirectPaymentMod