import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import NoticeView from "../../../../page/notice/View";
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";



function SiteAdminManageNoticeView() {


    return (
        <>
        <div id="wrap">
            <Header/>        
            
            <NoticeView user_level={'1'} user_site={'SiteAdmin'} />
            
        </div>

        <Footer/>
        </>
    )
}

export default SiteAdminManageNoticeView