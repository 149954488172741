import React from 'react'

function SiteAdminFooterInfo() {
    return (
        <footer id="footer">
            <address>
                <span>대표번호:070-8680-0808</span>
                <span>팩스번호:070-8260-0808 </span>
                <span>이메일:starmon@starmon.co.kr</span>
            </address>
            <p className="copy">Copyright &copy;uwalpay All rights reserved.</p>

        </footer>
    )
}

export default SiteAdminFooterInfo