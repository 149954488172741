// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{\r\n\tbox-sizing: border-box;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n}\r\nbody, html{\r\nwidth: 100%;\r\nheight: 100%;\r\n}\r\n.preparing_sect{\r\n\ttext-align: center;\r\n\tmin-height: 100vh;\r\n\tpadding: 50px;\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tflex-direction: column;\r\n\tjustify-content: center;\r\n\tfont-family: 'NanumSquare', sans-serif;\r\n}\r\n.preparing_sect img{\r\n\tdisplay: block;\r\n\tmargin: 0 auto;\r\n\tmax-width: 100%;\r\n}\r\n.preparing_sect h1{\r\n\tfont-size: 48px;\r\n\tfont-weight: 400;\r\n\tmargin: 60px auto 22px;\r\n\t\r\n}\r\n.preparing_sect strong{font-weight: 800;}\r\n.preparing_sect p{\r\n\tfont-size: 24px;\r\n\tline-height: 1.5;\r\n}", "",{"version":3,"sources":["webpack://./src/assest/css/index.css"],"names":[],"mappings":"AAEA;CACC,sBAAsB;CACtB,SAAS;CACT,UAAU;AACX;AACA;AACA,WAAW;AACX,YAAY;AACZ;AACA;CACC,kBAAkB;CAClB,iBAAiB;CACjB,aAAa;CACb,aAAa;CACb,mBAAmB;CACnB,sBAAsB;CACtB,uBAAuB;CACvB,sCAAsC;AACvC;AACA;CACC,cAAc;CACd,cAAc;CACd,eAAe;AAChB;AACA;CACC,eAAe;CACf,gBAAgB;CAChB,sBAAsB;;AAEvB;AACA,uBAAuB,gBAAgB,CAAC;AACxC;CACC,eAAe;CACf,gBAAgB;AACjB","sourcesContent":["@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');\r\n\r\n*{\r\n\tbox-sizing: border-box;\r\n\tmargin: 0;\r\n\tpadding: 0;\r\n}\r\nbody, html{\r\nwidth: 100%;\r\nheight: 100%;\r\n}\r\n.preparing_sect{\r\n\ttext-align: center;\r\n\tmin-height: 100vh;\r\n\tpadding: 50px;\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tflex-direction: column;\r\n\tjustify-content: center;\r\n\tfont-family: 'NanumSquare', sans-serif;\r\n}\r\n.preparing_sect img{\r\n\tdisplay: block;\r\n\tmargin: 0 auto;\r\n\tmax-width: 100%;\r\n}\r\n.preparing_sect h1{\r\n\tfont-size: 48px;\r\n\tfont-weight: 400;\r\n\tmargin: 60px auto 22px;\r\n\t\r\n}\r\n.preparing_sect strong{font-weight: 800;}\r\n.preparing_sect p{\r\n\tfont-size: 24px;\r\n\tline-height: 1.5;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
