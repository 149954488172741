import React, {useState, useRef } from 'react'
import * as axios from 'axios';
import {useNavigate} from "react-router-dom";
import queryString from 'query-string';
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";

import OpenLogComment from "../../../include/OpenLogComment";

function SiteAdminManageAccessIpForm() {

    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    const [log_set_msg, set_log_set_msg] = useState('');
    const [pageReload, set_pageReload] = useState('');    
    React.useEffect(() => {              
        if(log_set === true){
            set_log_set(false);            
        }
    }, [log_set])

    const navigate = useNavigate();

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';
    
    const [myip, set_myip] = useState("");    
    const [page_state, set_page_state] = useState(true);
    const [idx, set_idx] = useState(sIdx);
    const [ip1, set_ip1] = useState("");
    const [ip2, set_ip2] = useState("");
    const [ip3, set_ip3] = useState("");
    const [ip4, set_ip4] = useState("");
    const [memo, set_memo] = useState("");
    const [level, set_level] = useState("0");
    

    const on_ip1_handler = (event) => {
        set_ip1(event.currentTarget.value)
    }
    const on_ip2_handler = (event) => {
        set_ip2(event.currentTarget.value)
    }
    const on_ip3_handler = (event) => {
        set_ip3(event.currentTarget.value)
    }
    const on_ip4_handler = (event) => {
        set_ip4(event.currentTarget.value)
    }  
    const on_memo_handler = (event) => {
        set_memo(event.currentTarget.value)
    }  
    const on_level = (event) => {       
        set_level(event.currentTarget.value)
    }            
    
    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            idx: idx,
            ip: ip1+'-'+ip2+'-'+ip3+'-'+ip4,
            memo: memo,
            level: level           
        }

        axios.post('/api/manage/AccessIpAdd', body)
        .then(function(response){

            if(response.data === "AddOk"){
                if(idx !==''){
                    alert('정상적으로 수정되었습니다.')
                }                
                set_log_set_msg('수정');
                set_log_set(true);
                set_pageReload('/SiteAdmin/_manage/AccessIpList');                                 
            }else{
                alert("시스템 에러");
                
            }

        },[])
    }

    //상세정보
    const Fn_View=(sIdx)=>{

        let bodyPage = {
            idx: sIdx
        }
        
        axios.post('/api/manage/AccessIpInfo', bodyPage).then(function(res){                        
            res.data.map((v, index) => {
                set_ip1(v.ip.split('-')[0]);
                set_ip2(v.ip.split('-')[1]);
                set_ip3(v.ip.split('-')[2]);
                set_ip4(v.ip.split('-')[3]);
                set_memo(v.memo);
                set_level(v.level);              
                return ''
            }); 
            
            if(res.data.length === 0){                
                set_page_state(false)                
            }
        }) 


        
    }

    const FnMyip =async()=>{
        const ipData = await fetch('https://geolocation-db.com/json/');
        const locationIp = await ipData.json();        
        set_myip(locationIp.IPv4)
    }
    
    React.useEffect(() => {
        FnMyip();
        if(sIdx !== ''){
            Fn_View(sIdx);
        }
    }, [sIdx])


    //페이지 정상 접근인지 체크
    React.useEffect(() => {
        if(page_state === false){
            alert("잘못된 접근입니다.");
            navigate(-1);
        }
    }, [page_state])

    

    return (
        <>
        {(log_set === true) && ( <OpenLogComment pageMode={`운영관리 > 접속 IP 관리 > ${log_set_msg}`} pageReload={pageReload} /> )} {/* 운영로그 기록 */} 
        <div id="wrap">
            <Header/>        
            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>
                 
                    <input type="hidden" name="idx" id="idx" value={idx} />
                    <h3 className="page_ttl">관리자 접속 IP</h3>

                    <div className="form_item">
                        
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>

                                    <tr>
                                        <th>IP <span className="red">＊</span></th>
                                        <td colSpan='3'>
                                            <div className="in_box">
                                                <input type="text" id="ip1" name="ip1" maxLength='4' value={ip1} onChange={on_ip1_handler} required style={{width: '70px', marginRight:'5px'}}  /> .
                                                <input type="text" id="ip2" name="ip2" maxLength='4' value={ip2} onChange={on_ip2_handler} required style={{width: '70px', marginRight:'5px', marginLeft:'5px'}} /> .
                                                <input type="text" id="ip3" name="ip3" maxLength='4' value={ip3} onChange={on_ip3_handler} required style={{width: '70px', marginRight:'5px', marginLeft:'5px'}} /> .
                                                <input type="text" id="ip4" name="ip4" maxLength='4' value={ip4} onChange={on_ip4_handler} required style={{width: '70px', marginRight:'5px', marginLeft:'5px'}} />
                                                <span style={{color:'red'}}>접속 IP ( {myip} )</span>
                                            </div>
                                        </td>   
                                    </tr>
                                    <tr>
                                        <th>메모  <span className="red">＊</span></th>
                                        <td colSpan='3'>
                                            <div className="in_box">
                                                <input type="text" name="memo" id="memo" required value={memo} onChange={on_memo_handler} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>사용유무  <span className="red">＊</span></th>
                                        <td colSpan='3'>
                                            <div className="flex">
                                                <div className="radio_box">
                                                    <input type="radio" name="level" id="level1" value="1" checked={String(level)==='1' || level===''} onChange={on_level} />
                                                    <label htmlFor="level1">본사</label>
                                                </div>
                                                <div className="radio_box">
                                                    <input type="radio" name="level" id="level2" value="2" checked={String(level)==='2'} onChange={on_level} />
                                                    <label htmlFor="level2">지사</label>
                                                </div>
                                                <div className="radio_box">
                                                    <input type="radio" name="level" id="level3" value="3" checked={String(level)==='3'} onChange={on_level} />
                                                    <label htmlFor="level3">총판</label>
                                                </div>
                                                <div className="radio_box">
                                                    <input type="radio" name="level" id="level4" value="4" checked={String(level)==='4'} onChange={on_level} />
                                                    <label htmlFor="level4">대리점</label>
                                                </div>
                                                <div className="radio_box">
                                                    <input type="radio" name="level" id="level5" value="5" checked={String(level)==='5'} onChange={on_level} />
                                                    <label htmlFor="level5">가맹점</label>
                                                </div>
                                               
                                            </div>
                                        </td>
                                    </tr>
                                
                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="top_table_btn">
               
                        <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>
                        <button className=" blue btn_st_01">등록</button>
                    </div>

                </form>

            </div>
            
        </div>

        <Footer/>
        </>
    )
}

export default SiteAdminManageAccessIpForm