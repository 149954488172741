import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import {
    useLocation, useNavigate
  } from "react-router-dom";

import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import PagingNew from "../../../include/PagingNew";
import queryString from 'query-string';
import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";
import OpenLogComment from "../../../include/OpenLogComment";


function SiteAdminPayHomepageList() {

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    //console.log(queryParam);

    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    React.useEffect(() => {              
        if(log_set === true){
            window.location.reload();
        }
    }, [log_set])
    

    // 문자열의 쿼리스트링을 Object로 변환
    const query = queryString.parse(location.search);    
    
    // 게시판 내역
    const [viewContent , setViewContent] = useState([]);

    // 게시판 총 수
    const [totalCnt , setTotalCnt] = useState(0);

    // 게시판 넘버
    const [nums , setNums] = useState(0);   

    // 페이징 페이징에 한번에 보일 수량
    const [pageMax, setPageMax] = useState(10);

    // 페이징 한번에 나오는 리스트 수
    const [gListRows, set_gListRows] = useState(15);

    // 페이징 url 정보
    const [url , setUrl] = useState(queryParam);

    
    //const [nFrom, setNFrom] = useState();

    // 사전 처리 완료 체크
    const [load, setLoad] = useState(false);

    // 로딩 제어관련
    const [loading, setLoading] = useState(false);
    
    const qs = queryString.parse(window.location.search);    
    const page = qs.pg? qs.pg : 1;

    let payEnd_txt = "";
    let tdBack = "";

    const Board = ({
        idx,
        nums,
        reg_id,
        goods_name,
        customer_name,
        customer_hp,
        price,
        memo,
        regdate,
        payEnd,
        branch_name,
        regdate2,
        limitDay,
        limitChk

    }) => {

        if(limitChk === "F"){
            payEnd_txt = "기간만료";
            tdBack = "red_bg";

        }else{
            payEnd_txt = "결제전";
            tdBack = "";

        }

        return (
            <tr className={tdBack}>
                <td className="number_td">{nums}</td>
                <td className="branch_name_td">{branch_name}</td>
                <td className="goods_name_td">{goods_name}</td>
                <td className="price_td">{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td className="payEnd_txt_td">{payEnd_txt}</td>
                <td className="regdate2_td">{regdate2}</td>
                <td className="limitDay_td">{limitDay}</td>
                <td className="btn_wrap_td">
                    <div className="btn_wrap"><a className="btn_st_02 blue_line" onClick={ ()=> fnDel(idx) }  >삭제</a></div>
                </td>
            </tr>
        );
    };



    // 전체 수량 구하는 
    function getTotalCnt(){

        const loginId = sessionStorage.getItem("loginId");
        
        if(loginId === null){
            navigate('/SiteAdmin/');
            return;
        }

        let bodyPage = {
            loginId: loginId
        }

        axios.post('/api/management/pay/homepageList_total_list').then(function(res){            
            setTotalCnt(res.data.tcnt);
            setNums( (res.data.tcnt) -  ( (page - 1) * gListRows ) );
        })
    }

    
    useEffect(() => {    
        getTotalCnt();
    },[]);
      
    useEffect(()=>{
        
        let bodyPage = {
            nFrom: (page - 1) * gListRows,
            gListRows: gListRows
        }

        axios.post('/api/management/pay/homepageList_list', bodyPage).then(function(res){
            //console.log(pg + " -> " + nPage + " -> " + pageMax + " -> " + url);
            
            setViewContent(res.data);
            //console.log(res.data)
        })

    },[page]);
        
    



    const fn_submit = (e) => {
        $("#pg").val("1");
        //e.preventDefault();
    }


    function fnDel(idx){
        if (window.confirm("정말 삭제 하시겠습니까?")) {
            
            let bodyPage = {
                idx: idx
            }
            
            axios.post('/api/management/pay/homepageList_delete', bodyPage).then(function(res){            
                alert("삭제되었습니다.");                
                set_log_set(true);
            })

        }


        
        
        
    }

    return (
        <>

        {(log_set === true) && ( <OpenLogComment pageMode={'발송내역 > 웹결제발송내역 > 삭제'} /> )} {/* 운영로그 기록 */}

        <div id="wrap">
            <Header/>            
            <div id="container" data-menu-page="pay_history">
                <h3 className="page_ttl">발송내역</h3>
                

                <div className="bot_item">
                    <div className="bot_info">
                        <div className="left">총 {totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>
                    </div>
                    <div className="table_box">
                        <table>
                            <thead>
                                <tr>
                                    <th>NO</th>
                                    <th>가맹점</th>
                                    <th>상품명</th>
                                    <th>금액</th>
                                    <th>상태</th>
                                    <th>발행날짜</th>
                                    <th>마감날짜</th>
                                    <th>삭제</th>
                                </tr>
                            </thead>

                            <tbody>
                            {
                                viewContent.map((v, index) => {
                                    
                                    
                                    return (
                                        <Board
                                            key={v.idx}
                                            idx={v.idx}
                                            nums={nums-index}
                                            reg_id={v.reg_id}
                                            goods_name={v.goods_name}
                                            customer_name={v.customer_name}
                                            customer_hp={v.customer_hp}
                                            price={v.price}
                                            memo={v.memo}
                                            regdate={v.regdate}
                                            payEnd={v.payEnd}
                                            branch_name={v.branch_name}
                                            regdate2={v.regdate2}
                                            limitDay={v.limitDay}
                                            limitChk={v.limitChk}
                                        />
                                    );
                                })

                                
                            }
                            </tbody>

                        </table>
                    </div>
                    {
                        viewContent.length > 0 ? <PagingNew curPage={page} totalPage={Math.ceil(totalCnt/gListRows)} pageBtnNum={pageMax} url={url} /> : null
                    } 

                </div>
            </div>
        </div>

        <Footer/>
        </>
    )

    
}
export default SiteAdminPayHomepageList