import * as axios from 'axios';
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';

import OpenLogComment from '../../views/include/OpenLogComment';

function UserEditForm({user_level, user_site, sIdx}) {

    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    const [log_set_msg, set_log_set_msg] = useState('');
    const [pageReload, set_pageReload] = useState('');    
    React.useEffect(() => {              
        if(log_set === true){
            set_log_set(false);            
        }
    }, [log_set])

    let siteobj={
        site_title:'',
        apipath_mod:'',
        apipath_update:'',
    }

    if(user_level === 3){
        siteobj.site_title = '총판 상세정보';
        siteobj.apipath_mod = 'distributorMod';
        siteobj.apipath_update = 'DistributorUpdate';

    }else if(user_level === 4){
        siteobj.site_title = '대리점 상세정보';
        siteobj.apipath_mod = 'agencyMod';
        siteobj.apipath_update = 'AgencyUpdate';
    }

    const navigate = useNavigate();

    const [corp_class, set_corp_class] = useState("");
    const [login_able, set_login_able] = useState("");
    const [calculate, set_calculate] = useState("");
    const [bank, set_bank] = useState("");
    const [bankList, set_bankList] = useState([]);
    const [branch_name, set_branch_name] = useState("");
    const [user_id, set_user_id] = useState("");
    const [user_pw, set_user_pw] = useState("");
    const [representative, set_representative] = useState("");
    
    const [parent_idx1, set_parent_idx1] = useState("");
    const [parent_idx2, set_parent_idx2] = useState("");
    const [manager, set_manager] = useState("");
    const [manager_phone, set_manager_phone] = useState("");
    const [corp_name, set_corp_name] = useState("");
    const [corp_nun, set_corp_nun] = useState("");
    const [corp_addr, set_corp_addr] = useState("");
    const [corp_status, set_corp_status] = useState("");
    const [corp_sector, set_corp_sector] = useState("");
    const [commission, set_commission] = useState("");
    const [account_num, set_account_num] = useState("");
    const [account_holder, set_account_holder] = useState("");
    const [id_check, set_id_check] = useState("");
    const [memo, set_memo] = useState("");
    const [managerList1, setManagerList1] = useState([]);
    const [managerList2, setManagerList2] = useState([]);
    const [contract_status, set_contract_status] = useState("");


    useEffect(() => {

        let bodyPage = {
            idx: sIdx
        }

        axios.post('/api/management/' + siteobj.apipath_mod, bodyPage).then(function(res){
            //console.log('res : ', res);
            res.data.map((v, index) => {
                set_parent_idx1(v.parent_idx);
                getManagerList2(v.parent_idx);
                set_parent_idx2(v.parent_idx2);
                set_branch_name(v.branch_name);
                set_user_id(v.user_id);
                set_corp_class(v.corp_class);
                set_representative(v.representative);
                set_manager((v.manager === null ? '':v.manager));
                set_manager_phone((v.manager_phone === null ? '':v.manager_phone));
                set_corp_name((v.corp_name === null ? '':v.corp_name));
                set_corp_nun((v.corp_nun === null ? '':v.corp_nun));
                set_corp_addr((v.corp_addr === null ? '':v.corp_addr));
                set_corp_status((v.corp_status === null ? '':v.corp_status));
                set_corp_sector((v.corp_sector === null ? '':v.corp_sector));
                set_contract_status((v.contract_status === null ? '':v.contract_status));
                set_login_able((v.login_able === null ? '':v.login_able));
                set_commission((v.commission === null ? '':v.commission));
                set_calculate((v.calculate === null ? '':v.calculate));
                set_bank((v.bank === null ? '':v.bank));
                set_account_num((v.account_num === null ? '':v.account_num));
                set_account_holder((v.account_holder === null ? '':v.account_holder));
                set_memo((v.memo === null ? '':v.memo));

            })
            
        }) 


        // 은행 리스트
        getBankList();
        
    }, []);


    
    const on_contract_status = (event) => {
        set_contract_status(event.currentTarget.value)
    }

    const on_corp_class = (event) => {
        set_corp_class(event.currentTarget.value)
    }

    

    const on_login_able = (event) => {
        set_login_able(event.currentTarget.value)
    }
    
    const on_bank = (event) => {
        set_bank(event.currentTarget.value)
    }

    const on_user_pw_handler = (event) => {
        set_user_pw(event.currentTarget.value)
    }

    const on_representative_handler = (event) => {
        set_representative(event.currentTarget.value)
    }

   
    const on_corp_name_handler = (event) => {
        set_corp_name(event.currentTarget.value)
    }

    const on_corp_nun_handler = (event) => {
        set_corp_nun(event.currentTarget.value)
    }

    const on_corp_addr_handler = (event) => {
        set_corp_addr(event.currentTarget.value)
    }

    const on_corp_status_handler = (event) => {
        set_corp_status(event.currentTarget.value)
    }

    const on_corp_sector_handler = (event) => {
        set_corp_sector(event.currentTarget.value)
    }

    const on_commission_handler = (event) => {
        set_commission(event.currentTarget.value)
    }

    const on_account_num_handler = (event) => {
        set_account_num(event.currentTarget.value)
    }

    const on_account_holder_handler = (event) => {
        set_account_holder(event.currentTarget.value)
    }

    const on_set_parent_idx1_handler = (event) => {
        set_parent_idx1(event.currentTarget.value);
        getManagerList2(event.currentTarget.value);
    }

    const on_set_parent_idx2_handler = (event) => {
        set_parent_idx2(event.currentTarget.value)
    }

    const on_memo_handler = (event) => {
        set_memo(event.currentTarget.value)
    }

    
    const onSubmitHandler = (event) => {
        event.preventDefault();

        let body = {
            idx: sIdx,
            parent_idx: parent_idx1,
            parent_idx2: parent_idx2,
            user_id: user_id,
            user_pw: user_pw,
            branch_name: branch_name,
            manager: manager,
            manager_phone: manager_phone,
            login_able: login_able,
            commission: commission,
            calculate: calculate,
            corp_class: corp_class,
            representative: representative,
            corp_name: corp_name,
            corp_nun: corp_nun,
            corp_addr: corp_addr,
            corp_status: corp_status,
            corp_sector: corp_sector,
            bank: bank,
            account_num: account_num,
            account_holder: account_holder,
            contract_status: contract_status,
            memo: memo
        }

        axios.post('/api/management/' + siteobj.apipath_update, body)
        .then(function(response){
            if(response.data === "ModOk"){    
                set_log_set_msg('수정');
                set_log_set(true);
                set_pageReload('reload');
            }else{
                alert("시스템 에러");   
            }
        },[])


    }

    function getManagerList1(){
        
        let bodyPage = {
            user_level: 2,
        }
        axios.post('/api/management/manager_list',bodyPage).then(function(res){            
            setManagerList1(res.data);
            
        })
    }

    function getManagerList2(temp_idx){

        
        let bodyPage = {
            user_level: 3,
            parent_idx: temp_idx,
        }
        axios.post('/api/management/distributor_sel_list',bodyPage).then(function(res){            
            setManagerList2(res.data);
            
        })
    
    }

    function rendManagerList(){
        return (
            <select name="parent_idx1" id="parent_idx1" value={parent_idx1} required onChange={on_set_parent_idx1_handler}>
                <option value=''>==지사선택==</option>
            {
                managerList1.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }

    function rendManagerList2(){
        return (
            <select name="parent_idx2" id="parent_idx2" value={parent_idx2} required onChange={on_set_parent_idx2_handler}>
                <option value=''>==선택==</option>
            {
                managerList2.map((v, index) => {
                    return (
                        <option value={v.idx} key={v.idx} >{v.branch_name}</option>
                    )
                })
            }
            </select>
        )
    }


    

    // 상위지사 내역 가져오기
    useEffect(() => {
        getManagerList1()
    },[])


    // 총판 내역 가져오기
    useEffect(() => {
        //getManagerList2();
    },[managerList1])




    function getBankList(){
        axios.post('/api/management/bank_list').then(function(res){         
            
            set_bankList(res.data);
            
        })

    }


    return (
        <>
            {(log_set === true) && ( <OpenLogComment pageMode={`정보변경 > ${log_set_msg}`} pageReload={pageReload} /> )} {/* 운영로그 기록 */} 

            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>

                    <h3 className="page_ttl">{siteobj.site_title}</h3>

                        <div className="form_item">
                        <h3 className="label">* 기본정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        
                                        {(user_level === 3) && (
                                            <>
                                            <th>상위지사 <span className="red">＊</span></th>
                                            <td>
                                                <div className="sel_box flex">
                                                    {
                                                        rendManagerList()
                                                    }
                                                    
                                                </div>
                                            </td>
                                            </>
                                        )}
                                        {(user_level === 4) && (
                                            <>
                                            <th>상위총판 <span className="red">＊</span></th>
                                            <td>
                                                <div className="sel_box flex">
                                                    {
                                                        rendManagerList()
                                                    }
                                                    {
                                                        rendManagerList2()
                                                    }
                                                    
                                                </div>
                                            </td>
                                            </>
                                        )}

                                        {(user_level !== 2) && (
                                            <>
                                            
                                            <th>계약상태   <span className="red">＊</span></th>
                                            <td>
                                                <div className="sel_box">
                                                    <select name="contract_status" disabled id="contract_status" value={contract_status} required onChange={on_contract_status}>
                                                        <option value="Y" >정상</option>
                                                        <option value="F">해지</option>
                                                        <option value="A">신청</option>
                                                    </select>
                                                </div>
                                            </td>
                                                                                    
                                            </>
                                        )}
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="form_item">
                        <h3 className="label">* 로그인 정보</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>

                                    <tr>
                                        <th>로그인ID <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box" style={{width:'400px'}}>
                                                    {user_id}
                                                </div>
                                            </div>
                                        </td>
                                        <th>비밀번호 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="password" name="user_pw" id="user_pw" value={user_pw} onChange={on_user_pw_handler} />
                                                <p>비밀번호 변경 시에만 입력하세요.</p>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>수수료  <span className="red">＊</span></th>
                                        <td>
                                            <div className="flex">
                                                <div className="in_box" style={{width: '100px'}}>
                                                    <input type="text" name="commission" id="commission" value={commission} onChange={on_commission_handler} required />
                                                </div>
                                                %
                                                <span className="red" style={{margin:'0 10px'}}>(VAT 포함가) </span>
                                                <span>수수료 = 가맹점 수수료 - 제공 수수료</span>
                                            </div>
                                        
                                        </td>
                                        <th>로그인  <span className="red">＊</span></th>
                                        <td>
                                            <div className="sel_box">
                                                <select name="login_able"  id="login_able" disabled value={login_able} required onChange={on_login_able}>
                                                    <option value="Y">허용</option>
                                                    <option value="N">불가</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>

                    <div className="form_item">
                        <h3 className="label">* 사업자 정보 ( 선택 사항 )</h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>사업자 구분</th>
                                        <td>
                                            <div className="sel_box">
                                                <select name="corp_class" id="corp_class" value={corp_class} required onChange={on_corp_class}>
                                                    <option value="">선택</option>
                                                    <option value="corp">법인사업자</option>
                                                    <option value="solo">개인사업자</option>
                                                    <option value="non">비사업자</option>
                                                </select>
                                            </div>
                                        </td>
                                        <th>대표자  </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="representative" id="representative" value={representative} onChange={on_representative_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>사업자명 </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_name" id="corp_name" value={corp_name} onChange={on_corp_name_handler} required />
                                            </div>
                                        </td>
                                        <th>사업자등록번호 <span className="red">＊</span></th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_nun" id="corp_nun" value={corp_nun} onChange={on_corp_nun_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>사업자주소 </th>
                                        <td colSpan="3">
                                            <div className="in_box">
                                                <input type="text" name="corp_addr" id="corp_addr" value={corp_addr} onChange={on_corp_addr_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>업태 </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_status" id="corp_status" value={corp_status} onChange={on_corp_status_handler}  required />
                                            </div>
                                        </td>
                                        <th>종목 </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="corp_sector" id="corp_sector" value={corp_sector} onChange={on_corp_sector_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="form_item">
                        <h3 className="label">*  정산계좌정보 </h3>

                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th>은행선택  </th>
                                        <td colSpan="3">
                                            <div className="sel_box table_cont_1">
                                                <select name="bank" id="bank" value={bank} required onChange={on_bank}>
                                                    <option value="">선택</option>
                                                    {
                                                        bankList.map((v, index)=> {
                                                            return (
                                                                <option value={v.bank_code} key={index}>{v.bank_name}</option>
                                                            )

                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                
                                    <tr>
                                        <th>계좌번호  </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="account_num" id="account_num" value={account_num} onChange={on_account_num_handler} required />
                                            </div>
                                        </td>
                                        <th>예금주 </th>
                                        <td>
                                            <div className="in_box">
                                                <input type="text" name="account_holder" id="account_holder" value={account_holder} onChange={on_account_holder_handler} required />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>기타  </th>
                                        <td colSpan="3">
                                            <div className="text_area">
                                                <textarea name="memo" id="memo" value={memo} onChange={on_memo_handler} ></textarea>
                                            </div>
                                        </td>
                                    </tr>


                    

                                </tbody>
                            </table>

                        </div>

                    </div>

                    <div className="top_table_btn">
                        <button className=" blue btn_st_01">등록</button>
                    </div>                        
                    
                </form>

            </div>
            
      

       
        </>
    )
}

export default UserEditForm