

import * as axios from 'axios';
import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom";

function OpenLogComment({pageMode, pageReload}) {    
    console.log('pageMode : ', pageMode);

    const navigate = useNavigate();

    //사용자 ID
    const loginId = sessionStorage.getItem("loginId");
    const loginIdx = sessionStorage.getItem("loginIdx");
        
    const Log_Save =()=>{
        const _user_ip = sessionStorage.getItem("user_ip");
        let body = {
            loginId: loginId,
            loginIdx: loginIdx,
            uesrIP: (_user_ip === null ? '' : _user_ip),
            sUrl: window.location.href,
            sMemo: pageMode,
        }    
        console.log('Log_Save > body : ', body);
        axios.post('/api/_manage/OpenLogSet', body)
        .then(function(res){
            console.log('res : ', res);
            if(pageReload === 'reload'){                
                window.location.reload();
            }else if(pageReload === '-1'){                
                window.history.go(-1);
            }else if(pageReload !== null && pageReload !== ''){
                navigate(pageReload);
            }
        }).catch((err) => {
            console.log('err : ', err);
        })            
    }

    useEffect(() => {
        Log_Save();
    }, []);


    return (
        <>
        LOG
        </>
    );
}


export default OpenLogComment