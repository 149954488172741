import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import Editor from '../../quill/EditorComponent';
import OpenLogComment from "../../views/include/OpenLogComment";

function NoticeForm({user_level, user_site}) {

    const navigate = useNavigate();
    const location = useLocation();
    const query = queryString.parse(location.search);  
    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    React.useEffect(() => {              
        if(log_set === true){
            navigate('/'+user_site+'/_manage/NoticeList');
        }
    }, [log_set])
        
    const [myip, set_myip] = useState(""); 
    const [page_state, set_page_state] = useState(true);


    const [idx, set_idx] = useState(sIdx);
    const on_idx_handler = (event) => {
        set_idx(event.currentTarget.value)
    }
    
    
    //제목
    const [subject, set_subject] = useState('');
    const on_subject_handler = (event) => {
        set_subject(event.currentTarget.value)
    }

    //내용
    const [contents, set_contents] = useState('');
    function onEditorChange(value) {
        set_contents(value)
    }


    //등록
    const onSubmitHandler = (event) => {
        event.preventDefault();

        if(contents === ''){
            alert('내용을 입력해주세요.');
            return;
        }

        let body = {
            idx: idx,
            code: (query.code === undefined || query.code === '' ? 'notice':query.code),
            subject: subject,
            contents: contents,  
            ip_address: myip,
            user_level: user_level,                 
            user_id: sessionStorage.getItem("loginId"),
        }

        axios.post('/api/manage/NoticeFormAdd', body).then(function(response){
            if(response.data === "AddOk"){
                if(idx !==''){
                    alert('정상적으로 등록되었습니다.')
                }

                set_log_set(true);
                
            }else{
                alert("시스템 에러");                
            }

        },[])

    }


        //상세정보
    const Fn_View=(sIdx)=>{

        let bodyPage = {
            idx: sIdx
        }
        
        axios.post('/api/manage/NoticeInfo', bodyPage).then(function(res){                        
            res.data.map((v, index) => {
                set_subject(v.subject);
                set_contents(v.contents);                                
                return ''
            }); 
            
            if(res.data.length === 0){                
                set_page_state(false)                
            }
        })         
    }

    const FnMyip =async()=>{
        const ipData = await fetch('https://geolocation-db.com/json/');
        const locationIp = await ipData.json();        
        set_myip(locationIp.IPv4)
    }
    
    React.useEffect(() => {
        FnMyip();
        if(sIdx !== ''){
            Fn_View(sIdx);
        }
    }, [sIdx])


    //페이지 정상 접근인지 체크
    React.useEffect(() => {
        if(page_state === false){
            alert("잘못된 접근입니다.");
            navigate(-1);
        }
    }, [page_state])

    

    return (
        <>
            {/* 운영로그 기록 */}
            {(log_set === true) && ( <OpenLogComment pageMode={(sIdx === '' ? '운영관리 > 공지사항 등록' : '운영관리 > 공지사항 수정')} /> )}
                
            <div id="container" data-menu-page="distributor_list">
                <form onSubmit={onSubmitHandler}>
                 
                    <input type="hidden" name="idx" id="idx" value={idx} onChange={on_idx_handler} />
                    
                    <h3 className="page_ttl">공지사항</h3>

                    <div className="form_item">
                        
                        <div className="top_table">
                            <table>
                                <colgroup>
                                    <col width="200px" />
                                    <col width="*" />
                                    <col width="200px" />
                                    <col width="*" />
                                </colgroup>

                                <tbody>

                                    <tr>
                                        <th>제목 <span className="red">＊</span></th>
                                        <td colSpan='3'>
                                            <input type="text" name="subject" id="subject" required value={subject} onChange={on_subject_handler} />
                                        </td>   
                                    </tr>
                                    <tr>
                                        <th>내용  <span className="red">＊</span></th>
                                        <td colSpan='3'>                                            
                                            <Editor value={contents} onChange={onEditorChange} />                                           
                                        </td>
                                    </tr>
                                  
                                </tbody>
                            </table>

                        </div>

                    </div>


                    <div className="top_table_btn">
                 
                        <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>
                        <button className=" blue btn_st_01">등록</button>
                    </div>

                </form>

            </div>                    
        </>
    )
}

export default NoticeForm