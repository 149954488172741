import re_closebtn_w from '../../../assets/img/common/re_closebtn_w.png';
import * as axios from 'axios';
import React, {useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import {useCookies} from 'react-cookie'
import moment from 'moment';


const AuthPopup =({pLoginId, pPassword, pLoginPopup, pUserInfo, Props_FnPopupClose, Props_FnLoginAuth})=>{

    // console.log('pLoginId : ', pLoginId);
    // console.log('pPassword : ', pPassword);
    // console.log('pLoginPopup : ', pLoginPopup);
    // console.log('pUserInfo : ', pUserInfo);
    
    const [loginPopup, setLoginPopup] = useState(pLoginPopup);
    const [loginType, setLoginType] = useState("EMAIL");
    const [loginEdit, setLoginEdit] = useState("");
    const [typeNumber, setTypeNumber] = useState(""); //메일 및 휴대폰 번호
    const [snsNumber, setSnsNumber] = useState(""); //신규 등록할 메일 및 휴대폰 번호
    const [authNumber, setAuthNumber] = useState(""); //인증번호 확인
    const [choiceValue, setChoiceValue] = useState(""); //인증 정보 클릭
    const [loginInfo, setLoginInfo] = useState(); //1차 로그인 인증 후 회원정보
    const [certNumber, setCertNumber] = useState(""); //인증확인 번호
    const [certNumber2, setCertNumber2] = useState(""); //추가 인증확인 번호
    const [clickValue, setClickValue] = useState(""); //인증할 연락처 선택된 값
    const [radio_Checked, setRadio_Checked] = useState(false); //라디오 체크 유무 처리
    
    const [CookieCertNum, setCookieCertNum, removeCookieCertNum] = useCookies(['cert_num']); // 쿠키 훅 
    //const [CookieCertNum2, setCookieCertNum2, removeCookieCertNum2] = useCookies(['cert_num2']); // 쿠키 훅

    //해당 사용자의 휴대폰, 이메일 인증된 정보 가저오기 
    const [certSnsList , setCertSnsList] = useState([]);

    const [branch_name, setBranch_name] = useState(''); 
    const [account_num, setAccount_num] = useState(''); 
    const [auth_check, setAuth_check] = useState(false); 
    
    
    const onCertNumberHandler = (event) => {
        setCertNumber(event.currentTarget.value)
    }

    const onCertNumber2Handler = (event) => {
        setCertNumber2(event.currentTarget.value)
    }

    const onSnsNumberHandler = (event) => {        
        setSnsNumber(event.currentTarget.value);
        setAuth_check(false);
    }

    const onAccount_numHandler = (event) => {
        setAccount_num(event.currentTarget.value)
    }

    const onBranch_nameHandler = (event) => {
        setBranch_name(event.currentTarget.value)
    }

    


    const Fn_User_Cert_List =(mode)=>{
        // console.log('mode : ', mode)
        let bodyPage = {
            loginType: mode
        }
        axios.post('/api/agency/login_cert_list', bodyPage).then(function(res){
            //console.log('res.data : ', res.data)
            setCertSnsList(res.data);
        }) 
    }
 
    useEffect(()=>{
        Fn_User_Cert_List(loginType);
    }, [loginType])


    //인증 팝업 닫기
    const Fn_PopupClose =()=>{
        setLoginPopup(false);
        setLoginType('EMAIL');
        Props_FnPopupClose();
    }

    const Fn_PopupEditClose =()=>{
        setCertNumber2('');
        setLoginEdit('');
        Fn_All_Rest();
        
    }

    //이메일, 휴대폰 인증타입 선택
    const Fn_TabState =(tab)=>{
        setLoginType(tab);
        Fn_All_Rest();

    }

    //다른 이메일, HP 계정 등록 버튼
    const Fn_Edit =(mode)=>{
        setLoginEdit(mode);
        setChoiceValue('');
        Fn_All_Rest();
    }

    const Fn_All_Rest =()=>{
        setClickValue('');
        setSnsNumber('');
        setAccount_num('');
        setBranch_name('');
        setAuth_check(false);
        removeCookieCertNum(['cert_num']);       
    }

    //선택된 이메일에 인증 번호 발송
    const Fn_SNS_Send =(type)=>{
        if(clickValue === ''){
            alert('보내실 계정을 선택해주세요.');
            return;
        }
        if(type === 'EMAIL'){
            FN_EMAIL_SEND_PROC(clickValue)
        }else if('HP'){
            FN_HP_SEND_PROC(clickValue)
        }
        
    }

    //최종 인증번호 확인
    const Fn_Sns_Proc_Check =()=>{
        if(clickValue === ''){
            alert('인증할 계정을 선택해주세요.');
            return;
        }
        if(certNumber === ''){
            alert('인증번호를 입력해주세요.');
            return;
        }
    
        if(certNumber !== CookieCertNum.cert_num){
            alert('인증번호가 일치하지 않습니다.');
            return;
        }

        Props_FnLoginAuth();
    }

    
    //신규 로그인 인증등록 전 발송할 sns 계정으로 인증번호 발송
    const Fn_SNS_Send_V2 =()=>{
        if(snsNumber === ''){
            alert('계정을 입력해주세요.');
            return;
        }
               
        if(loginType === 'EMAIL'){
            FN_EMAIL_SEND_PROC(snsNumber);
        }else if(loginType === 'HP'){
            FN_HP_SEND_PROC(snsNumber);
        }

    }


    //이메일 인증번호 전송
    const FN_EMAIL_SEND_PROC =(pEmail)=>{
        
        const cert_num = Math.floor(Math.random() * 999999);

        let body = {
            receivers: pEmail,
            subject:'[유월] 인증번호를 보내드립니다.',
            content:'인증번호 : ' + cert_num
        }
        
        axios.post('/api/email/sendMail', body)
        .then(function(res){
            
            if(res.data.code === 'ERR'){
                alert(res.data.message);
                return;
            }
            
            alert("인증번호를 발송하였습니다.");
            const pExpires =  moment().add('3','m').toDate()
            setCookieCertNum('cert_num', cert_num, {expires:pExpires})
        }).catch((err) => {
            console.log('err : ', err);
        })

    }

    //휴대폰 인증번호 발송
    const FN_HP_SEND_PROC =(pHp)=>{

        const cert_num = Math.floor(Math.random() * 999999);     
        let body = {
            receivers: pHp,
            message: '인증번호 : ' + cert_num
        }

        axios.post('/api/sms/send', body)
        .then(function(res){  
            if(res.data.result_code.toString() ==='-101'){
                alert(res.data.message);
                return;
            }                 
            alert("인증번호를 발송하였습니다.");
            const pExpires =  moment().add('3','m').toDate()
            setCookieCertNum('cert_num', cert_num, {expires:pExpires})
        }).catch((err) => {
            //console.log('err : ', err);
        })
    }

    //신규 인증 정보 체크
    const Fn_Sns_Proc_Check2 =()=>{


        if(certNumber2 === ''){
            alert('인증번호를 입력해주세요.');
            return;
        }

        if(certNumber2 !== CookieCertNum.cert_num){
            setAuth_check(false);
            alert('인증번호가 일치하지 않습니다.');
            return;
        }

        setAuth_check(true);
        alert("정상적으로 인증되었습니다.");        
    }

    const Fn_SNS_INSERT =()=>{

        if(account_num === ''){
            alert('계좌번호 뒷자리(5자리) 입력해주세요.');
            return;
        }

        if(branch_name === ''){
            alert('회사명을 입력해주세요.');
            return;
        }

        if(auth_check === false){               
            alert('인증확인이 필요합니다.');            
            return;
        }

        let body = {
            LoginId: pLoginId,
            LoginType: loginType,
            SnsNumber: snsNumber,
            Branch_name: branch_name,
            Account_num: account_num
        }

        axios.post('/api/manage/Login_Cert_Add', body).then(function(response){
           
            if(response.data === "OK"){    
                alert("정상적으로 등록되었습니다.");
                Fn_PopupEditClose();
                Fn_User_Cert_List(loginType);
                setAuth_check(false);
            }else{                
                alert(response.data);
            }
        },[])

    }

    //라디오 버튼 선택 값
    const Fn_Radio_Click_Val =(sValue)=>{
        setClickValue(sValue);
    }
    return(        
        <div>
            <div className="pop_common intro_pop login_confirm01" style={{display:(loginPopup === true ? 'block' : 'none')}} > 
                <div className="pop_cont ">
                    <div className="pop_top">
                        <h3 className="pop_ttl">로그인 추가인증 선택</h3>
                        <button type="button" onClick={Fn_PopupClose}>
                            <img src={re_closebtn_w} alt="닫기버튼" />
                        </button>
                    </div>
                
                    <div className="pop_item">
                        <div className="pop_inner">
                            <span className="txt">
                                정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 28조에 제 1항 제2호 개인정보의<br/>
                                보호조치에 따라 “로그인 시 추가인증"이 필요하게 되었습니다. 따라서 추가인증 수단을 사전에<br/> 
                                등록하신 후 로그인시 해당 수단을 선택하여 인증하여 주시기 바랍니다.
                            </span>
                            <em>아래 인증 수단 중 택일하여 추가인증을 진행하여 주시기 바랍니다.</em>
                            <div className="btn_box confirm_tab">
                                <Link to='#' className={`dark_line_btn ${(loginType === 'EMAIL' || loginType === '' ? 'on' : "")}`} onClick={()=>{Fn_TabState('EMAIL')}}>이메일 인증</Link>
                                <Link to='#' className={`dark_line_btn ${(loginType === 'HP' ? 'on' : "")}`} onClick={()=>{Fn_TabState('HP')}} >휴대폰 인증</Link>
                            </div>				
                            <div className="tab_wrap">
                                
                                
                                <div className="tab" style={{display:`${loginType === 'EMAIL' ? 'block' : 'none'}`}}>
                                    <span className="txt">· 가맹점관리자 계정 내 등록된 휴대폰 번호로 인증번호가 발송됩니다.</span>
                                    <span className="txt">· 선택한 정보로 수신된 인증번호를 입력하여 주시기 바랍니다.</span>
                                    <span className="txt">· '다른 휴대폰 등록'을 클릭하여 인증정보 추가 등록이 4개까지 가능합니다</span>
                                    <div className="inner_bottom login_add_radio">
                                        <ul>
                                            
                                            
                                        {
                                                            
                                            certSnsList.map((key, value) => {                                                
                                                return (
                                                    <li>
                                                        <div className="radio_box">
                                                            <input type="radio" 
                                                            id={'email_select_'+key.idx} 
                                                            onChange={()=>{Fn_Radio_Click_Val(key.email)}} 
                                                            name="email_select" 
                                                            value={key.email}                                                             
                                                            checked={(clickValue === key.email)}
                                                            />
                                                            <label htmlFor={'email_select_'+key.idx} >
                                                                <span> <i>{key.email}</i></span>
                                                            </label>
                                                        </div>	
                                                    </li>
                                                );
                                            })
                                        }
                                        {  
                                            (certSnsList.length === 0) && (
                                                <li>
                                                    <div className="radio_box">
                                                        등록된 계정이 없습니다.
                                                    </div>	
                                                </li>
                                            )
                                        }
                                        </ul>
                                        <div className="btn_box intro_btn">
                                            
                                            <Link to='#' onClick={()=>{Fn_SNS_Send('EMAIL')}} className="dark_full_btn" >이메일 발송</Link>
                                            <Link to='#' onClick={()=>{Fn_Edit('EMAIL')}} className="gray_line_btn register_email" >다른 이메일 주소 등록</Link>
                                        
                                        </div>
                                    </div>				
                                    <div className="pop_table">
                                        <table className="table_layout01">
                                
                                            <tbody>
                                            
                                                <tr>
                                                    <th>인증확인</th>
                                                    <td>
                                                        <div className="button_row flex_box">
                                                            <input className="" type="text" name="certNumber" id="certNumber" placeholder="인증번호"  maxLength={6}
                                                                value={certNumber}  
                                                                onChange={onCertNumberHandler} />
                                                            <button className="list_btn" type="button" onClick={Fn_Sns_Proc_Check} >인증확인</button>
                                                        </div>
                                                
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>					
                                </div>


                                <div className="tab" style={{display:`${loginType === 'HP' ? 'block' : 'none'}`}}>
                                    <span className="txt">· 가맹점관리자 계정 내 등록된 휴대폰 번호로 인증번호가 발송됩니다.</span>
                                    <span className="txt">· 선택한 정보로 수신된 인증번호를 입력하여 주시기 바랍니다.</span>
                                    <span className="txt">· '다른 휴대폰 등록'을 클릭하여 인증정보 추가 등록이 4개까지 가능합니다</span>
                                    <div className="inner_bottom login_add_radio">
                                        <ul>
                                        {
                                            certSnsList.map((key, value) => {
                                                return (
                                                    <li >
                                                        <div className="radio_box">                                                                                                                        
                                                            <input type="radio" 
                                                                id={'hp_select_'+key.idx} 
                                                                onChange={()=>{Fn_Radio_Click_Val(key.hp)}} 
                                                                name="hp_select" 
                                                                value={key.hp}                                                             
                                                                checked={(clickValue === key.hp)}
                                                            />
                                                            <label htmlFor={'hp_select_'+key.idx} >
                                                                <span> <i>{key.hp}</i></span>
                                                            </label>
                                                            
                                                        </div>	
                                                    </li> 
                                                );
                                            })
                                        }
                                        
                                        </ul>
                                        <div className="btn_box intro_btn">
                                            <Link to='#' onClick={()=>{Fn_SNS_Send('HP')}} className="dark_full_btn" >문자 발송</Link>
                                            <Link to='#' className="gray_line_btn register_phone" onClick={()=>{Fn_Edit('HP')}} >다른 휴대폰 등록</Link>

                                            
                                            {/* <a href="javascript:auth_js('1');" className="dark_full_btn">문자 발송</a>
                                            <a href="javascript:void(0);" className="gray_line_btn register_phone">다른 휴대폰 등록</a> */}
                                            
                                        </div>
                                    </div>				
                                    <div className="pop_table">
                                        <table className="table_layout01">
                                            <tbody>
                                                <tr>
                                                    <th>인증확인</th>
                                                    <td>
                                                        <div className="button_row flex_box">
                                                            <input className="" type="text" name="certNumber" id="certNumber" placeholder="인증번호" maxLength={6}
                                                                value={certNumber}  
                                                                onChange={onCertNumberHandler} />
                                                            <button className="list_btn" type="button" onClick={Fn_Sns_Proc_Check} >인증확인</button>
                                                        </div>
                                                
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>					
                                </div>

                            </div>
                        </div>
                    </div>
                </div> 
                <div className="dim"></div>
            </div>




            <div className="pop_common intro_pop login_confirm02 " style={{display:`${loginEdit !== '' ? 'block' : 'none'}`}} >
                <div className="pop_cont ">
                    <div className="pop_top">
                        <h3 className="pop_ttl">로그인 추가인증 선택</h3>
                        <button type="button" >
                            <img src={re_closebtn_w} alt="닫기버튼" onClick={Fn_PopupEditClose} />
                        </button>
                    </div>
                    
                    <div className="pop_item">
                        <div className="pop_inner">
                            <div className="inner_top">
                                <span className="txt">
                                    등록된 휴대폰 번호 이외에 추가로 등록하실 다른 휴대폰 번호를 입력해주시기 바랍니다.<br/>
                                    아래 입력하신 인증 정보는 다음 추가 인증 시 리스트에 포함됩니다.
                                </span>			
                            </div>
                            <div className="pop_table">

                                <table className="table_layout01">
                                    
                                    <tbody>
                                            <tr>
                                                <th>MID(상점아이디)</th>
                                                <td>{pLoginId}</td>
                                            </tr>
                                            <tr>
                                                <th>정산계좌번호<br />(뒤 5자리)</th>
                                                <td>
                                                    <div className="flex_box count_num">
                                                        <span>  ***** &nbsp;</span>
                                                        <input type="text" maxLength={5} name="account_num" id="account_num" placeholder="" 
                                                        value={account_num}  
                                                        onChange={onAccount_numHandler}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>회사명</th>
                                                <td>
                                                    <input type="text" name="branch_name" id="branch_name" placeholder=""
                                                    value={branch_name}  
                                                    onChange={onBranch_nameHandler}                                                    
                                                    />
                                                </td>
                                            </tr>
                                            {(loginEdit === 'EMAIL') && (
                                                <tr>
                                                    <th>이메일</th>
                                                    <td>
                                                        <div className="button_row flex_box">
                                                            <input type="text" name="check_no1" id="check_no1" placeholder=""
                                                            value={snsNumber}  
                                                            onChange={onSnsNumberHandler}
                                                            />
                                                            <button type="button" className="list_btn" onClick={Fn_SNS_Send_V2}>인증번호 전송</button>
                                                        </div>

                                                    </td>
                                                </tr>
                                            )}
                                            {(loginEdit === 'HP') && (
                                                <tr>
                                                    <th>휴대폰번호</th>
                                                    <td>
                                                        <div className="button_row flex_box">
                                                            <input type="text" name="check_no1" id="check_no1" placeholder="010-0000-0000"
                                                            value={snsNumber}  
                                                            onChange={onSnsNumberHandler}
                                                            />
                                                            <button type="button" className="list_btn" onClick={Fn_SNS_Send_V2}>인증번호 발송</button>
                                                        </div>

                                                    </td>
                                                </tr>
                                            )}


                                            <tr>
                                                <th>인증번호</th>
                                                <td>
                                                    <div className="button_row flex_box">
                                                        <input type="text" name="auth_no_1" id="auth_no_1" placeholder="인증번호" 
                                                    value={certNumber2}  
                                                    onChange={onCertNumber2Handler} />
                                                        <button type="button" id="auth_no_3_check1" className="confirm_before_btn off list_btn" onClick={Fn_Sns_Proc_Check2} >인증번호 확인</button>	

                                                    </div>
                                                                                
                                                </td>
                                            </tr>
                                    </tbody>
                                </table>
                                
                                <div className="btn_box intro_btn">
                                    <Link className="dark_full_btn" onClick={Fn_SNS_INSERT}>등록</Link>
                                    <Link className="gray_line_btn register_phone_back" onClick={Fn_PopupEditClose}>돌아가기</Link>
                                    
                                </div>					
                            
                            </div>				
                        </div>
                    </div>
                </div>
                <div className="dim"></div>
            </div>
        </div>
   
    );
}

export default AuthPopup