import React, {useEffect, useState, useRef, useReducer, useCallback } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import Paging from "../../../include/Paging";
import Header from "../../../include/SiteAdminHeader";
import Footer from "../../../include/SiteAdminFooter";
import SetupLoginPopup from './SetupLoginPopup'
import OpenLogComment from "../../../include/OpenLogComment";
import {maskingFunc} from "../../../../views/include/ConfigSet_masking";

function SiteAdminManageSetupLoginList() {
    
    //운영로그 상태
    const [log_set, set_log_set] = useState(false);
    const [log_set_msg, set_log_set_msg] = useState('');
    const [pageReload, set_pageReload] = useState('');    
    React.useEffect(() => {              
        if(log_set === true){
            set_log_set(false);            
        }
    }, [log_set])

    const navigate = useNavigate();
    const location = useLocation();

    // 파라메터 정보 저장
    const queryParam = location.search.substring(1, location.search.length);
    const query = queryString.parse(location.search);    
    const sParent_idx = query.parent_idx ? query.parent_idx : '';
    const sPg = query.pg? query.pg : 1;
    //console.log('state : ', state)


    const [list , setList] = useState({
        viewContent:'',     // 게시판 내역
        totalCnt:0,         // 게시판 총 수
        nums:0,             // 게시판 넘버
        pageMax:10,          // 페이징 페이징에 한번에 보일 수
        gListRows:10,       // 페이징 한번에 나오는 리스트 수
        page:1,             // 페이징 현재 페이지
        url:queryParam,     // 페이징 url 정보
        parent_idx: sParent_idx       // PG idx 처리
    }); 
        

    // 등록 버튼을 눌렀을 때
    function pop_show(){
        const bank_pop = document.getElementsByClassName("bank_pop").item(0);
        bank_pop.classList.add('show');
    }

    const Board = ({
        idx,
        nums,  
        cert_mode, 
        hp,
        email     
    }) => {

            return (
                <tr>
                    <td className="number_td">{nums}</td>
                    <td>{cert_mode}</td>
                    <td className="maskingFunc_td">{maskingFunc.phone(hp)}</td>
                    <td className="maskingFunc_td">{maskingFunc.email(email)}</td>
                    <td className="btn_wrap_td">
                        <div className="btn_wrap">                        
                            <button className="btn_st_02 blue_line" onClick={()=>{Fn_Del(idx)}} >삭제</button>
                        </div>
                    </td>
                </tr>
            
            );
    };

    function Fn_Del(idx){
        if (!window.confirm('정말로 삭제하시겠습니까?')) {
            return;
        }

        let body = {
            idx: idx
        }

        axios.post('/api/manage/SetupLoginDel', body)
        .then(function(response){
            if(response.data === "DelOk"){    
                set_log_set_msg('삭제');
                set_log_set(true);
                set_pageReload('reload');                 
            }else{
                alert("시스템 에러");    
            }
        },[])

    }

    // 전체 수량 구하는 
    function GetTotalCnt(){
        axios.post('/api/manage/SetupLoginTotalList').then(function(res){                           
            setList({...list, totalCnt:{...list.totalCnt=res.data.tcnt}, nums: {...list.nums=(res.data.tcnt) -  ( (list.page - 1) * list.gListRows )} })            
        })
    }

    function GetList(){
        let bodyPage = {
            nFrom: (list.page - 1) * list.gListRows,
            gListRows: list.gListRows,
            parent_idx: list.parent_idx
        }
            
        //console.log('bodyPage : ', bodyPage);
        axios.post('/api/manage/SetupLoginList', bodyPage).then(function(res){                        
            setList({...list, viewContent:res.data});            
        }) 
    }

    useEffect(() => {
        
        if(sPg) {
            setList({...list, sPg:{...list.sPg=sPg}});
        }

        GetTotalCnt();

        GetList();

    },[sPg]);

  
    return(
    <>
        {(log_set === true) && ( <OpenLogComment pageMode={`운영관리 > 로그인 관리 > ${log_set_msg}`} pageReload={pageReload} /> )} {/* 운영로그 기록 */} 
        <div id="wrap">
            <Header/>
            <div id="container" data-menu-page="setup_admin">
                <h3 className="page_ttl">로그인 관리</h3>


                    <div className="bot_item">
                        <div className="bot_info">
                            <div className="left">총 {list.totalCnt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}개</div>
                            <div className="right">                                
                                <button className="btn_st_02 blue_bg" onClick={pop_show} >등록</button>
                                {/* <button className="btn_st_02 blue" onClick={onClickTable} >등록</button> */}                                
                            </div>
                        </div>
                        <div className="table_box">
                            <table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>인증방법</th>
                                        <th>휴대폰</th>
                                        <th>이메일</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (list.viewContent !=='') && (                                            
                                            list.viewContent.map((v, index) => {
                                                return (
                                                    <Board
                                                        key={v.idx}
                                                        idx={v.idx}                                                        
                                                        nums={list.nums-index}                                                  
                                                        cert_mode={v.cert_mode}
                                                        hp={v.hp}
                                                        email={v.email}
                                                    />
                                                );
                                            })
                                        )

                                    }                                    
                                </tbody>
                            </table>
                        </div>

                        {
                            list.viewContent.length > 0 ? <Paging curPage={list.page} totalPage={Math.ceil(list.totalCnt/list.gListRows)} pageBtnNum={list.pageMax} url={list.url} /> : null
                        }
                    </div>

                                    
                   <SetupLoginPopup />

            </div>
        </div>

        <Footer/>        
    </>
    )
}

export default SiteAdminManageSetupLoginList