import React, {useEffect, useState, useRef } from 'react'
import * as axios from 'axios';
import {Link, useLocation, useNavigate} from "react-router-dom";
import queryString from 'query-string';
import ReactQuill from 'react-quill';



function NoticeView({user_level, user_site}) {

    const navigate = useNavigate();
    const location = useLocation();
    const query = queryString.parse(location.search);  

    const qs = queryString.parse(window.location.search);
    const sIdx = qs.idx ? qs.idx : '';

    const [myip, set_myip] = useState(""); 
    const [page_state, set_page_state] = useState(true);


    const [idx, set_idx] = useState(sIdx);
    const on_idx_handler = (event) => {
        set_idx(event.currentTarget.value)
    }
    
    
    //제목
    const [subject, set_subject] = useState('');
    const on_subject_handler = (event) => {
        set_subject(event.currentTarget.value)
    }

    //내용
    const [contents, set_contents] = useState('');
    function onEditorChange(value) {
        set_contents(value)
    }

    //작성일
    const [regdate, set_regdate] = useState('');
   

    //등록
    const onSubmitHandler = (event) => {
        event.preventDefault();

        if(contents === ''){
            alert('내용을 입력해주세요.');
            return;
        }

        let body = {
            idx: idx,
            code: (query.code === undefined || query.code === '' ? 'notice':query.code),
            subject: subject,
            contents: contents,  
            ip_address: myip,
            user_level: user_level,                 
            user_id: sessionStorage.getItem("loginId"),
        }

        axios.post('/api/manage/NoticeFormAdd', body).then(function(response){
            if(response.data === "AddOk"){
                if(idx !==''){
                    alert('정상적으로 등록되었습니다.')
                }
                navigate('/'+user_site+'/_manage/NoticeList');

            }else{
                alert("시스템 에러");                
            }

        },[])

    }


        //상세정보
    const Fn_View=(sIdx)=>{

        let bodyPage = {
            idx: sIdx
        }
        
        axios.post('/api/manage/NoticeInfo', bodyPage).then(function(res){                        
            res.data.map((v, index) => {
                set_subject(v.subject);
                set_contents(v.contents);     
                set_regdate(v.regdate.substr(0,10));                           
                return ''
            }); 
            
            if(res.data.length === 0){                
                set_page_state(false)                
            }
        })         
    }

    const FnMyip =async()=>{
        const ipData = await fetch('https://geolocation-db.com/json/');
        const locationIp = await ipData.json();        
        set_myip(locationIp.IPv4)
    }
    
    React.useEffect(() => {
        FnMyip();
        if(sIdx !== ''){
            Fn_View(sIdx);
        }
    }, [sIdx])


    //페이지 정상 접근인지 체크
    React.useEffect(() => {
        if(page_state === false){
            alert("잘못된 접근입니다.");
            navigate(-1);
        }
    }, [page_state])

    

    return (
        <>      
            <div id="container" data-menu-page="distributor_list">
            
                 
                    <input type="hidden" name="idx" id="idx" value={idx} onChange={on_idx_handler} />
                    
                    <h3 className="page_ttl">공지사항</h3>

                    <div className="board_view">
                        <div className="bd_top">
                            <h3 className="ttl">{subject}</h3>
                        </div>
                        <div className="bd_info">
                            <div className="fl_sb">
                                <div className="left">
                                    <p>작성일 : <span>{regdate}</span></p>
                                </div>
                            </div>
                        </div>
                        

                        <div className="bd_body">
                            <ReactQuill value={contents} readOnly={true} theme={"bubble"}/>
                        </div>

                   
                    </div>

                    {/* <div className="form_item">
                        
                        <div className="top_table">
                            
                            <table>
                                
                                <tbody>
                                    <tr>
                                        <th style={{width:'200px'}}>제목</th>
                                        <td>
                                            <div className="in_box">
                                            {subject}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <ReactQuill value={contents} readOnly={true} theme={"bubble"}/>
                                        </td>
                                    </tr>                                
                                </tbody>
                            </table>
                            
                        

                        </div>

                    </div> */}


                    <div className="top_table_btn">
                 
                        <button type="button" className=" btn_st_01" onClick={  () => navigate(-1) }>목록</button>                       
                    </div>

             

            </div>                    
        </>
    )
}

export default NoticeView