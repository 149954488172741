import React from 'react'


function TexOrderExcelTable({datas, producer}) {


    //날짜 출력
    const getnowtiems = () => {

        let today = new Date(); // today 객체에 Date()의 결과를 넣어줬다
        let time = {
          year: today.getFullYear(),  //현재 년도
          month: today.getMonth() + 1, // 현재 월
          date: today.getDate(), // 현제 날짜
          hours: today.getHours(), //현재 시간
          minutes: today.getMinutes(), //현재 분
        };

        return time.year +'-'+(time.month > 9 ? time.month : '0' + time.month)+'-'+time.date;
    }


    return(
        <table id="tex-order-table-to-xls" style={{display:'none'}}>
            <thead>
            <tr>
                <th colSpan={33}>매출 전자 세금계산서 목록조회</th>               
            </tr>
            <tr>
                <th>작성일자</th>
                <th>승인번호</th>
                <th>발급일자</th>
                <th>전송일자</th>

                <th>공급자사업자등록번호</th>
                <th>종사업장번호</th>
                <th>상호</th>
                <th>대표자명</th>
                <th>주소</th>
                
                
                <th>공급받는자사업자등록번호</th>
                <th>종사업장번호</th>
                <th>상호</th>
                <th>대표자명</th>
                <th>주소</th>
                <th>합계금액</th>
                <th>공급가액</th>
                <th>세액</th>
                <th>전자세금계산서분류</th>
                <th>전자세금계산서종류</th>
                <th>발급유형</th>
                <th>비고</th>
                <th>영수/청구 구분</th>
                <th>공급자 이메일</th>
                <th>공급받는자 이메일1</th>
                <th>공급받는자 이메일2</th>
                <th>품목일자</th>
                <th>품목명</th>
                <th>품목규격</th>
                <th>품목수량</th>
                <th>품목단가</th>
                <th>품목공급가액</th>
                <th>품목세액</th>
                <th>품목비고</th>
            </tr>
            </thead>
            <tbody>
                
            {datas.map((item, index) => (                            
                <tr>
                    <td>{getnowtiems()}</td>
                    <td>{item.authno}</td>
                    <td>{getnowtiems()}</td>
                    <td>{getnowtiems()}</td>
                   
                    <td>{producer.corp_nun}</td>
                    <td></td>

                    <td>{producer.corp_name}</td>
                    <td>{producer.user_name}</td>
                    <td>{producer.corp_addr}</td>
                    
                    <td>{item.corp_nun}</td>
                    <td></td>
                    <td>{item.corp_name}</td>
                    <td>{item.representative}</td>
                    <td>{item.corp_addr}</td>
                    <td>{(item.E_income-item.EC_income).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{Math.round(((item.E_income-item.EC_income) / 11 * 10)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{Math.round(((item.E_income-item.EC_income) / 11)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>세금계산서</td>
                    <td>일반</td>
                    <td>인터넷발급</td>
                    <td></td>
                    <td>청구</td>
                    <td>{item.email}</td>
                    <td>{producer.email}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{Math.round(((item.E_income-item.EC_income) / 11 * 10)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>{Math.round(((item.E_income-item.EC_income) / 11)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td></td>                
                </tr>
            ))}
            </tbody>
        </table>

    );
}
export default TexOrderExcelTable